import React, {Component, Props as ComponentProps} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {Omit} from 'utility-types';

import {DatagridContext} from './Datagrid.context';
import {DatagridViewMode} from './Datagrid.model';
import {Styles, styles} from './Datagrid.style';

export interface DatagridProps<T> extends Styles {
  items: T[];
  viewMode: DatagridViewMode;
  printable?: boolean;
  loading?: boolean;
}

class Datagrid<T> extends Component<DatagridProps<T>> {
  static defaultProps = {
    loading: false,
    printable: false,
  };

  render() {
    const {children, classes, items, loading, viewMode, printable} = this.props;
    return (
      <DatagridContext.Provider value={{items, loading, viewMode, printable}}>
        <div className={classes.root}>
          <div className={classes.outerContainer}>
            <div className={classes.innerContainer}>{children}</div>
          </div>
        </div>
      </DatagridContext.Provider>
    );
  }
}

type DatagridComponentType = <T>(
  props: Omit<DatagridProps<T>, 'classes'> & ComponentProps<DatagridProps<T>>,
) => JSX.Element;

export default withStyles(styles)(Datagrid) as DatagridComponentType;
