import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      // flex: 1,
      // display: 'block',
      width: '100%',
      height: '100%',
      // overflowX: 'auto',
      // overflowY: 'hidden',
    },
    grid: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      position: 'relative',
      // minWidth: 1750,
    },
    head: {},
    headRow: {},
    body: {},
    bodyRows: {},
  });

export type Styles = WithStyles<typeof styles>;
