import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    popperContent: {
      marginTop: 0,
      marginBottom: 0,
    },
    checkbox: {
      fontSize: 14,
    },
    rootCheckbox: {
      marginBottom: 0,
      '&:not(:first-child)': {
        marginTop: '-10px',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
