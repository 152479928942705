import React, {FunctionComponent} from 'react';

import MuDivider, {DividerProps as MuiDividerProps} from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';

import {styles} from './Divider.style';

export interface ContextMenuDividerProps extends MuiDividerProps {}

const Divider: FunctionComponent<ContextMenuDividerProps> = (props) => {
  return <MuDivider {...props} />;
};

export default withStyles(styles)(Divider);
