import React, {Component, CSSProperties, ReactNode, RefObject} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import {TabsProps as MuiTabsProps} from '@material-ui/core/Tabs';
import classNames from 'classnames';

import {Option} from '../../models/Option';
import {nope} from '../../utils/function';
import {Styles, styles} from './Tabs.style';

interface State<T extends string = string> {
  tab: T;
}

interface TabOption<T extends string> extends Option<T> {
  disabled?: boolean;
}

export interface TabsProps<T extends string = string>
  extends Pick<MuiTabsProps, 'indicatorColor' | 'textColor' | 'centered' | 'variant'>,
    Styles {
  stickyTop?: number;
  tabs: TabOption<T>[];
  selectedTab?: T;
  onTabClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChange?: (_evt: React.ChangeEvent<{}>, tab: T) => void;
  children?: (tab: T) => ReactNode | void;
  tabsRootRef?: RefObject<HTMLDivElement>;
  zIndex?: number;
  indicatorPosition?: 'bottom' | 'top';
}

class Tabs<T extends string = string> extends Component<TabsProps<T>, State<T>> {
  static defaultProps: Partial<TabsProps> = {
    indicatorColor: 'primary',
    textColor: 'primary',
    centered: false,
    variant: 'standard',
    children: nope,
    stickyTop: null,
    zIndex: 1520,
    indicatorPosition: 'bottom',
  };

  constructor(props: TabsProps<T>) {
    super(props);
    this.state = {tab: props.selectedTab || (props.tabs && props.tabs[0].value)};
  }

  handleOnChange = (evt: any, tab: State<T>['tab']) => {
    this.setState({tab});
  };

  render() {
    const {tab} = this.state;
    const {
      classes,
      selectedTab = tab,
      onTabClick,
      onChange = this.handleOnChange,
      tabs,
      children,
      stickyTop,
      indicatorColor,
      textColor,
      centered,
      variant,
      tabsRootRef,
      zIndex,
      indicatorPosition,
    } = this.props;

    const style: CSSProperties = stickyTop ? {position: 'sticky', top: stickyTop, zIndex} : {};

    return (
      <>
        <div className={classes.root} style={style} ref={tabsRootRef}>
          <MuiTabs
            indicatorColor={indicatorColor}
            textColor={textColor}
            centered={centered}
            variant={variant}
            value={selectedTab}
            onChange={onChange}
            onClick={onTabClick}
            classes={{
              root: classNames({
                [classes.tabsRoot]: variant !== 'scrollable' && indicatorPosition === 'bottom',
              }),
              scrollButtons: classes.scrollButtons,
              indicator: classNames({[classes.indicatorTop]: indicatorPosition === 'top'}),
            }}
          >
            {tabs.map((tab) => (
              <MuiTab
                key={tab.value}
                classes={{
                  root: classes.tabRoot,
                  label: classes.tabLabel,
                  disabled: classes.tabDisabled,
                  wrapper: classes.tabWrapper,
                }}
                label={tab.label}
                value={tab.value}
                disabled={tab.disabled}
              />
            ))}
          </MuiTabs>
          <div className={classNames({[classes.separator]: indicatorPosition === 'bottom'})} />
        </div>
        {children(selectedTab)}
      </>
    );
  }
}

export default withStyles(styles)(Tabs);
