import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    cardTitle: {
      fontSize: 14,
      fontWeight: 400,
      padding: '8px 0',
      margin: 0,
      width: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:first-child': {
        paddingTop: 0,
      },
      '& itemStatus': {
        marginRight: 8,
      },
      '& showStatus': {
        '&a:before': {
          top: '30%',
        },
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
