import React from 'react';

import isEmpty from 'lodash/isEmpty';

type FilterDictionary = {[index: string]: any};

const removeFalsyOrder = (next: FilterDictionary) => {
  return Object.keys(next || {}).filter(
    (key) => typeof next[key] === 'boolean' || !isEmpty(next[key]),
  );
};

const removeFalsy = (next: FilterDictionary) => {
  const keys = removeFalsyOrder(next);

  return keys.reduce((acc, curr) => ({...acc, [curr]: next[curr]}), {});
};

export const removeFalsyFilters = <T extends {}, S extends keyof T>(
  Comp: React.ComponentType<T>,
  keys: S[],
) => (props: T & {children: any | any[]}) => {
  const cleanedProps = keys.reduce(
    (acc, key) => ((acc[key as string] = removeFalsy(props[key])), acc),
    {} as T,
  );

  const children = React.Children.toArray(props.children).filter((item) => !!item);

  const newProps = {
    ...props,
    ...cleanedProps,
    children,
  };

  return <Comp {...newProps} />;
};
