import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Popover, {PopoverProps as MuiPopoverProps} from '@material-ui/core/Popover';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import {nope} from '../../utils/function';

import {styles, Styles} from './Popover.styles';

export type PopoverProps = {
  headerTitle?: string;
  header?: JSX.Element;
} & Styles &
  Partial<MuiPopoverProps>;

class PopoverComponent extends React.Component<PopoverProps, {}> {
  static defaultProps: Partial<PopoverProps> = {
    onExited: nope,
  };

  render() {
    const {
      classes,
      anchorEl,
      open,
      children,
      onClose,
      onExited,
      header,
      headerTitle,
      ...otherProps
    } = this.props;

    return (
      <Popover
        classes={{paper: classes.popoverRoot}}
        anchorEl={anchorEl}
        open={open}
        onExited={onExited}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...otherProps}
      >
        {headerTitle ? (
          <div className={classes.popoverHeader}>
            <Typography className={classes.headerLabel}>{headerTitle}</Typography>
            <IconButton
              color="primary"
              className={classes.headerButton}
              aria-label={`Close ${headerTitle}`}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
            header
          )}
        {children}
      </Popover>
    );
  }
}

export default withStyles(styles)(PopoverComponent);
