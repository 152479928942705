import {Theme} from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    },
    dialogPaper: {
      maxHeight: '80vh',
      borderRadius: 4,
      boxShadow:
        '0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05), 0 -1px 5px 0 rgba(0,0,0,0.05), 0 0 100px rgba(0,0,0,0.2)',
    },
    footerBorder: {
      borderTop: '1px solid rgba(0,0,0,0.1)',
    },
    footerRoot: {},
    headerRoot: {
      minHeight: '48px',
    },
    headerText: {},
    headerButton: {},
    dialogContent: {
      padding: '15px',
    },
    dialogActions: {
      margin: 0,
      display: 'block',
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
