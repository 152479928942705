import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    pagingSection: {
      minHeight: '54px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    tabsSection: {
      minHeight: '54px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    typography: {
      color: '#666',
      padding: 10,
      textTransform: 'uppercase',
      fontSize: 12,
    },
    root: {
      borderTop: '2px solid #E6E6E6',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    multipleSections: {
      justifyContent: 'space-between',
    },
    footerRoot: {
      minWidth: '180px',
      paddingLeft: '4px',
    },
  });

export type Styles = WithStyles<typeof styles>;
