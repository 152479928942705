import React, {Component, MouseEvent} from 'react';

import {RootRef} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import {SvgIconProps} from '@material-ui/core/SvgIcon';

import {ConditionalWrap} from '../ConditionalWrap';
import {CssTooltip, CssTooltipProps} from '../CssTooltip';
import {styles, Styles} from './FilterChip.style';

export interface FilterChipProps extends Styles {
  isDashed?: boolean;
  onReset?: any;
  icon?: React.ReactElement<SvgIconProps>;
  label: string;
  isLoaded?: boolean;
  hideClose?: boolean;
  tooltipProps?: Partial<CssTooltipProps>;
  handleClick?: (event: MouseEvent<HTMLDivElement>) => void;
  chipRef?: React.RefObject<{}>;
  rootRef?: React.RefObject<HTMLElement>;
}

class FilterChip extends Component<FilterChipProps> {
  static defaultProps: Partial<FilterChipProps> = {
    isDashed: false,
    hideClose: false,
    onReset: null,
    chipRef: null,
    tooltipProps: {
      title: '',
    },
  };

  checkOnDelete = () => {
    const {onReset, isDashed, isLoaded} = this.props;
    if (isLoaded) {
      return onReset;
    } else {
      if (!isDashed) {
        return () => {
          return;
        };
      }
    }
    return null;
  };

  render() {
    const {
      classes,
      label,
      icon,
      isDashed,
      isLoaded,
      hideClose,
      handleClick,
      chipRef,
      rootRef,
      tooltipProps,
    } = this.props;
    const {title, ...otherTooltipProps} = tooltipProps;
    const customClasses = isLoaded ? {} : {label: classes.disabledLabel};
    const clickable = isLoaded && !!handleClick;

    return (
      <div className={classes.root}>
        <ConditionalWrap
          condition={!!rootRef}
          wrap={(children) => <RootRef rootRef={rootRef}>{children}</RootRef>}
        >
          <CssTooltip title={title} {...otherTooltipProps}>
            <Chip
              innerRef={chipRef}
              className={`${classes.chip} ${isLoaded &&
                isDashed &&
                classes.dashedChip} ${!isLoaded && classes.disabledChip} ${!clickable &&
                classes.disableHover}`}
              clickable={isLoaded && !!handleClick}
              label={label}
              icon={icon}
              onClick={isLoaded ? handleClick : undefined}
              onDelete={!hideClose ? this.checkOnDelete() : undefined}
              variant="outlined"
              classes={customClasses}
            />
          </CssTooltip>
        </ConditionalWrap>
      </div>
    );
  }
}

export default withStyles(styles)(FilterChip);
