import React from 'react';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import DragIndicator from '@material-ui/icons/DragIndicator';
import classNames from 'classnames';
import {SortableHandle} from 'react-sortable-hoc';

import {styles} from './styles';

interface Props extends WithStyles<typeof styles> {
  disabled?: boolean;
}

const DragHandle = (props: Props) => {
  const {classes, disabled} = props;
  return <DragIndicator className={classNames(classes.handle, {[classes.disabled]: disabled})} />;
};

export default withStyles(styles)(SortableHandle(DragHandle));
