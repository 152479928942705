import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {
      flex: 1,
    },
  });

export type Styles = WithStyles<typeof styles>;
