import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './Content.style';

interface Props extends Styles {}

const Presentational: FC<Props> = (props) => {
  const {classes, children} = props;
  return <div className={classes.root}>{children}</div>;
};
export default withStyles(styles)(Presentational);
