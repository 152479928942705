import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit,
    },
    rootHasCounter: {
      marginBottom: 0,
    },
    rootWithValues: {},
    selectRoot: {
      padding: '0px 15px',
    },
    selectWithValues: {
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit,
      position: 'sticky !important' as 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: '#fff',
    },
  });

export type Styles = WithStyles<typeof styles>;
