import React from 'react';

import {InputProps} from '@material-ui/core/Input';
import withStyles from '@material-ui/core/styles/withStyles';

import {styles, Styles} from './StaticInput.style';

export type StaticInputProps = Styles & {
  label: string;
  value: InputProps['value'];
};

const StaticInput: React.SFC<StaticInputProps> = (props) => {
  const {classes, label, value} = props;
  return (
    <>
      <div className={classes.label}>
        <small>{label}</small>
      </div>

      <div className={classes.staticValue}>
        <span>{value}</span>
      </div>
    </>
  );
};

export default withStyles(styles)(StaticInput);
