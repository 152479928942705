import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    selectWithValues: {
      borderTop: 'none',
      marginTop: 0,
    },
  });

export type Styles = WithStyles<typeof styles>;
