import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

const disabledColor = '#BEC0C1';

export const styles = () =>
  createStyles({
    root: {
      padding: '0 0 0 5px',
    },
    chip: {
      fontSize: 13,
      backgroundColor: '#F7F7F7',
    },
    dashedChip: {
      borderStyle: 'dashed',
      backgroundColor: 'transparent',
    },
    disabledChip: {
      borderStyle: 'dashed',
      cursor: 'not-allowed',
    },
    disabledLabel: {
      color: disabledColor,
    },
    disabledIcon: {
      color: disabledColor,
    },
    disableHover: {
      pointerEvents: 'none',
    },
  });

export type Styles = WithStyles<typeof styles>;
