import React, {Component} from 'react';

import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import _equal from 'fast-deep-equal';

import {onCloseType} from '../../../ActionPopper';
import {FilterActionPopper} from '../../../FilterActionPopper';
import {PopperContentSection} from '../../../Popper/Content';
import {TextField, TextFieldSize} from '../../../TextField';
import {Styles, styles} from './SearchTermFilter.style';

interface SearchByFilterPopperProps extends Styles {
  hideAdvancedButton?: boolean;
  value: string;
  tempValue?: string;
  hasChanges: boolean;
  onChange: (value: string, ev?: React.SyntheticEvent) => void;
  onPopperClose: (temp?: string) => void;
  resetTempValue: () => void;
  open: boolean;
  anchorEl?: HTMLElement;
}

interface SearchByFilterPopperState {
  searchInput: string;
  advanced: boolean;
}

class SeachByFilterPopper extends Component<SearchByFilterPopperProps, SearchByFilterPopperState> {
  static defaultProps = {value: '', hideAdvancedButton: false};

  constructor(props: SearchByFilterPopperProps) {
    super(props);

    this.state = {
      searchInput: props.tempValue || props.value || '',
      advanced: false,
    };
  }

  handleSubmit = (ev: React.SyntheticEvent) => {
    const {onChange} = this.props;
    const {searchInput} = this.state;

    onChange(searchInput, ev);
  };

  handleValueChange = (searchInput: string) => {
    this.setState({searchInput});
  };

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>,
    type?: onCloseType,
  ) => {
    const {searchInput} = this.state;
    const {value} = this.props;
    const temp = type === 'away' && !_equal(value, searchInput) ? searchInput : undefined;
    this.props.onPopperClose(temp);
  };

  onReset = () => {
    const {value, resetTempValue} = this.props;
    resetTempValue();
    this.setState({searchInput: value});
  };

  render() {
    const {classes, anchorEl, open, hideAdvancedButton, hasChanges} = this.props;
    const {advanced, searchInput} = this.state;
    return (
      <FilterActionPopper
        anchorEl={anchorEl}
        open={open}
        onClose={this.handleClose}
        headerTitle="Search"
        disabled={!searchInput}
        onSubmit={this.handleSubmit}
        hasChanges={hasChanges}
        onCancelLabel="Reset"
        onCancel={hasChanges && this.onReset}
      >
        <PopperContentSection classes={{root: classes.popperContent}}>
          {!hideAdvancedButton && advanced ? this.renderAdvancedInput() : this.renderBasicInput()}
          {!hideAdvancedButton && (
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={this.onChangeInput}
              className={classes.button}
            >
              {advanced ? `Basic` : `Advanced`}
            </Button>
          )}
        </PopperContentSection>
      </FilterActionPopper>
    );
  }

  onChangeInput = () => {
    this.setState((state) => ({advanced: !state.advanced}));
  };

  renderBasicInput = () => {
    const {searchInput} = this.state;
    return (
      <TextField
        label="Query"
        value={searchInput}
        autoFocus
        onChange={this.handleValueChange}
        size={TextFieldSize.small}
        fullWidth
      />
    );
  };

  renderAdvancedInput = () => {
    const {classes} = this.props;
    const {searchInput} = this.state;
    return (
      <TextField
        key="lucene"
        label="Query (Lucene)"
        value={searchInput}
        onChange={this.handleValueChange}
        multiline
        autoFocus
        rows={4}
        size={TextFieldSize.small}
        rowsMax={8}
        fullWidth
        InputProps={{
          classes: {
            root: `${classes.inputBackground} ${classes.textAreaPadding}`,
          },
          inputProps: {
            className: classes.textAreaFont,
          },
        }}
      />
    );
  };
}

export default withStyles(styles)(SeachByFilterPopper);
