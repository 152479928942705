import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    mapView: {
      flex: 1,
      position: 'relative',
      height: '100%',
      display: 'none',
    },
    visible: {
      display: 'block',
    },
  });

export type Styles = WithStyles<typeof styles>;
