import React, {FC} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

import {usePopper} from '../../hooks/popper';
import {ParentPopperModifiers} from '../../utils/popper';
import {
  ContextHeaderMenuItem,
  ContextMenu,
  ContextMenuDivider,
  ContextMenuProps,
} from '../ContextMenu';
import {CssTooltip, CssTooltipProps} from '../CssTooltip';
import {styles, Styles} from './DownloadPopper.styles';

export interface Props extends Styles {
  disabled?: boolean;
  headerTitle?: string;
  tooltipTitle?: CssTooltipProps['title'];
  tooltipPlacement?: CssTooltipProps['placement'];
  tooltipCenterArrow?: CssTooltipProps['centerArrow'];
  modifiers?: ContextMenuProps['modifiers'];
  children: (renderPros: {popperRef: HTMLElement; onClose: () => void}) => React.ReactNode;
}

const Presentational: FC<Props> = (props) => {
  const {popperRef, onOpen, onClose} = usePopper();
  const {
    classes,
    disabled,
    tooltipCenterArrow,
    tooltipPlacement,
    tooltipTitle,
    headerTitle,
  } = props;

  return (
    <>
      <CssTooltip
        title={!disabled && tooltipTitle}
        centerArrow={tooltipCenterArrow}
        placement={tooltipPlacement}
      >
        <IconButton color="primary" disabled={disabled} onClick={onOpen}>
          <DownloadIcon color="inherit" />
        </IconButton>
      </CssTooltip>
      {!!popperRef && (
        <ContextMenu
          className={classes.popper}
          open={true}
          anchorEl={popperRef}
          onClickAway={onClose}
          modifiers={ParentPopperModifiers}
        >
          {headerTitle && (
            <>
              <ContextHeaderMenuItem name={headerTitle} />
              <ContextMenuDivider />
            </>
          )}
          {props.children({popperRef, onClose})}
        </ContextMenu>
      )}
    </>
  );
};

Presentational.defaultProps = {
  tooltipPlacement: 'left',
  tooltipCenterArrow: true,
  headerTitle: 'Downloads',
  tooltipTitle: 'Downloads',
  modifiers: ParentPopperModifiers,
};

export default withStyles(styles)(Presentational);
