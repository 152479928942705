import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      minHeight: 40,
      padding: '0 8px',
      backgroundColor: '#FFF',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0',
      boxSizing: 'border-box',
      marginBottom: -1,
      '&:hover': {},
    },
  });
export type Style = WithStyles<typeof styles>;
