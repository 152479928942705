import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';
import {Props as AsyncProps} from 'react-select/lib/Async';
import AsyncCreatable from 'react-select/lib/AsyncCreatable';
import {SelectComponentsConfig} from 'react-select/lib/components';
import {Option as SelectOption} from 'react-select/lib/filters';
import {createFilter} from 'react-select/lib/filters';

import {AutocompleteSize, defaultAutoCompleteStyles} from '../Autocomplete';
import {styles, Styles} from '../Autocomplete.style';
import {asyncComponents} from '../utils';

type AsyncDefaultProps = AsyncProps<SelectOption>;

export type AsyncCreatableAutocompleteProps = AsyncDefaultProps &
  Styles & {
    virtualized?: boolean;
    size?: AutocompleteSize;
    SingleValueProps?: TypographyProps;
    components?: SelectComponentsConfig<SelectOption>;
  };

const AsyncCreatableAutocomplete: FunctionComponent<AsyncCreatableAutocompleteProps> = (props) => {
  const {
    className,
    classes,
    virtualized,
    filterOption,
    components = {},
    styles = {},
    ...otherProps
  } = props;
  const {root, ...otherClasses} = classes;

  return (
    <AsyncCreatable
      className={classNames(root, className)}
      classes={otherClasses}
      components={{...asyncComponents, ...components}}
      virtualized={virtualized}
      filterOption={filterOption || (virtualized && createFilter({ignoreAccents: false}))} // this makes all the difference!
      styles={{...defaultAutoCompleteStyles, ...styles}}
      {...otherProps}
    />
  );
};

AsyncCreatableAutocomplete.defaultProps = {
  menuPortalTarget: process.browser ? document.body : null,
  menuPlacement: 'auto',
  isMulti: false,
  label: '',
  size: AutocompleteSize.large,
  menuShouldScrollIntoView: true,
  instanceId: 'auto-complete',
  formatCreateLabel: (newItem: string) => `Add "${newItem}"`,
  createOptionPosition: 'first',
};

export default withStyles(styles)(AsyncCreatableAutocomplete);
