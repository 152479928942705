import React, {FunctionComponent} from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {Popper, PopperContent, PopperPaper, PopperProps} from '../../Popper';

import {Styles, styles} from './UserMenuPopper.style';

export type UserMenuPopperProps = Partial<PopperProps> &
  Styles & {
    userName: string;
    userEmail: string;
    linkToAccount?: string;
    version: string;
    build: string;
    onAccountClick?: () => void;
    onLogoutClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  };

const UserMenuPopper: FunctionComponent<UserMenuPopperProps> = ({
  classes,
  userName,
  userEmail,
  linkToAccount,
  version,
  build,
  onLogoutClick,
  onAccountClick,
  open,
  ...popperProps
}) => (
    <Popper open={open} {...popperProps}>
      <PopperPaper className={classes.paper}>
        <PopperContent>
          <div className={classes.userInfo}>
            <Typography gutterBottom className={classes.userName}>
              {userName}
            </Typography>
            <Typography gutterBottom className={classes.userEmail}>
              {userEmail}
            </Typography>
          </div>

          <div className={classes.buttonWrapper}>
            {onAccountClick && (
              <Button
                onClick={onAccountClick}
                variant="text"
                target="_blank"
                href={linkToAccount}
                size="small"
                color="primary"
                rel="noopener noreferrer"
                className={classes.button}
              >
                Account
              </Button>
            )}
            {onLogoutClick && onAccountClick && <>&nbsp;|&nbsp;</>}
            {onLogoutClick && (
              <Button
                variant="text"
                onClick={onLogoutClick}
                size="small"
                color="primary"
                className={classes.button}
              >
                Logout
              </Button>
            )}
          </div>

          <Divider />

          <Typography className={classes.versionLabel}>
            v{version} - build {build}
          </Typography>
        </PopperContent>
      </PopperPaper>
    </Popper>
  );

export default withStyles(styles)(UserMenuPopper);
