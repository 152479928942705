import React, {Component, MouseEvent} from 'react';

import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './Dialog.style';
import {Option} from './model';

interface Props extends Styles {
  onClose: () => void;
  actions?: Option[];
}

class DialogContent extends Component<Props, {}> {
  static defaultProps: Partial<Props> = {
    actions: [],
  };

  onBackdropClick = () => {
    this.props.onClose();
  };

  onRootClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  render() {
    const {classes, children, onClose, actions} = this.props;

    return (
      <div className={classes.backdrop} onClick={this.onBackdropClick}>
        <div className={classes.root} onClick={this.onRootClick}>
          <div className={classes.controls}>
            {actions.map((option) => (
              <Button variant="text" onClick={option.action} classes={{text: classes.button}}>
                {option.label}
              </Button>
            ))}
            <Button variant="text" onClick={onClose} classes={{text: classes.button}}>
              Close
            </Button>
          </div>
          <div className={classes.container}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DialogContent);
