import {FilterColorChip, FilterColorChipProps, filterColorChipStylesDecorator} from '../FilterChip';

interface Props extends FilterColorChipProps<boolean> {
  chipModelName: string;
}

class Chip extends FilterColorChip<Props, boolean> {
  getLabel(value: boolean) {
    return `My ${this.props.chipModelName}`;
  }
}

export default filterColorChipStylesDecorator(Chip);
