import {fade} from '@material-ui/core/styles/colorManipulator';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

const littleFade = (color: string) => fade(color, 0.26);

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(200,200,200,0.1)',
      display: 'flex',
      alignItems: 'center',
      minHeight: 38,
      boxSizing: 'border-box',
      // padding: '0 15px 0 10px',
      padding: '0 15px',

      borderTop: '1px solid #E6E6E6',
      '&:hover': {
        backgroundColor: 'rgba(200,200,200,0.2)',
      },
      '&:hover $buttonContainer': {
        opacity: 1,
      },
    },
    lastChild: {
      '&:last-child': {
        borderBottom: '1px solid #E6E6E6',
      },
    },
    selected: {
      backgroundColor: 'rgba(0, 173, 255, 0.1)',
      '&:hover': {
        backgroundColor: 'rgba(0, 173, 255, 0.1)',
      },
    },
    label: {
      flex: 1,
      fontSize: '12px',
      color: '#000000',
      marginTop: 9,
      marginBottom: 10,
    },
    buttonContainer: {
      opacity: 0,
      marginLeft: 10,
    },
    icon: {
      WebkitTapHighlightColor: 'transparent',
      display: 'inline-block',
      color: littleFade(theme.palette.text.primary),
      cursor: 'pointer',
      height: 'auto',
      margin: '0 -4px 0 -8px',
      '&:hover': {
        color: fade(littleFade(theme.palette.text.primary), 0.4),
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
