import React, {FunctionComponent, HTMLAttributes} from 'react';

import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography, {TypographyProps} from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import classNames from 'classnames';

import {Styles, styles} from './Header.style';

export type DrawerHeaderProps = HTMLAttributes<HTMLDivElement> &
  Pick<TypographyProps, 'children'> &
  Styles & {
    onBackClick: IconButtonProps['onClick'];
  };

const DrawerHeader: FunctionComponent<DrawerHeaderProps> = ({
  classes,
  onBackClick,
  className,
  children,
  ...divProps
}) => (
  <div className={classNames(classes.root, className)} {...divProps}>
    <Typography variant="h6" className={classes.children}>
      {children}
    </Typography>
    <IconButton className={classes.backButton} onClick={onBackClick}>
      <ArrowBack />
    </IconButton>
  </div>
);

DrawerHeader.defaultProps = {};

export default withStyles(styles)(DrawerHeader);
