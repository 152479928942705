import React, {FunctionComponent, ImgHTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './BrandLogo.style';

type BrandLogoProps = Styles & ImgHTMLAttributes<HTMLImageElement>;

const Presentational: FunctionComponent<BrandLogoProps> = ({classes, className, ...otherProps}) => {
  return <img className={classNames(classes.root, className)} {...otherProps} />;
};

Presentational.defaultProps = {
  width: 125,
};

export default withStyles(styles)(Presentational);
