import React, {SFC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {TextField, TextFieldProps, TextFieldSize} from '../TextField';
import {Styles, styles} from './SearchField.style';

export type SearchFieldProps = Styles &
  Pick<
    TextFieldProps,
    | 'margin'
    | 'className'
    | 'placeholder'
    | 'value'
    | 'onChange'
    | 'onKeyDown'
    | 'fullWidth'
    | 'autoFocus'
    | 'autoComplete'
    | 'size'
    | 'isClearable'
  >;

const SearchField: SFC<SearchFieldProps> = (props) => {
  const {classes, className, value, onChange, ...otherProps} = props;

  return (
    <TextField
      className={`${className} ${classes.root}`}
      value={value}
      onChange={onChange}
      {...otherProps}
    />
  );
};

SearchField.defaultProps = {
  margin: 'dense',
  fullWidth: true,
  autoFocus: true,
  isClearable: true,
  autoComplete: 'off',
  size: TextFieldSize.small,
};

export default withStyles(styles)(SearchField);
