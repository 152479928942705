import React from 'react';

import Typography from '@material-ui/core/Typography';

const Placeholder = (props) => {
  const {selectProps, innerProps = {}} = props;
  const {'data-testid': dataTestId} = selectProps;
  return (
    <Typography
      color="textSecondary"
      component="div"
      className={props.selectProps.classes.placeholder}
      {...innerProps}
      data-testid={dataTestId ? `${dataTestId}-placeholder` : innerProps.id}
    >
      {props.children}
    </Typography>
  );
};

Placeholder.muiName = 'Placeholder';

export default Placeholder;
