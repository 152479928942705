import React, {FC, MouseEvent} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {CssTooltip} from '../../../CssTooltip';
import {Styles, styles} from './Header.style';

interface Props extends Styles {
  title: string;
  hideOptions?: boolean;
  onMoreClick: (evt: MouseEvent<HTMLElement>) => void;
}

const Presentational: FC<Props> = (props) => {
  const {classes, title, hideOptions, onMoreClick} = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {!hideOptions && (
        <CssTooltip title="Actions" placement="left">
          <IconButton className={classes.settingsIcon} color="primary" onClick={onMoreClick}>
            <MoreVertIcon />
          </IconButton>
        </CssTooltip>
      )}
    </div>
  );
};
export default withStyles(styles)(Presentational);
