import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    paper: {
      minWidth: 180,
      minHeight: 'auto',
      width: 300,
    },
    header: {
      height: 64,
    },
    filterListMessage: {
      padding: '15px 15px 0',
      color: 'rgba(0,0,0,0.54)',
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      width: 28,
      height: 28,
      padding: 0,
    },
    icon: {
      fontSize: 18,
    },
    adornedEnd: {
      paddingRight: 0,
    },
    positionEnd: {
      marginRight: 8,
    },
  });

export type Styles = WithStyles<typeof styles>;
