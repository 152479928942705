import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';

import {Period} from '../../../models/Period';
import Content from '../common/Content';
import Widget, {Props as WidgetProps} from '../common/Widget';
import {Styles, styles} from './CampaignPeriod.style';
import DateSection from './DateSection';
import SettingsPopper from './SettingsPopper';
import SummarySection from './SummarySection';

interface Props
  extends Styles,
    Pick<
      WidgetProps,
      | 'renderBeforeSettingsMenuItem'
      | 'renderAfterSettingsMenuItem'
      | 'renderBeforeDeleteMenuItem'
      | 'renderAfterDeleteMenuItem'
    > {
  hideOptions?: boolean;
  campaign: Period;
  minimumNights?: number;
  calendarDirection?: 'up' | 'down';
  onChange: (changes: Period) => void;
  onDelete: () => void;
}

const Presentational: FC<Props> = (props) => {
  const {
    classes,
    campaign,
    hideOptions,
    minimumNights,
    calendarDirection,
    onChange,
    onDelete,
  } = props;
  const {
    renderBeforeSettingsMenuItem,
    renderAfterSettingsMenuItem,
    renderBeforeDeleteMenuItem,
    renderAfterDeleteMenuItem,
  } = props;
  const {startDate, endDate} = campaign;
  const start = moment(startDate);
  const end = moment(endDate).endOf('day');

  return (
    <Widget
      classes={{root: classes.root}}
      hideOptions={hideOptions}
      title="Campaign Period"
      renderBeforeSettingsMenuItem={renderBeforeSettingsMenuItem}
      renderAfterSettingsMenuItem={renderAfterSettingsMenuItem}
      renderBeforeDeleteMenuItem={renderBeforeDeleteMenuItem}
      renderAfterDeleteMenuItem={renderAfterDeleteMenuItem}
      onDelete={onDelete}
      settingsPopper={({openSettings, anchorEl, onUpdateState}) =>
        openSettings && (
          <SettingsPopper
            open={true}
            minimumNights={minimumNights}
            openDirection={calendarDirection}
            campaign={campaign}
            anchorEl={anchorEl}
            onChange={onChange}
            onClose={() => onUpdateState({anchorEl: null, openMenu: false, openSettings: false})}
          />
        )
      }
    >
      <Content classes={{root: classes.content}}>
        <DateSection date={start} />
        <SummarySection startDate={start} endDate={end} />
        <DateSection classes={{root: classes.endDate}} date={end} />
      </Content>
    </Widget>
  );
};

export default withStyles(styles)(Presentational);
