import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    button: {
      minWidth: 20,
      fontSize: 12,
      padding: '8px 20px',
      minHeight: 48,
      transition: 'all 0.16s ease-in-out',
    },

    disableMouseCursor: {
      cursor: 'not-allowed',
    },
    disable: {
      display: 'none',
    },

    formActions: {
      marginLeft: '25%',
    },
    formGroupSubmit: {
      margin: '2rem 0 0',
      display: 'flex',
      minHeight: '48px',
      alignItems: 'center',
    },

    removeButton: {
      backgroundImage: 'linear-gradient(90deg, #f44336 0, #e91e63)',
      borderColor: '#e91e63',
    },

    buttonLoading: {
      cursor: 'progress',
    },
    tooltip: {
      position: 'relative',
      backgroundColor: 'rgb(0, 0, 0);',
      '-webkit-font-smoothing': 'subpixel-antialiased',
    },
    tooltipPopper: {
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.95em',
        width: '2em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent #000000 transparent`,
        },
      },
    },
    arrow: {
      position: 'absolute',
      fontSize: 6,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
