import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './HeaderBar.style';

export type HeaderBarProps = Styles;
export type DatagridHeaderBarProps = HeaderBarProps;

const HeaderBar: FunctionComponent<HeaderBarProps> = (props) => {
  const {classes, children} = props;

  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(HeaderBar);
