import React, {FunctionComponent} from 'react';

import {Omit} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography, {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';

import {Link, LinkProps} from '../../../Link';

import {Styles, styles} from './BreadcrumbsItem.style';

type BreadcrumbsItemProps = Styles &
  Omit<TypographyProps, 'color'> &
  Partial<Pick<LinkProps, 'href'>>;

const Presentational: FunctionComponent<BreadcrumbsItemProps> = ({
  classes,
  className,
  children,
  href,
  ...otherProps
}) => (
    <Typography className={classNames(classes.root, className)} {...otherProps}>
      {href ? (
        <Link color="primary" href={href}>
          {children}
        </Link>
      ) : (
          children
        )}
    </Typography>
  );

Presentational.defaultProps = {};

export default withStyles(styles)(Presentational);
