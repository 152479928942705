import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {BeakynLogo} from './BeakynLogo';
import {Styles, styles} from './BrandsSection.style';

type DrawerBrandsSectionProps = Styles;

const Presentational: FunctionComponent<DrawerBrandsSectionProps> = ({classes}) => {
  return (
    <div className={classes.root}>
      <BeakynLogo />
    </div>
  );
};
Presentational.defaultProps = {};

export default withStyles(styles)(Presentational);
