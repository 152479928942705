import React, {ReactNode, SFC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import {CssTooltip, CssTooltipProps} from '../../CssTooltip';
import {styles, Styles} from './Horizontal.style';

export interface HorizontalBarProps extends Styles {
  label?: string | ReactNode;
  value?: string | ReactNode;
  tooltip?: CssTooltipProps['title'];
  tooltipCenterArrow?: CssTooltipProps['centerArrow'];
  tooltipPlacement?: CssTooltipProps['placement'];
  fillInPercentage: number;
  disableAnimation?: boolean;
}

const HorizontalBar: SFC<HorizontalBarProps> = (props) => {
  const {
    classes,
    label,
    value,
    tooltip,
    tooltipPlacement,
    tooltipCenterArrow,
    fillInPercentage,
    disableAnimation,
  } = props;

  return (
    <div className={classes.root}>
      <div
        className={classNames(classes.bar, {[classes.noAnimation]: disableAnimation})}
        style={{width: `${fillInPercentage}%`}}
      />
      {!!label && (
        <Typography className={classes.itemDetails}>
          <span className={classes.itemLabel}>{label}</span>
          <CssTooltip centerArrow={tooltipCenterArrow} title={tooltip} placement={tooltipPlacement}>
            <span className={classes.itemValue}>{value}</span>
          </CssTooltip>
        </Typography>
      )}
    </div>
  );
};

HorizontalBar.defaultProps = {
  tooltipPlacement: 'left',
};

export default withStyles(styles)(HorizontalBar);
