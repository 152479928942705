import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {$PropertyType} from 'utility-types';

import {DatagridContext} from '../../../../Datagrid/Datagrid.context';
import {
  ColumnHeaderProperty,
  DatagridProperty,
  DatagridSorting,
  DatagridSortingType,
} from '../../../../Datagrid/Datagrid.model';
import {DatagridTableViewColumn} from '../../../../Datagrid/ViewModes/Table/Column';
import {DatagridTableViewRow} from '../../../../Datagrid/ViewModes/Table/Row';
import {DatagridTableViewSorting} from '../../../../Datagrid/ViewModes/Table/Sorting';
import {Styles, styles} from './Head.style';

interface Props extends Styles {
  properties: ColumnHeaderProperty[];
  sorting: DatagridSorting;
  onSortingChange: (sorting: DatagridSorting) => void;
}

interface State {
  hovered: string;
}

const sortingHandler = (
  property: DatagridProperty,
  sorting: DatagridSorting,
  onSortingChange: $PropertyType<Props, 'onSortingChange'>,
) => () =>
  onSortingChange({
    key: property.value,
    type:
      sorting.key === property.value && sorting.type === DatagridSortingType.asc
        ? DatagridSortingType.desc
        : DatagridSortingType.asc,
  });

const hoverHandler = (property: string, onHoverChange: (property: string) => void) => () =>
  onHoverChange(property);

class DatagridTableViewHead extends Component<Props, State> {
  state = {
    hovered: null,
  };

  setHovered = (property: string) => {
    this.setState({hovered: property});
  };

  render() {
    const {classes, properties, sorting, onSortingChange} = this.props;
    const {hovered} = this.state;

    return (
      <DatagridContext.Consumer>
        {({printable}) => {
          return (
            <div className={classes.root}>
              <DatagridTableViewRow classes={{root: classes.row}}>
                {properties.map((property, index) => {
                  const isSelected = property.value === sorting.key && !printable;
                  const isHovered = property.value === hovered;
                  const isClickable = typeof property.label === 'string' && property.value;

                  return (
                    <DatagridTableViewColumn
                      style={property.style}
                      classes={{
                        root: property.columnRootClass,
                        headRoot: classNames(
                          {[classes.cursorInitial]: !isClickable},
                          property.columnHeadRootClass,
                        ),
                        headText: property.columnHeadTextClass,
                      }}
                      key={`${property.value}${index}`}
                      head
                      highlighted={isSelected}
                      onClick={
                        isClickable ? sortingHandler(property, sorting, onSortingChange) : undefined
                      }
                      onMouseOver={hoverHandler(property.value, this.setHovered)}
                      onMouseLeave={hoverHandler(null, this.setHovered)}
                    >
                      {property.label}
                      {!printable && isClickable && (
                        <DatagridTableViewSorting
                          sorting={isSelected ? sorting.type : DatagridSortingType.asc}
                          classes={{
                            root: classNames({
                              [classes.hideSortArrow]: !isSelected,
                              [classes.smoothSortArrow]: !isSelected && isHovered,
                            }),
                          }}
                        />
                      )}
                      {!!property.columnHeadExtra && (
                        <div
                          className={classNames(
                            classes.extraContainerClass,
                            property.columnHeadExtraContainerClass,
                          )}
                        >
                          {property.columnHeadExtra}
                        </div>
                      )}
                    </DatagridTableViewColumn>
                  );
                })}
              </DatagridTableViewRow>
            </div>
          );
        }}
      </DatagridContext.Consumer>
    );
  }
}

export default withStyles(styles)(DatagridTableViewHead);
