import React from 'react';

import _capitalize from 'lodash/capitalize';

import {BaseFilter, BaseProps} from '../../BaseFilter';
import Chip from './Chip';

type Props = BaseProps<boolean> & {
  chipModelName: string;
};
const FILTER_ID = 'my' as const;
const pascalCase = (str: string) =>
  str
    .split(' ')
    .map(_capitalize)
    .join(' ');

export class MyFilter extends BaseFilter<Props, boolean> {
  static id = FILTER_ID;
  static defaultFilterAddOption = (modelName: string = 'entries') => ({
    id: FILTER_ID,
    title: `My ${pascalCase(modelName)}`,
    description: `Only show my ${modelName.toLocaleLowerCase()}.`,
    initialValue: true,
  });

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: MyFilter.defaultFilterAddOption(),
  } as Partial<Props>;

  render() {
    const {disabled} = this.context;
    const {value, initialValue} = this;

    return (
      <div>
        {value && (
          <Chip
            chipModelName={this.props.chipModelName}
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            enableColor={this.hasInitialFilters}
            hasChanges={value !== initialValue}
          />
        )}
      </div>
    );
  }
}
