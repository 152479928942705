import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const style = () =>
  createStyles({
    textField: {},
    outlined: {
      padding: '6px 14px 5px',
    },
    '@global': {
      '.DateRangePickerInput': {
        backgroundColor: 'transparent',
      },

      '.CalendarMonth_caption': {
        fontSize: '14px',
        lineHeight: '21px',
      },

      '.CalendarDay__selected_span, .CalendarDay__hovered_span': {
        background: '#e6d0ff',
        color: '#3e3e3e',
        border: '1px solid #e4e7e7',
      },

      '.CalendarDay__selected, .CalendarDay__selected:hover, .CalendarDay__selected_span:hover, .CalendarDay__hovered_span:hover': {
        background: '#8e00dc',
        color: 'white',
        border: '1px solid #e4e7e7',
      },

      '.DateInput': {
        width: 'auto',
        backgroundColor: 'transparent',
      },
      '.DateInput_input': {
        width: '80px',
        fontSize: '0.9rem',
        padding: '2px 9px 1px',
        color: '#212121',
        backgroundColor: 'transparent',
      },

      '.DateInput_input__regular': {
        padding: '12px 9px 11px',
      },

      '.DateInput_input__focused': {
        borderBottom: '2px solid #9c27b0',
      },

      '.SingleDatePickerInput_calendarIcon, .DateRangePickerInput_calendarIcon': {
        padding: '0px',
        marginTop: '-6px',
        marginLeft: '0px',
      },
      '.DateRangePickerInput_arrow': {
        marginLeft: '0px',
      },
    },
  });

export type Style = WithStyles<typeof style>;
