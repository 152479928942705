import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {toLowerCase} from '../../../../utils/string';
import {BaseFilter, BaseProps} from '../../BaseFilter';
import {RadioFilterPopper} from '../RadioFilter';
import Chip from './Chip';

export enum FormatFilterOptions {
  static = 'static',
  digital = 'digital',
}

type Props = BaseProps<FormatFilterOptions> & {
  options: FormatFilterOptions[];
};
const FILTER_ID = 'format' as const;

export class FormatFilter extends BaseFilter<Props, FormatFilterOptions> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Format',
    description: 'Filter by digital or static format.',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: FormatFilter.defaultFilterAddOption,
    options: [FormatFilterOptions.static, FormatFilterOptions.digital],
  } as Partial<Props>;

  render() {
    const {value = '', context, isOpen: open, tempValue, initialValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;

    return (
      <div>
        {!isEmpty(value) && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
            templateLabel={toLowerCase}
          />
        )}
        {open && (
          <RadioFilterPopper
            headerTitle={this.props.filterAddOption.title}
            options={this.props.options}
            open={open}
            anchorEl={anchorEl}
            value={value}
            tempValue={tempValue}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  }
}
