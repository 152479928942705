import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    '@-webkit-keyframes load': {
      '0%': {
        '-webkit-transform': 'rotate(0deg)',
        transform: 'rotate(0deg)',
      },
      '100%': {
        '-webkit-transform': 'rotate(360deg)',
        transform: 'rotate(360deg)',
      },
    },
    '@keyframes load': {
      '0%': {
        '-webkit-transform': 'rotate(0deg)',
        transform: 'rotate(0deg)',
      },
      '100%': {
        '-webkit-transform': 'rotate(360deg)',
        transform: 'rotate(360deg)',
      },
    },
    spinner: {
      borderRadius: '50%',
      width: 38,
      height: 38,
      margin: '0 auto',
      fontSize: 3.8,
      position: 'relative',
      border: '0.55em solid #dfc8f3',
      borderLeftColor: theme.palette.primary.main,
      '-webkit-transform': 'translateZ(0)',
      '-ms-transform': 'translateZ(0)',
      transform: 'translateZ(0)',
      '-webkit-animation': 'load 1.1s infinite linear',
      animation: 'load 1.1s infinite linear',
    },
  });

export type Styles = WithStyles<typeof styles>;
