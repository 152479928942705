import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {SvgIconProps} from '@material-ui/core/SvgIcon';

import {styles, Styles} from './Icon.style';

export interface DatagridTableViewColumnIconProps extends Styles {
  icon: React.ComponentType<SvgIconProps>;
}

const Presentational: FunctionComponent<DatagridTableViewColumnIconProps> = (props) => {
  return <props.icon classes={{root: `${props.classes.root}`}} />;
};

export default withStyles(styles)(Presentational);
