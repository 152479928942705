import _capitalize from 'lodash/capitalize';

export const toUpperCase = (item: string) => item.toUpperCase();
export const toLowerCase = (item: string) => item.toLowerCase();

export const maybePluralize = (count: number, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;

export const pascalCase = (str: string) =>
  str
    .split(' ')
    .map(_capitalize)
    .join(' ');
