import React, {FunctionComponent} from 'react';

import MuiPaper, {PaperProps as MuiPaperProps} from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

import {styles, Styles} from './Paper.style';

export type PopperPaperProps = MuiPaperProps & Styles;

const Paper: FunctionComponent<PopperPaperProps> = (props) => {
  const {classes, className, children, ...otherProps} = props;

  return (
    <MuiPaper className={`${classes.root} ${className}`} {...otherProps}>
      {children}
    </MuiPaper>
  );
};

export default withStyles(styles)(Paper);
