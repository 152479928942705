import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    headerText: {
      color: '#000',
      fontSize: 14,
      fontWeight: 500,
    },
    header: {
      padding: 0,
    },
    helpCaptionBox: {
      paddingBottom: '0px',
    },
    helpCaption: {
      textTransform: 'uppercase',
      fontSize: 10,
    },
  });

export type Styles = WithStyles<typeof styles>;
