import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 24px',
    },
    children: {
      color: '#eee',
      fontWeight: 400,
      flex: 1,
    },
    backButton: {
      color: '#eee',
      transform: 'translateX(16px)',
    },
  });

export type Styles = WithStyles<typeof styles>;
