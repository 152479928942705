import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import Typography, {TypographyProps} from '@material-ui/core/Typography';

import {styles, Styles} from './SwitchBox.style';

export interface SwitchBoxProps extends Styles {
  title: string;
  disabled?: boolean;
  checked?: boolean;
  children?: TypographyProps['children'];
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const Presentational: FunctionComponent<SwitchBoxProps> = ({
  title,
  children,
  classes,
  checked,
  disabled,
  onChange,
}: SwitchBoxProps) => (
  <div className={classes.root}>
    <div>
      <Typography className={classes.boxTitle}>{title}</Typography>
      <Typography variant="caption" className={classes.caption}>
        {children}
      </Typography>
    </div>
    <Switch
      color="primary"
      classes={{root: classes.switch}}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  </div>
);

export default withStyles(styles)(Presentational);
