import React, {FunctionComponent} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {SvgIconProps} from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/icons/Menu';

import {Styles, styles} from './MainNav.style';

interface Props extends Styles {
  Icon?: React.ComponentType<SvgIconProps>;
  onClickIconButton?: () => void;
}
const MainNav: FunctionComponent<Props> = (props) => {
  const {classes, Icon, children, onClickIconButton} = props;
  return (
    <nav className={classes.root}>
      <IconButton arial-label={'Main Menu'} color="primary" onClick={onClickIconButton}>
        <Icon />
      </IconButton>
      {children}
    </nav>
  );
};

MainNav.defaultProps = {
  Icon: Menu,
};

export default withStyles(styles)(MainNav);
