import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import Legend from './Legend';
import Slice from './Slice';
import {styles, Styles} from './StackedBarChart.style';

const defaultColors = [
  '#AD4BAA',
  '#6A35C4',
  '#356EC4',
  '#D1524D',
  '#BD69E8',
  '#5E44B9',
  '#748EE9',
  '#93DDD8',
  '#EFD77C',
  '#D68A42',
  '#AAAAAA',
  '#FF8A65',
  '#A1887F',
  '#EC407A',
  '#7E57C2',
  '#42A5F5',
  '#26C6DA',
  '#66BB6A',
  '#D4E157',
  '#FFCA28',
];

export type Subject = {
  key: string;
  value: number;
  legendColor?: string;
  sliceBarColor?: string;
};

export type SubjectWithPercentual = Subject & {percentual: number};

export type Props = Styles & {
  subjects: Subject[];
  loading?: boolean;
  percentualFixedDigits?: number;
  noLegend?: boolean;
};

const distributeDiffBetweenPercentualsGreaterThanOne = (
  subjects: SubjectWithPercentual[],
  diff: number,
) => {
  const maxSubjects = [...subjects].sort((s1, s2) => s2.percentual - s1.percentual)[0];

  return subjects.map((s) =>
    s.key === maxSubjects.key ? {...maxSubjects, percentual: maxSubjects.percentual - diff} : s,
  );
};

const calculateSubjectsPercentual = (fixedDigits: number, subjects: (Subject)[], total: number) => {
  if (fixedDigits > 0) {
    return subjects.map((s) => ({
      ...s,
      percentual: Number(((s.value / total) * 100).toFixed(fixedDigits)),
    }));
  }

  const subjectsWithPercentages = subjects.map((s) => ({
    ...s,
    percentual: Math.round((s.value / total) * 100),
  }));
  const diff = subjectsWithPercentages.reduce((a, b) => a + b.percentual, 0) - 100;
  const subjectsWithRoundedPercentages = distributeDiffBetweenPercentualsGreaterThanOne(
    subjectsWithPercentages,
    diff,
  );

  return subjectsWithRoundedPercentages;
};

const StackedBarChart: React.SFC<Props> = (props) => {
  const DEFAULT_PERCENTUAL_FIXED_DIGITS = 2;
  const {
    classes,
    subjects,
    loading,
    noLegend = false,
    percentualFixedDigits = DEFAULT_PERCENTUAL_FIXED_DIGITS,
  } = props;
  const total = subjects.reduce((a, b) => a + b.value, 0);

  const subjectsWithPercentual = calculateSubjectsPercentual(
    percentualFixedDigits,
    subjects,
    total,
  );

  return (
    <div className={classes.root}>
      <div className={classNames(classes.bars, {[classes.loading]: loading})}>
        {!loading &&
          subjectsWithPercentual.map((subject, index) => (
            <Slice
              className={classes.sliceBar}
              key={subject.key}
              title={subject.key}
              percentual={subject.percentual}
              background={subject.sliceBarColor || defaultColors[index]}
            />
          ))}
      </div>
      {!noLegend && (
        <div
          className={classNames(classes.legends, {
            [classes.loading]: loading,
          })}
        >
          {!loading &&
            subjectsWithPercentual.map((subject, index) => (
              <Legend
                key={subject.key}
                className={classes.legend}
                color={subject.legendColor || defaultColors[index]}
                name={subject.key}
                percentual={subject.percentual}
              />
            ))}
        </div>
      )}
    </div>
  );
};

StackedBarChart.defaultProps = {
  noLegend: false,
};

export default withStyles(styles)(StackedBarChart);
