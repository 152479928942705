import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    button: {
      width: 28,
      height: 28,
      padding: 0,
    },
    icon: {
      fontSize: 18,
    },
  });

export type Styles = WithStyles<typeof styles>;
