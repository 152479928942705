import {fade} from '@material-ui/core/styles/colorManipulator';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

const littleFade = (color: string) => fade(color, 0.26);

export const styles = (theme: Theme) =>
  createStyles({
    root: {},
    buttonRoot: {
      margin: 0,
      padding: '0.5rem',
      color: littleFade(theme.palette.text.primary),
      '&:hover': {
        color: fade(littleFade(theme.palette.text.primary), 0.4),
        backgroundColor: 'transparent',
      },
    },
    headerRoot: {
      height: 48,
    },
    headerButton: {
      transform: 'translateX(10px)',
    },
    footerRoot: {
      minHeight: 50,
      justifyContent: 'flex-end',
    },
    helpButton: {
      transform: 'translateX(-10px)',
      marginRight: 'auto',
    },
    footerButton: {
      color: '#000',
      '&:disabled': {
        color: 'rgba(0, 0, 0, 0.5)',
      },
    },
    footerButtonLabel: {
      fontSize: '13px',
      lineHeight: '15px',
      fontWeight: 500,
    },
    cancelButton: {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    headerText: {},
    paper: {},
  });

export type Styles = Partial<WithStyles<typeof styles>>;
