import React from 'react';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {CssTooltip, CssTooltipProps} from '../CssTooltip';
import {styles} from './ColoredIndicator.style';

export interface ColoredIndicatorProps extends WithStyles<typeof styles> {
  dense?: boolean;
  onIndicatorClick?: () => void;
  tooltipProps?: Partial<CssTooltipProps>;
}

const ColoredIndicator: React.SFC<ColoredIndicatorProps> = (props) => {
  const {classes, dense, onIndicatorClick, tooltipProps} = props;
  const {title, ...otherCssTooltipProps} = tooltipProps;

  return (
    <CssTooltip title={title} {...otherCssTooltipProps}>
      <div
        className={classNames(classes.root, {
          [classes.clickable]: onIndicatorClick,
          [classes.dense]: dense,
        })}
        aria-label={title}
        onClick={onIndicatorClick}
      />
    </CssTooltip>
  );
};

ColoredIndicator.defaultProps = {
  onIndicatorClick: null,
  tooltipProps: {title: ''},
};

export default withStyles(styles)(ColoredIndicator);
