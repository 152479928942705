import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './Disclaimer.style';
import {DisclaimerPaper, DisclaimerPaperProps} from './Paper';

export interface DisclaimerProps extends Styles {
  title?: DisclaimerPaperProps['headerTitle'];
  onEmptyAreaClick: (ev: React.MouseEvent<HTMLDivElement>) => void;
}

const Disclaimer: FunctionComponent<DisclaimerProps> = ({
  classes,
  title,
  children,
  onEmptyAreaClick,
}) => {
  return (
    <div className={classes.root}>
      <DisclaimerPaper headerTitle={title} className={classes.paper}>
        {children}
      </DisclaimerPaper>
      {/* container just to represent transparent empty area */}
      <div className={classes.emptyArea} onClick={onEmptyAreaClick} />
    </div>
  );
};

export default withStyles(styles)(Disclaimer);
