import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      minHeight: 54,
      height: 54,
      borderBottom: '1px solid #E6E6E6',
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
    },
  });

export type Styles = WithStyles<typeof styles>;
