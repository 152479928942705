import React, {FunctionComponent} from 'react';

import Avatar from '@material-ui/core/Avatar';
import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {LoadingSpinner} from '../../LoadingSpinner';
import {Styles, styles} from './UserMenuImage.style';

export type UserMenuImageProps = IconButtonProps &
  Styles & {
    imageUrl: string;
    userName: string;
    loading: boolean;
  };

const UserMenuImage: FunctionComponent<UserMenuImageProps> = ({
  imageUrl,
  userName,
  classes,
  className,
  loading,
  ...iconButtonProps
}) => {
  const imgSizeAttributes: any = {width: 30, height: 30};
  return (
    <IconButton className={classNames(classes.root, className)} {...iconButtonProps}>
      {loading && <LoadingSpinner className={classes.progress} />}
      <Avatar
        className={classes.avatar}
        src={imageUrl}
        alt={userName}
        imgProps={{...imgSizeAttributes}}
      />
    </IconButton>
  );
};

UserMenuImage.defaultProps = {
  color: 'primary',
};

export default withStyles(styles)(UserMenuImage);
