import React, {FunctionComponent} from 'react';

import MuiDrawer, {DrawerProps as MuiDrawerProps} from '@material-ui/core/Drawer';
import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './Drawer.style';
import {DrawerHeader, DrawerHeaderProps} from './Header';

export type DrawerProps = MuiDrawerProps &
  Styles & {
    title: DrawerHeaderProps['children'];
  };

const Drawer: FunctionComponent<DrawerProps> = ({title, children, ...drawerProps}) => (
  <MuiDrawer {...drawerProps}>
    <DrawerHeader onBackClick={drawerProps.onClose}>{title}</DrawerHeader>
    {children}
  </MuiDrawer>
);

export default withStyles(styles)(Drawer);
