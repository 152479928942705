import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './Badge.style';

export type BadgeProps = Styles & {
  type?: keyof Pick<Styles['classes'], 'danger' | 'warning'>;
};

const Badge: FC<BadgeProps> = ({classes, children, type}) => (
  <div className={classNames(classes.root, classes[type])}>{children}</div>
);

Badge.defaultProps = {
  type: 'warning',
};

export default withStyles(styles)(Badge);
