import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {DatagridContext} from '../../../Datagrid/Datagrid.context';
import HeaderBar, {DatagridHeaderBarProps} from './HeaderBar';
import {styles} from './HeaderBar.style';

const DatagridHeader: FunctionComponent<DatagridHeaderBarProps> = (props) => {
  const {classes, children} = props;

  const renderProps = children as (items, viewMode) => JSX.Element;

  return (
    <DatagridContext.Consumer>
      {({items, viewMode}) => (
        <HeaderBar classes={classes}>{renderProps(items, viewMode)}</HeaderBar>
      )}
    </DatagridContext.Consumer>
  );
};

export default withStyles(styles)(DatagridHeader);
