import React, {HTMLProps} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import {Styles, styles} from './MapView.style';

export interface DatagridMapViewProps extends Styles, HTMLProps<HTMLDivElement> {
  visible?: boolean;
}

const DatagridMapView: React.FunctionComponent<DatagridMapViewProps> = ({
  classes,
  children,
  visible,
  ...otherProps
}) => {
  return (
    <div className={classnames(classes.mapView, {[classes.visible]: visible})} {...otherProps}>
      {children}
    </div>
  );
};

DatagridMapView.defaultProps = {
  visible: false,
};

export default withStyles(styles)(DatagridMapView);
