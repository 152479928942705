import React, {FunctionComponent} from 'react';

import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import _capitalize from 'lodash/capitalize';

import {AutocompleteSize} from '../../Autocomplete';

const styles = () => {
  return createStyles({
    root: {
      cursor: 'default',
      backgroundColor: 'rgb(230, 230, 230)',
      borderRadius: 2,
      display: 'flex',
      margin: 2,
      minWidth: 0,
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
    text: {
      color: 'rgb(51, 51, 51)',
      fontSize: '12px',
      padding: '3px 3px 3px 6px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
    },
    remove: {
      cursor: 'pointer',
      WebkitBoxAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 4,
      paddingRight: 4,
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: 'rgb(208, 208, 208)',
        color: 'rgb(74, 74, 74)',
      },
    },
    removeIcon: {
      display: 'inline-block',
      fill: 'currentcolor',
      lineHeight: 1,
      stroke: 'currentcolor',
      strokeWidth: 0,
      color: '#4a4a4a',
    },
  });
};

type Styles = Partial<WithStyles<typeof styles>>;
interface Props extends Styles {
  'data-testid': string;
  iconSize: AutocompleteSize;
  onRemove: () => void;
}

const CustomChip: FunctionComponent<Props> = ({
  'data-testid': dataTestId,
  classes,
  iconSize,
  children,
  onRemove,
}) => {
  return (
    <div tabIndex={-1} className={classes.root}>
      <div data-testid={dataTestId ? `${dataTestId}-value` : dataTestId} className={classes.text}>
        {children}
      </div>
      <div
        data-testid={dataTestId ? `${dataTestId}-removeButton` : dataTestId}
        className={classes.remove}
        onClick={onRemove}
      >
        <svg
          className={classes.removeIcon}
          height={iconSize === AutocompleteSize.small ? 14 : 16}
          width={iconSize === AutocompleteSize.small ? 14 : 16}
          viewBox="0 0 20 20"
          focusable="false"
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
      </div>
    </div>
  );
};

export default withStyles(styles)(CustomChip);
