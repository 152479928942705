import React, {FC, MouseEvent, useState} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import SettingsPopper, {SettingsPopperProps} from '../../common/SettingsPopper';
import {TargetBudgetValue} from '../TargetBudget';
import Content from './Content';
import {Styles, styles} from './SettingsPopper.style';

interface Props
  extends Pick<SettingsPopperProps, 'open' | 'anchorEl' | 'onClose' | 'placement' | 'title'>,
    Styles {
  budget: TargetBudgetValue;
  onChange: (changes: TargetBudgetValue) => void;
}

const Presentational: FC<Props> = (props) => {
  const {open, anchorEl, budget, placement, title, onChange, onClose} = props;

  const [state, setState] = useState(budget);

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    onChange(state);
    onClose(event);
  };

  return (
    <SettingsPopper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      title={title}
      disabled={state.maxValue < 1}
      onSubmit={handleSubmit}
      onClose={onClose}
    >
      <Content
        autoFocus
        maxValue={state.maxValue}
        onChange={(total) => setState({...state, maxValue: total})}
      />
    </SettingsPopper>
  );
};
export default withStyles(styles)(Presentational);
