import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    buttonIcon: {
      fontSize: 16,
    },
    buttonSpin: {
      'animation-name': 'spinRefreshButton',
      'animation-duration': '1000ms',
      'animation-iteration-count': 'infinite',
      'animation-timing-function': 'linear',
    },
    '@keyframes spinRefreshButton': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
