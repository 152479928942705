import {Theme} from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flexGrow: 1,
      position: 'sticky',
      backgroundColor: '#fff',
      zIndex: 1520,
    },
    tabsRoot: {
      padding: '0px 15px',
    },
    tabRoot: {
      fontWeight: 400,
      minWidth: 'unset',
    },
    scrollButtons: {
      width: 15,
      color: theme.palette.action.active,
    },
    tabLabel: {
      fontSize: '0.75rem',
    },
    tabDisabled: {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
    tabWrapper: {
      width: 'auto',
    },
    indicatorTop: {
      top: 0,
    },
    separator: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    },
  });
export type Styles = Partial<WithStyles<typeof styles>>;
