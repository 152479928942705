import React, {Component, MouseEvent} from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Code from '@material-ui/icons/Code';
import classNames from 'classnames';

import {nope} from '../../utils/function';
import {JsonDebugger} from '../JsonDebugger';
import {Styles, styles} from './FormActions.style';

export type FormActionsProps = Styles & {
  loadingStateActiveOnSubmitButton?: boolean;
  noBackButton?: boolean;
  noJsonDebugger?: boolean;
  noSubmitButton?: boolean;
  submitButtonName?: string;
  objectToDebug?: any;
  submitButtonDisabled?: boolean;
  shouldHaveTooltip?: boolean;
  disableMouseCursor?: boolean;
  onChangeDebugObject?: (object: any) => void;
  onClickBackButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickRemoveButton?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export interface FormActionsState {
  debugModeEnabled: boolean;
  arrowRef: HTMLSpanElement;
}

class FormActions extends Component<FormActionsProps, FormActionsState> {
  static defaultProps: Partial<FormActionsProps> = {
    submitButtonName: 'Save',
    loadingStateActiveOnSubmitButton: false,
    noBackButton: false,
    noJsonDebugger: false,
    noSubmitButton: false,
    objectToDebug: {},
    shouldHaveTooltip: false,
    submitButtonDisabled: false,
    disableMouseCursor: false,
    onChangeDebugObject: nope,
    onClickBackButton: nope,
  };

  state: FormActionsState = {
    debugModeEnabled: false,
    arrowRef: null,
  };

  render() {
    const {
      submitButtonName,
      loadingStateActiveOnSubmitButton,
      noBackButton,
      noJsonDebugger,
      noSubmitButton,
      objectToDebug,
      submitButtonDisabled,
      onChangeDebugObject,
      onClickRemoveButton,
      classes,
      disableMouseCursor,
      shouldHaveTooltip,
    } = this.props;

    const {debugModeEnabled} = this.state;

    return (
      <div className={classes.formActions}>
        <div className={classes.formGroupSubmit}>
          {!noBackButton && this.backButton()}

          {!noSubmitButton &&
            this.submitButton(
              shouldHaveTooltip,
              classes,
              disableMouseCursor,
              submitButtonDisabled,
              loadingStateActiveOnSubmitButton,
              submitButtonName,
            )}

          {onClickRemoveButton &&
            this.removeButton(classes, loadingStateActiveOnSubmitButton, submitButtonDisabled)}

          {!noJsonDebugger && (
            <IconButton className="btn-icon" color="primary" onClick={this.toggleDebugMode}>
              <Code />
            </IconButton>
          )}
        </div>

        {!noJsonDebugger && debugModeEnabled && (
          <div style={{marginTop: '1rem'}}>
            <JsonDebugger object={objectToDebug} onChange={onChangeDebugObject} />
          </div>
        )}
      </div>
    );
  }

  toggleDebugMode = () => {
    this.setState((state) => ({debugModeEnabled: !state.debugModeEnabled}));
  };

  backButton(): React.ReactNode {
    const {onClickBackButton} = this.props;
    return (
      <IconButton title="Go back" color="primary" className="btn-icon" onClick={onClickBackButton}>
        <ArrowBack />
      </IconButton>
    );
  }

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };
  submitButton = (
    shouldHaveTooltip: boolean,
    classes: Styles['classes'],
    disableMouseCursor: boolean,
    submitButtonDisabled: boolean,
    loadingStateActiveOnSubmitButton: boolean,
    submitButtonName: string,
  ): React.ReactNode => {
    const {arrowRef} = this.state;
    const openTooltip = shouldHaveTooltip && submitButtonDisabled;
    const tootipComponent =
      shouldHaveTooltip && submitButtonDisabled ? (
        <>
          Invalid Selection
          <span className={classes.arrow} ref={this.handleArrowRef} />
        </>
      ) : (
        ''
      );
    return (
      <Tooltip
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: true,
                element: arrowRef,
              },
            },
          },
        }}
        classes={{
          popper: openTooltip ? classes.tooltipPopper : classes.disable,
          tooltip: openTooltip ? classes.tooltip : classes.disable,
        }}
        title={tootipComponent}
        placement={'bottom'}
      >
        <span
          className={classNames({
            [classes.disableMouseCursor]: disableMouseCursor && submitButtonDisabled,
            [classes.buttonLoading]: loadingStateActiveOnSubmitButton,
          })}
        >
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={submitButtonDisabled || loadingStateActiveOnSubmitButton}
            type="submit"
          >
            {submitButtonName}
          </Button>
        </span>
      </Tooltip>
    );
  };

  removeButton(
    classes: Styles['classes'],
    loadingStateActiveOnSubmitButton: boolean,
    submitButtonDisabled: boolean,
  ): React.ReactNode {
    const {onClickRemoveButton} = this.props;
    return (
      <span
        className={classNames({
          [classes.buttonLoading]: loadingStateActiveOnSubmitButton,
        })}
      >
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          disabled={submitButtonDisabled || loadingStateActiveOnSubmitButton}
          onClick={onClickRemoveButton}
        >
          Remove
        </Button>
      </span>
    );
  }
}

export default withStyles(styles)(FormActions);
