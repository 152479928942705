import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    loading: {
      background: 'linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%)',
      backgroundSize: '800px 104px',
      position: 'relative',
      width: '100%'
    },
    hasPlaceholder: {
      width: 'unset',
      display: 'inline',
    },
  });

export type Style = WithStyles<typeof styles>;
