import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: '0 4px',
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
