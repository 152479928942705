import React, {ChangeEvent, Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import {nope} from '../../utils/function';

import {Styles, styles} from './JsonDebugger.style';

export interface JsonDebuggerProps extends Styles {
  className?: string;
  object: any;
  spaces?: number;
  onChange?: (object: any) => void;
}

export interface JsonDebuggerState {
  editionModeEnabled: boolean;
  hasError: boolean;
  json: any;
}

class JsonDebugger extends Component<JsonDebuggerProps, JsonDebuggerState> {
  static defaultProps: Partial<JsonDebuggerProps> = {
    spaces: 2,
    onChange: nope,
  };

  state: JsonDebuggerState;
  private textarea: HTMLTextAreaElement;
  private updatedFromInside: boolean;

  constructor(props: JsonDebuggerProps) {
    super(props);

    this.state = {
      editionModeEnabled: false,
      hasError: false,
      json: this.stringifyObject(),
    };
  }

  componentDidMount() {
    this.updateTextareaHeight();
  }

  componentWillReceiveProps(nextProps: JsonDebuggerProps) {
    if (!this.updatedFromInside) {
      const json = this.stringifyObject(nextProps.object);
      this.setState({json});
    }
  }

  componentDidUpdate() {
    this.updatedFromInside = false;
    this.updateTextareaHeight();
  }

  render() {
    const {classes, className} = this.props;
    const {editionModeEnabled, hasError} = this.state;
    const value = this.getTextareaValue();

    return (
      <div className={classNames(classes.root, className)}>
        {hasError && (
          <div className={classes.errorContainer}>
            <Typography className={classes.errorMessage}>Invalid JSON</Typography>
          </div>
        )}

        <textarea
          autoCorrect="off"
          autoCapitalize="off"
          ref={(instance) => (this.textarea = instance)}
          spellCheck={false}
          value={value}
          onBlur={this.setFocus(false)}
          onChange={this.handleChange}
          onFocus={this.setFocus(true)}
          className={classNames(classes.textarea, {
            [classes.textareaActive]: editionModeEnabled,
            [classes.textareaError]: hasError,
          })}
        />
      </div>
    );
  }

  getTextareaValue() {
    return this.updatedFromInside ? this.state.json : this.stringifyObject();
  }

  stringifyObject = (object: any = this.props.object) => {
    const {spaces} = this.props;
    return JSON.stringify(object, null, spaces);
  };

  handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const json = e.target.value;

    this.setState({
      hasError: false,
      json,
    });

    this.updatedFromInside = true;

    try {
      const object = JSON.parse(json);
      this.props.onChange(Object.assign({}, object));
    } catch (error) {
      this.setState({hasError: true});
    }
  };

  setFocus = (focused: boolean) => () => {
    this.setState({editionModeEnabled: focused});
  };

  updateTextareaHeight() {
    this.textarea.style.height = '1px';
    this.textarea.style.height = `${this.textarea.scrollHeight}px`;
  }
}

export default withStyles(styles)(JsonDebugger);
