import React, {FunctionComponent, HTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';

import {Option} from '../../../models/Option';
import {styles, Styles} from './Item.style';

export type RemovableListItemProps = Styles &
  Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> & {
    'data-testid'?: string;
    item: Option;
    selected?: boolean;
    disabled?: boolean;
    hideBorderToLast?: boolean;
    children?: (item: Option) => string | JSX.Element;
    onClick?: (op: Option, evt: React.MouseEvent<HTMLDivElement>) => void;
    onRemove: (op: Option) => void;
  };

const handleClick = (callback: RemovableListItemProps['onClick'], value: Option) => (
  evt: React.MouseEvent<HTMLDivElement>,
) => {
  callback(value, evt);
};

const handleOnRemove = (callback: RemovableListItemProps['onRemove'], value: Option) => (
  evt: React.MouseEvent<Element>,
) => {
  evt.stopPropagation();
  callback(value);
};

const Item: FunctionComponent<RemovableListItemProps> = (props) => {
  const {
    'data-testid': dataTestId,
    classes,
    children,
    item,
    selected,
    disabled,
    hideBorderToLast,
    onClick,
    onRemove,
    ...otherProps
  } = props;

  return (
    <div
      {...otherProps}
      className={classNames(classes.root, {
        [classes.selected]: selected,
        [classes.lastChild]: !hideBorderToLast,
      })}
      data-testid={dataTestId ? `${dataTestId}-valueContainer` : dataTestId}
      onClick={handleClick(onClick, item)}
    >
      <Typography
        data-testid={dataTestId ? `${dataTestId}-value` : dataTestId}
        className={classes.label}
        component="div"
      >
        {children(item) || item.label}
      </Typography>
      {!disabled && (
        <div className={classes.buttonContainer}>
          <CloseIcon
            data-testid={dataTestId ? `${dataTestId}-removeButton` : dataTestId}
            className={classes.icon}
            onClick={handleOnRemove(onRemove, item)}
          />
        </div>
      )}
    </div>
  );
};

Item.defaultProps = {
  children: (item) => item.label,
  onClick: () => null,
  onRemove: () => null,
};

export default withStyles(styles)(Item);
