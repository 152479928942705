import React, {FunctionComponent, ReactNode} from 'react';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import {styles} from './styles';

export interface ListProps extends WithStyles<typeof styles> {
  items: (ItemProps['item'])[];
  children?: ItemProps['children'];
}

type Item = {value?: string; label: string; disabledDrag?: boolean};
interface ItemProps {
  item: Item;
  sortIndex: number;
  children?: (props: {item: Item; sortIndex: number}) => ReactNode;
}
const ChildrenItem = ({item, sortIndex, children}: ItemProps) => {
  return <>{children({item, sortIndex})}</>;
};
const SortableItem = SortableElement(ChildrenItem);

const List: FunctionComponent<ListProps> = (props) => {
  const {classes, items, children} = props;

  return (
    <div className={classes.root}>
      {items &&
        items.map((item, index) => {
          return (
            <SortableItem
              key={`${item.value}_${index}`}
              item={item}
              index={index}
              sortIndex={index}
              disabled={item.disabledDrag}
            >
              {children}
            </SortableItem>
          );
        })}
    </div>
  );
};

export default withStyles(styles)(SortableContainer(List));
