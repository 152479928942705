import React from 'react';

import _equal from 'fast-deep-equal';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';

import {ScrollbarsType} from '../../Scrollbars/Scrollbars';
import {
  FilterContext,
  FilterContextActions,
  FilterContextState,
  initialState,
} from '../FilterContext';

type Props = {};
export type State = FilterContextState;

export default class FilterSlotProvider extends React.PureComponent<Props, State> {
  private scrollBarsRef = React.createRef<ScrollbarsType>();

  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  removeListener: (id: string) => void;

  addRemoveFilterListener = (callback: (id: string) => void) => {
    this.removeListener = callback;
  };

  tryOpenTipToApply = debounce(() => {
    this.setState({shouldOpenTipToApply: true});
  }, 3000);

  onRemoveFilter = (filterId: string) => {
    this.tryOpenTipToApply();
    this.setState((state) => {
      const {[filterId]: _removed, ...shownFilters} = state.shownFilters;
      const tempFilters = {...state.tempFilters, [filterId]: null};

      const order = state.order.filter((f) => filterId !== f);
      return {order, openFilter: '', shownFilters, tempFilters};
    });
    if (this.removeListener) {
      this.removeListener(filterId);
    }
  };

  handleChangeFilter = (id: string, value: any) => {
    this.tryOpenTipToApply();
    const isAdding = !(id in this.state.shownFilters);

    this.setState((state) => {
      const isAdding = !(id in state.shownFilters);
      const outOfOrder = !state.order.includes(id);

      const order = isAdding && outOfOrder ? [...state.order, id] : state.order;
      const shownFilters = {...state.shownFilters, [id]: value};
      const tempFilters = {...state.tempFilters, [id]: null};
      return {order, shownFilters, tempFilters, openFilter: ''};
    });

    if (isAdding) {
      this.scrollToEnd();
    }
  };

  scrollToEnd = () => {
    // wait to react render process
    setImmediate(() => {
      this.scrollBarsRef.current.scrollToRight(true);
    });
  };

  setContextState = (newState: Partial<State>) => {
    this.setState((state) => ({...state, ...newState}));
  };

  handleFilterChipClick = (filterId: string) => (anchorEl: HTMLElement) => {
    this.setState({openFilter: filterId, anchorEl});
  };

  handleFilterClose = () => {
    this.setState({openFilter: ''});
  };

  handleFilterAddSelected = (filterId: string, initialValue?: any) => {
    if (!isNil(initialValue)) {
      this.handleChangeFilter(filterId, initialValue);
    } else {
      this.setState({openFilter: filterId});
    }
  };

  handleRemoveAllFilters = () => {
    this.setState({
      order: [],
      openFilter: '',
      shownFilters: {},
      appliedFilters: {},
      tempFilters: {},
    });
  };

  handleSetTempValue = (id: string, tempValue: any) => {
    const tempFilters = {...this.state.tempFilters, [id]: tempValue};
    this.setState({tempFilters});
  };

  getActions = () => {
    const actions: FilterContextActions = {
      handlePopperClose: this.handleFilterClose,
      handleRemoveFilter: this.onRemoveFilter,
      handleChangeFilter: this.handleChangeFilter,
      handleChipFilterClick: this.handleFilterChipClick,
      handleAddFilter: this.handleFilterAddSelected,
      handleRemoveAllFilters: this.handleRemoveAllFilters,
      handleSetTempValue: this.handleSetTempValue,
      setState: this.setContextState,
      addRemoveFilterListener: this.addRemoveFilterListener,
      tryOpenTipToApply: this.tryOpenTipToApply,
      scrollbarsRef: this.scrollBarsRef,
    };

    return actions;
  };
  render() {
    const {children} = this.props;

    const actions = this.getActions();

    return (
      <FilterContext.Provider value={{...this.state, ...actions}}>
        {children}
      </FilterContext.Provider>
    );
  }
}
