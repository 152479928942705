import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: 220,
    },
    footerRoot: {backgroundColor: 'red'},
    footerBorder: {
      borderTop: '1px solid rgba(0,0,0,0.1)',
    },
    tempChangesIndicator: {
      marginRight: 8,
      backgroundColor: '#F8C244',
    },
    changeIndicator: {
      backgroundColor: 'rgba(248, 194, 68, 0.25)',
    },
    root: {},
    headerRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    headerTitleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    headerButton: {},
    helpButton: {},
    footerButton: {},
    footerButtonLabel: {},
    cancelButton: {},
    headerText: {},
  });

export type Styles = WithStyles<typeof styles>;
