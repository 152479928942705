import {PopperProps} from 'src/components/Popper';

const HEADER_BAR_HEIGHT = 54;

export const ParentPopperModifiers: PopperProps['modifiers'] = {
  offset: {offset: '0px,0px'},
  preventOverflow: {
    padding: {top: HEADER_BAR_HEIGHT},
  },
};

export const SubPopperModifiers: PopperProps['modifiers'] = {
  offset: {offset: `-10px,${HEADER_BAR_HEIGHT + 10}px`},
  preventOverflow: {
    enabled: true,
    padding: {
      top: HEADER_BAR_HEIGHT * 2,
    },
  },
};
