import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {},
    content: {},
    paper: {
      minWidth: 200,
      minHeight: 'auto',
    },
  });

export type Styles = WithStyles<typeof styles>;
