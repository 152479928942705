import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {styles, Styles} from './Anchor.style';

interface Props extends Styles, React.AnchorHTMLAttributes<HTMLAnchorElement> {}

const Anchor: FunctionComponent<Props> = (props) => {
  const {children, className, classes, ...otherProps} = props;
  return (
    <a className={classNames([classes.root, className])} {...otherProps}>
      {children}
    </a>
  );
};

export default withStyles(styles)(Anchor);
