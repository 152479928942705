import React, {SFC} from 'react';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';

import {ConditionalWrap} from '../ConditionalWrap';
import {styles} from './ArrowTooltip.style';
import {InjectedArrowTooltipableProps, makeArrowTooltipable} from './makeArrowTooltipable';

export interface ArrowTooltipProps
  extends WithStyles<typeof styles>,
    InjectedArrowTooltipableProps,
    Omit<TooltipProps, 'classes'> {}

const ArrowTooltip: SFC<ArrowTooltipProps> = (props) => {
  const {
    classes,
    children,
    tooltipArrowRef,
    onSetTooltipArrowRef,
    title,
    placement,
    PopperProps,
    TransitionProps,
    ...otherProps
  } = props;
  return (
    <ConditionalWrap
      condition={!!title && !!children}
      wrap={(children) => (
        <Tooltip
          PopperProps={{
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: Boolean(tooltipArrowRef),
                  element: tooltipArrowRef,
                },
              },
            },
            ...PopperProps,
          }}
          TransitionProps={{
            timeout: 0,
            ...TransitionProps,
          }}
          classes={{
            ...ArrowClasses(classes),
          }}
          title={
            <>
              {title}
              <span className={classes.arrow} ref={onSetTooltipArrowRef} />
            </>
          }
          placement={placement}
          {...otherProps}
        >
          {children}
        </Tooltip>
      )}
    >
      {children}
    </ConditionalWrap>
  );
};

const ArrowClasses = (classes) => {
  return {
    popper: classes.popper,
    tooltip: classes.tooltip,
    popperInteractive: classes.popperInteractive,
    tooltipPlacementBottom: classes.tooltipPlacementBottom,
    tooltipPlacementLeft: classes.tooltipPlacementLeft,
    tooltipPlacementRight: classes.tooltipPlacementRight,
    tooltipPlacementTop: classes.tooltipPlacementTop,
    touch: classes.touch,
  };
};

ArrowTooltip.defaultProps = {
  placement: 'bottom-start',
};

export default withStyles(styles)(makeArrowTooltipable(ArrowTooltip));
