import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './Title.style';

interface Props extends Styles {}

const DatagridCardViewTitle: React.FunctionComponent<Props> = (props) => {
  const {children, classes} = props;
  return <div className={classes.cardTitle}>{children}</div>;
};

export default withStyles(styles)(DatagridCardViewTitle);
