import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiSlider from '@material-ui/lab/Slider';
import {SliderProps as MuiSliderProps} from '@material-ui/lab/Slider';

import {Styles, styles} from './Slider.style';

export type SliderProps = Styles & MuiSliderProps & {autoFocus?: boolean, ['data-testLabel']?: string};
class Slider extends React.Component<SliderProps, {}> {
  sliderRef = React.createRef<HTMLSpanElement>();

  componentDidMount() {
    const {autoFocus} = this.props;
    const sliderThumbEl = this.sliderRef;

    if (sliderThumbEl && autoFocus) {
      setTimeout(() => {
        sliderThumbEl.current.parentElement.focus();
      }, 100);
    }
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange?.(event, Number(event.target.value));
  };

  render() {
    const { ['data-testLabel']: dataTestLabel, ...props } = this.props;

    return (
      <>
        <MuiSlider thumb={<span ref={this.sliderRef} />} {...props} />
        {
          /** 
           * Hidden input for component testing purposes, because we can't easily
           * modify slider position using testing-library. 
           * More information: https://testing-library.com/docs/guide-events/ 
           * */ 
          !!dataTestLabel && (
            <input
              type='hidden'
              aria-label={dataTestLabel}
              value={props.value}
              step={props.step}
              min={props.min}
              max={props.max}
              onChange={this.handleInputChange}
            />
          )
        }
      </>
    );
  };
}

export default withStyles(styles)(Slider);
