import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    button: {
      color: 'rgba(0,0,0,0.54)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      // '&:focus': {
      //   backgroundColor: 'rgba(0, 0, 0, 0.1)',
      // },
      width: 28,
      height: 28,
      padding: 0,
    },
    icon: {
      fontSize: 18,
    },
  });

export type Styles = WithStyles<typeof styles>;
