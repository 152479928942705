import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

const inputLabelPropsRoot = {
  pointerEvents: 'initial' as const,
};

export const styles = () =>
  createStyles({
    input: {
      height: 'auto',
    },
    sizeSmall: {
      padding: '10.5px 14px',
      fontSize: 14,
    },
    sizeLarge: {},
    sizeSmallBorder: {borderRadius: 3},
    sizeLargeBorder: {},
    sizeSmallInputLabelPropsRoot: {
      ...inputLabelPropsRoot,
      fontSize: '12px',
      lineHeight: '12px',
      marginBottom: '.6rem',
      color: 'rgba(0,0,0,0.6)',
    },
    sizeLargeInputLabelPropsRoot: {
      ...inputLabelPropsRoot,
    },
    sizeSmallInputLabelPropsFormControl: {
      position: 'unset',
    },
    sizeLargeInputLabelPropsFormControl: {},
    stepper: {
      '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
        opacity: 1,
      },
    },
    adornedEnd: {
      paddingRight: 0,
    },
  });

export type Styles = WithStyles<typeof styles>;
