import ClearIndicator from './components/ClearIndicator';
import Control from './components/Control';
import DropdownIndicator from './components/DropdownIndicator';
import Group from './components/Group';
import Input from './components/Input';
import Menu from './components/Menu';
import MenuList from './components/MenuList';
import MultiValue from './components/MultiValue';
import NoOptionsMessage from './components/NoOptionsMessage';
import Option from './components/Option';
import Placeholder from './components/Placeholder';
import SingleValue from './components/SingleValue';
import ValueContainer from './components/ValueContainer';

export const commonComponents = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  Input,
  Menu,
  MenuList,
  Group,
  MultiValue,
  ClearIndicator,
};

export const asyncComponents = {
  ...commonComponents,
  LoadingMessage: NoOptionsMessage,
};
