import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    searchWrapper: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: '#FFF',
      paddingLeft: 20,
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.grey[200],
    },
    startAdornment: {
      color: '#cccccc',
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
