import {Theme} from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.paper,
      minWidth: 180,
    },
    userInfo: {
      margin: '16px 16px 0',
    },
    userName: {
      fontSize: 14,
      fontWeight: 700,
    },
    userEmail: {
      fontSize: 12,
    },
    buttonWrapper: {
      margin: 9,
    },
    button: {
      fontSize: 11,
    },
    versionLabel: {
      margin: 16,
      fontSize: 12,
    },
  });

export type Styles = WithStyles<typeof styles>;
