import React from 'react';

import _capitalize from 'lodash/capitalize';

import Chip from './Chip';

const MultiValue = (props) => {
  const {selectProps} = props;
  const {'data-testid': dataTestId, classes, size} = selectProps;
  return (
    <Chip
      classes={{text: classes[`multiValueChip${_capitalize(size)}`]}}
      iconSize={size}
      onRemove={props.removeProps.onClick}
      data-testid={dataTestId}
    >
      {props.children}
    </Chip>
  );
};

MultiValue.muiName = 'MultiValue';

export default MultiValue;
