import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    itemTextRoot: {
      paddingRight: 0,
    },
    itemTextPrimary: {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.7)',
      '&$textDense': {
        fontSize: 14,
      },
    },
    textDense: {},
    disabledItem: {
      opacity: 1,
    },
    disabledText: {
      color: 'rgba(0, 0, 0, 0.4)',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  });

export type Styles = WithStyles<typeof styles>;
