import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    loading: {
      animationDuration: '1s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: 'placeHolderShimmer',
      animationTimingFunction: 'linear',
      background: 'linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%)',
      backgroundSize: '800px 104px',
      position: 'relative',
    },
    bars: {
      display: 'flex',
      minHeight: 20,
      overflow: 'hidden',
      borderRadius: 30,
    },
    sliceBar: {},
    legends: {
      display: 'flex',
      marginTop: '0.5rem',
      justifyContent: 'space-between',
      minHeight: 21,
    },
    legend: {},
    '@keyframes placeHolderShimmer': {
      '0%': {
        backgroundPosition: '-468px 0',
      },
      '100%': {
        backgroundPosition: '468px 0',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
