import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {Link} from '../../../Link';

import {Styles, styles} from './MadeBySection.style';

type DrawerMadeBySectionProps = Styles;

const Presentational: FunctionComponent<DrawerMadeBySectionProps> = ({classes}) => {
  return (
    <Typography className={classes.root}>
      Made with ❤️ by Beakyn Co. in Brazil.
      <br />
      <Link className={classes.contact} href="mailto:contact@beakyn.com" title="Contact Us">
        contact@beakyn.com
      </Link>
    </Typography>
  );
};

Presentational.defaultProps = {};

export default withStyles(styles)(Presentational);
