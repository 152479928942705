import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: 8,
      '& :not(:first-child):before': {
        content: '"/"',
        padding: '0 0.5rem',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
