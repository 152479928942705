import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      flex: 1,
      display: 'block',
      width: '100%',
      height: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    grid: {
      position: 'relative',
      minWidth: '100%',
      width: 'fit-content',
      // Prevent position sticky on header to move when grid is outside scroll
      minHeight: '100%',
    },
    head: {
      position: 'sticky',
      top: 0,
      zIndex: 15,
    },
    headRow: {
      backgroundColor: '#f9f9f9',
    },
    body: {},
    bodyRows: {},
  });

export type Styles = WithStyles<typeof styles>;
