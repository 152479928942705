import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      height: 54,
      backgroundColor: '#fff',
      zIndex: 2,
      '&:hover': {
        backgroundColor: 'rgb(250, 250, 250)',
      },
    },
    selected: {
      backgroundColor: '#f8effe',
      '&:hover': {
        backgroundColor: '#edeaff',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
