import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 0,
      paddingTop: 10,
      marginBottom: 0,
      paddingBottom: theme.spacing.unit,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'sticky',
      bottom: 0,
      backgroundColor: '#fff',
      borderTop: '1px solid #E6E6E6',
    },
    countLabel: {
      color: 'rgba(0,0,0,0.8)',
      fontSize: 12,
    },
    clearAllButton: {
      color: 'rgba(0,0,0,0.54)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      width: 28,
      height: 28,
      padding: 0,
    },
    clearAllIcon: {
      fontSize: 18,
    },
  });

export type Styles = WithStyles<typeof styles>;
