import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import _capitalize from 'lodash/capitalize';

const Option = (props) => {
  const {selectProps, innerProps} = props;
  const {'data-testid': dataTestId, classes, virtualized, size} = selectProps;

  if (virtualized) {
    delete innerProps.onMouseMove;
    delete innerProps.onMouseOver;
  }

  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={classNames(classes.option, classes[`option${_capitalize(size)}`], {
        [classes.selectedOption]: props.isSelected,
      })}
      {...innerProps}
      data-testid={dataTestId ? `${dataTestId}-option` : innerProps.id}
    >
      {props.children}
    </MenuItem>
  );
};

Option.muiName = 'Option';

export default Option;
