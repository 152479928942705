import {createMuiTheme} from '@material-ui/core/styles';

import {beakynPurple} from '../constants';

export default createMuiTheme({
  palette: {
    primary: {main: beakynPurple[500]},
  },
  typography: {
    useNextVariants: true,
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'rgba(0,0,0,0.4)',
        },
      },
    },
  },
});
