import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      minWidth: 8,
      width: 8,
      height: 8,
      backgroundColor: '#B85FFF',
      borderRadius: 8,
      transition: 'all 0.3s ease-in-out',
      margin: '10px 15px 10px 5px',
    },
    dense: {margin: 0},
    clickable: {
      cursor: 'pointer',
    },
  });
