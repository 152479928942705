import React, {RefObject} from 'react';

type Dictionary = {[index: string]: any};
export const initialState = {
  renderSplit: false,
  shouldOpenTipToApply: false,
  order: [] as string[],
  disabled: false,
  initialAppliedFilters: {} as Dictionary,
  appliedFilters: {} as Dictionary,
  shownFilters: {} as Dictionary,
  tempFilters: {} as Dictionary,
  anchorEl: null as HTMLElement,
  addChipRef: null as React.RefObject<HTMLElement>,
  openFilter: '',
};

const actions = {
  handleSetTempValue: (id: string, tempValue: any) => {
    return;
  },
  handlePopperClose: () => {
    return;
  },
  handleRemoveFilter: (id: string) => {
    return;
  },
  handleChangeFilter: (id: string, value: any) => {
    return;
  },
  handleAddFilter: (id: string, initialValue: any) => {
    return;
  },
  handleRemoveAllFilters: () => {
    return;
  },
  handleChipFilterClick: (id: string) => (el: HTMLElement) => {
    return;
  },
  setState: (state: Partial<typeof initialState>) => {
    return;
  },
  tryOpenTipToApply: () => {
    return null;
  },
  addRemoveFilterListener: (callback: (id: string) => void) => {
    return;
  },
  scrollbarsRef: null as RefObject<{}>,
};

export type FilterContextActions = typeof actions;
export type FilterContextState = typeof initialState;

export const FilterContext = React.createContext({
  ...initialState,
  ...actions,
});
