import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 54,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: '#fff',
      borderBottom: '1px solid #E6E6E6',
      display: 'flex',
      zIndex: 20,
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '0 0.2rem',
      [theme.breakpoints.down('xs')]: {
        left: 5,
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
