import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.6)',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
  });

export type Styles = WithStyles<typeof styles>;
