import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) =>
  createStyles({
    noChanges: {
      backgroundColor: 'rgba(130, 183, 97, 0.15)',
      '&:hover': {
        backgroundColor: 'rgba(130, 183, 97, 0.25) !important',
      },
      borderColor: '#82B761',
    },
    hasChanges: {
      backgroundColor: 'rgba(248, 194, 68, 0.15)',
      borderColor: '#F8C244',
      '&:hover': {
        backgroundColor: 'rgba(248, 194, 68, 0.25) !important',
      },
    },
    chip: {},
    dashedChip: {},
    disabledChip: {},
    disabledIcon: {},
    disabledLabel: {},
    root: {},
  });

export type Styles = WithStyles<typeof styles>;
export const decorator = withStyles(styles);
