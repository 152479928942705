import React from 'react';

import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import {SvgIconProps} from '@material-ui/core/SvgIcon';

import {Styles, styles} from './AdornmentIcon.style';

export interface AdornmentIconProps extends Styles {
  onClick?: IconButtonProps['onClick'];
  Icon: React.ComponentType<SvgIconProps>;
}
const AdornmentIcon = (props: AdornmentIconProps) => {
  const {classes, Icon, onClick} = props;
  return (
    <IconButton className={classes.button} onClick={onClick}>
      <Icon className={classes.icon} />
    </IconButton>
  );
};

export default withStyles(styles)(AdornmentIcon);
