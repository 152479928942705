import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import {returnMessageByOs} from '../../../../utils/os';
import {FilterChip} from '../../../FilterChip';
import {FilterContextState} from '../../FilterContext';
import {styles, Styles} from '../FilterSlot.style';

type FilterButtonProps = Styles &
  Pick<FilterContextState, 'shownFilters' | 'appliedFilters' | 'shouldOpenTipToApply'> & {
    toggleTooltip: (open: boolean) => () => void;
    hasScroll: boolean;
    disabled: boolean;
    handleApply: (ev?: React.SyntheticEvent) => void;
    handleCancelQuery: () => void;
    showCancel: boolean;
  };

const FilterSlotButton: FunctionComponent<FilterButtonProps> = (props) => {
  const {handleCancelQuery, toggleTooltip, handleApply} = props;
  const {classes, showCancel, hasScroll, shouldOpenTipToApply, disabled} = props;

  return (
    <div
      className={classNames(classes.applyButtonContainer, {
        [classes.applyButtonContainerWithBorder]: hasScroll,
      })}
    >
      {showCancel ? (
        <FilterChip
          hideClose
          tooltipProps={{
            centerArrow: true,
            title: 'Cancel Query',
            open: shouldOpenTipToApply,
            onOpen: toggleTooltip(true),
            onClose: toggleTooltip(false),
          }}
          handleClick={handleCancelQuery}
          isLoaded={true}
          classes={{chip: classes.cancelButton}}
          label=""
          icon={<CloseIcon fontSize="small" classes={{root: classes.cancelButtonIcon}} />}
        />
      ) : (
        <FilterChip
          hideClose
          tooltipProps={{
            centerArrow: true,
            title: !disabled && `Run Query (${returnMessageByOs})`,
            open: shouldOpenTipToApply && !disabled,
            onOpen: toggleTooltip(true),
            onClose: toggleTooltip(false),
          }}
          handleClick={handleApply}
          isLoaded={!disabled}
          classes={{chip: classes.applyButton, disabledChip: classes.disableApply}}
          label=""
          icon={<AddIcon fontSize="small" classes={{root: classes.applyButtonIcon}} />}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(FilterSlotButton);
