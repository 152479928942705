import React, {FunctionComponent} from 'react';

import ClickAwayListener, {ClickAwayListenerProps} from '@material-ui/core/ClickAwayListener';
import MuiPopper, {PopperProps as MuiPopperProps} from '@material-ui/core/Popper';
import withStyles from '@material-ui/core/styles/withStyles';

import {styles, Styles} from './Popper.style';

export type PopperProps = MuiPopperProps &
  Styles &
  Partial<Pick<ClickAwayListenerProps, 'onClickAway'>> & {
    leftSibling?: React.ReactNode;
    rightSibling?: React.ReactNode;
    awaitClickMouseEvent?: ClickAwayListenerProps['mouseEvent'];
  };

const Popper: FunctionComponent<PopperProps> = (props) => {
  const {
    classes,
    className,
    children,
    leftSibling,
    rightSibling,
    onClickAway,
    awaitClickMouseEvent,
    ...otherProps
  } = props;

  const handleKeyDown = (event) => {
    const key = event.key;

    switch (key) {
      case 'Escape':
        if (onClickAway) {
          event.preventDefault();
          onClickAway(event);
        }
        break;
      default:
        break;
    }
  };

  return (
    <MuiPopper
      tabIndex={0}
      className={`${classes.root} ${className}`}
      onKeyDown={handleKeyDown}
      style={{position: 'fixed'}}
      {...otherProps}
    >
      <ClickAwayListener onClickAway={onClickAway} mouseEvent={awaitClickMouseEvent}>
        <div className={classes.content}>
          {leftSibling}
          {children}
          {rightSibling}
        </div>
      </ClickAwayListener>
    </MuiPopper>
  );
};

Popper.defaultProps = {
  onClickAway: () => null,
  placement: 'bottom-start',
  modifiers: {offset: {offset: '0,10px'}},
  awaitClickMouseEvent: 'onMouseDown',
};

export default withStyles(styles)(Popper);
