import React, {FunctionComponent} from 'react';

import ListItem from '@material-ui/core/ListItem';
import {ListItemProps} from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';

import {styles, Styles} from './ServiceNavigationMenuItem.style';

const Presentational: FunctionComponent<ListItemProps & Styles> = ({
  children,
  classes,
  disabled,
  ...otherProps
}) => (
  <ListItem
    disabled={disabled}
    classes={{
      disabled: classes.disabledItem,
    }}
    {...otherProps}
  >
    <ListItemText
      classes={{
        root: classes.itemTextRoot,
        primary: classes.itemTextPrimary,
        textDense: classes.textDense,
      }}
    >
      {children}
    </ListItemText>
  </ListItem>
);

Presentational.defaultProps = {
  button: true,
  component: 'a',
};

export default withStyles(styles)(Presentational);
