import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    popperContent: {
      marginTop: 0,
      marginBottom: 0,
      padding: 0,
    },
    controlLabelRoot: {
      marginLeft: 0,
      marginBottom: 0,
      '&:last-child': {
        transform: 'translateY(-10px)',
      },
    },
    controlLabel: {
      fontSize: 14,
    },
  });

export type Styles = WithStyles<typeof styles>;
