export const range = (length: number) => Array.apply(null, {length}).map(Number.call, Number);

export const toOption = (op: string | number) =>
  op !== null || op !== undefined ? {value: op, label: op} : '';

export const toOptions = (arr: (string | number)[]) => arr.map(toOption);

export const arrayDifference = <T>(array1: T[], array2: T[]) =>
  array1.filter((a1) => !array2.some((a2) => JSON.stringify(a1) === JSON.stringify(a2)));

export const uniqueArray = <T>(items: T[]) => Array.from(new Set(items));

export const sortBy = (propName: string) => (array: any[]) => {
  return array.sort((a, b) => {
    if (a[propName] > b[propName]) {
      return 1;
    }
    if (a[propName] < b[propName]) {
      return -1;
    }
    return 0;
  });
};
