import React, {FunctionComponent, HTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {SvgIconProps} from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/icons/Menu';
import classNames from 'classnames';

import {
  Drawer,
  DrawerBrandsSection,
  DrawerContent,
  DrawerContentProps,
  DrawerFooter,
  DrawerMadeBySection,
  DrawerProps,
} from '../Drawer';
import {MainNav, MainNavLeft, MainNavLeftProps, MainNavRight, MainNavRightProps} from '../MainNav';
import {LayoutContent} from './Content';
import {Styles, styles} from './Layout.style';

export interface LayoutComponentProps extends Styles, HTMLAttributes<HTMLDivElement> {
  Icon?: React.ComponentType<SvgIconProps>;
  drawerOpen?: boolean;
  drawerMenu?: DrawerContentProps['children'];
  drawerTitle?: DrawerProps['title'];
  mainNavLeftElement?: MainNavLeftProps['children'];
  mainNavRightElement?: MainNavRightProps['children'];
  onDrawerClose?: () => void;
  onDrawerOpen?: () => void;
}

const Layout: FunctionComponent<LayoutComponentProps> = (props) => {
  const {
    classes,
    drawerTitle,
    drawerMenu,
    mainNavLeftElement,
    mainNavRightElement,
    children,
    className,
    drawerOpen,
    onDrawerOpen,
    onDrawerClose,
    Icon,
    ...divProps
  } = props;
  return (
    <div className={classNames(className)} {...divProps}>
      <MainNav Icon={Icon} onClickIconButton={onDrawerOpen}>
        <MainNavLeft>{mainNavLeftElement}</MainNavLeft>
        <MainNavRight>{mainNavRightElement}</MainNavRight>
      </MainNav>

      <Drawer open={drawerOpen} title={drawerTitle} onClose={onDrawerClose}>
        <DrawerContent>
          {drawerMenu}
          <DrawerBrandsSection />
          <DrawerMadeBySection />
          <DrawerFooter />
        </DrawerContent>
      </Drawer>

      <LayoutContent>{children}</LayoutContent>
    </div>
  );
};

Layout.defaultProps = {
  Icon: Menu,
};

export default withStyles(styles)(Layout);
