import React from 'react';

import classNames from 'classnames';

const ValueContainer = (props) => {
  const {selectProps} = props;
  const {'data-testid': dataTestId} = selectProps;

  return (
    <div
      className={classNames(props.selectProps.classes.valueContainer, {
        [props.selectProps.classes.disabled]: props.selectProps.isDisabled,
      })}
      data-testid={dataTestId ? `${dataTestId}-valueContainer` : dataTestId}
    >
      {props.children}
    </div>
  );
};

ValueContainer.muiName = 'ValueContainer';

export default ValueContainer;
