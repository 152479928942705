import React from 'react';

import {ButtonBaseActions} from '@material-ui/core/ButtonBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles from '@material-ui/core/styles/withStyles';
import _equal from 'fast-deep-equal';
import _capitalize from 'lodash/capitalize';

import {pascalCase} from '../../../../utils/string';
import {onCloseType} from '../../../ActionPopper';
import {FilterActionPopper} from '../../../FilterActionPopper';
import {PopperContentSection} from '../../../Popper/Content';
import {Styles, styles} from './Popper.style';

interface Props extends Styles {
  headerTitle: string;
  options: string[];
  value: string;
  tempValue?: string;
  hasChanges: boolean;
  onChange: (value: string, ev: React.SyntheticEvent) => void;
  onPopperClose: (temp?: string) => void;
  resetTempValue: () => void;
  open: boolean;
  anchorEl?: HTMLElement;
  templateLabel?: (item: string) => string;
}

interface State {
  selected: string;
}

class Popper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: props.tempValue || props.value,
    };
  }

  static defaultProps: Partial<Props> = {
    templateLabel: (item) => pascalCase(item.replace(/-/g, ' ')),
  };

  handleSubmit = (ev: any) => {
    const {onChange} = this.props;
    const {selected} = this.state;
    onChange(selected, ev);
  };

  getOptions = () => {
    const statusList = (this.props.options || []).map((k) => ({
      text: this.props.templateLabel(k),
      value: k,
    }));

    return statusList;
  };

  checkboxAction = (selected: boolean) => (action: ButtonBaseActions) => {
    if (selected) {
      action.focusVisible();
    }
  };

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>,
    type?: onCloseType,
  ) => {
    const {selected} = this.state;
    const {value} = this.props;
    const temp = type === 'away' && !_equal(value, selected) ? selected : undefined;
    this.props.onPopperClose(temp);
  };

  radioAction = (selected: boolean) => (action: ButtonBaseActions) => {
    if (selected) {
      action.focusVisible();
    }
  };

  handleSelectedValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({selected: event.target.value});
  };

  onReset = () => {
    const {value, resetTempValue} = this.props;
    resetTempValue();
    this.setState({selected: value});
  };

  render() {
    const {classes, anchorEl, open, hasChanges, headerTitle} = this.props;
    const {selected} = this.state;

    const options = this.getOptions();

    return (
      <FilterActionPopper
        anchorEl={anchorEl}
        open={open}
        onClose={this.handleClose}
        headerTitle={headerTitle}
        disabled={!selected.length}
        onSubmit={this.handleSubmit}
        hasChanges={hasChanges}
        onCancel={hasChanges && this.onReset}
        onCancelLabel="Reset"
      >
        <PopperContentSection className={classes.popperContent}>
          <RadioGroup value={this.state.selected} onChange={this.handleSelectedValueChange}>
            {options.map((option, index) => {
              const checked = option.value === selected;
              const autoFocus = selected ? checked : index === 0;
              return (
                <FormControlLabel
                  key={option.value}
                  classes={{root: classes.controlLabelRoot, label: classes.controlLabel}}
                  checked={checked}
                  value={option.value}
                  control={
                    <Radio
                      action={this.radioAction(autoFocus)}
                      autoFocus={autoFocus}
                      color="primary"
                    />
                  }
                  label={option.text}
                />
              );
            })}
          </RadioGroup>
        </PopperContentSection>
      </FilterActionPopper>
    );
  }
}

export default withStyles(styles)(Popper);
