import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      position: 'relative',
    },
    textarea: {
      width: '100%',
      overflow: 'hidden',
      resize: 'none',
      fontFamily: 'monospace, monospace',
      '&:focus': {
        outline: 'none',
      },
      // other
      padding: '8px',
      margin: '0 0 15px',
      fontSize: '13px',
      lineHeight: '1.72222',
      color: 'inherit',
      whiteSpace: 'pre',
      backgroundColor: '#fff',
      border: '2px solid #e7e9ec',
      borderRadius: '6px',
    },
    textareaActive: {
      borderColor: '#c784ff',
    },
    textareaError: {
      borderColor: '#f39898',
    },
    errorContainer: {
      position: 'absolute',
      fontSize: '11px',
      backgroundColor: '#f39898',
      right: '4px',
      top: '4px',
      borderRadius: '3px',
      padding: '5px 10px',
      color: '#fff',
    },
    errorMessage: {
      color: '#fff',
    },
  });

export type Styles = WithStyles<typeof styles>;
