import React, {ChangeEvent, Ref, RefObject, SFC, useRef} from 'react';

import {Omit} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTextField, {OutlinedTextFieldProps} from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import _capitalize from 'lodash/capitalize';
import _uniqueId from 'lodash/uniqueId';

import {EndAdornment} from './EndAdornment';
import {Styles, styles} from './TextField.style';

export enum TextFieldSize {
  small = 'small',
  large = 'large',
}

export type TextFieldProps = Styles &
  Omit<OutlinedTextFieldProps, 'onChange' | 'variant'> & {
    size?: TextFieldSize;
    isClearable?: boolean;
    hideStepper?: boolean;
    onChange?: (value: string) => void;
  };

const handleClear = (callback: TextFieldProps['onChange'], inputRef: Ref<any>) => () => {
  !!callback && callback('');
  (inputRef as RefObject<HTMLInputElement>).current.focus();
};

const handleChange = (callback?: TextFieldProps['onChange']) => (
  evt: ChangeEvent<HTMLInputElement>,
) => {
  if (callback) {
    callback(evt.target.value);
  }
};

const getLabelWith = (size: TextFieldSize) => (size === TextFieldSize.small ? {labelWidth: 0} : {});
const getLabelTransform = (size: TextFieldSize) =>
  size === TextFieldSize.small ? {transform: 'none'} : {};

const TextField: SFC<TextFieldProps> = ({
  classes,
  id,
  size,
  isClearable,
  hideStepper,
  onChange,
  inputProps,
  InputProps,
  InputLabelProps,
  value,
  inputRef,
  ...otherProps
}) => {
  const [autoId] = React.useState(_uniqueId('textfield-'));

  const customInputRef = useRef<HTMLInputElement>(null);
  const {className, ...inputOtherProps} = inputProps || ({} as TextFieldProps['inputProps']);
  const {classes: InputPropsClasses, ...InputOtherProps} =
    InputProps || ({} as TextFieldProps['InputProps']);
  const {classes: InputLabelPropsClasses, ...InputLabelOtherProps} =
    InputLabelProps || ({} as TextFieldProps['InputLabelProps']);

  const {root: inputLabelRoot, formControl: inputLabelFormControl, ...otherInputLabelPropsClasses} =
    InputLabelPropsClasses || ({} as TextFieldProps['InputLabelProps']['classes']);
  const {root: inputRoot, adornedEnd, notchedOutline, ...otherInputPropsClasses} =
    InputPropsClasses || ({} as TextFieldProps['InputProps']['classes']);

  return (
    <MuiTextField
      id={id || autoId}
      variant="outlined"
      onChange={handleChange(onChange)}
      value={value}
      inputRef={inputRef || customInputRef}
      InputProps={{
        endAdornment: isClearable && value !== null && value !== undefined && value !== '' && (
          <EndAdornment
            Icon={ClearIcon}
            onClick={handleClear(onChange, inputRef || customInputRef)}
          />
        ),
        classes: {
          root: classNames({[classes.stepper]: !hideStepper}, inputRoot),
          notchedOutline: classNames(classes[`size${_capitalize(size)}Border`], notchedOutline),
          adornedEnd: classNames(classes.adornedEnd, adornedEnd),
          ...otherInputPropsClasses,
        },
        ...getLabelWith(size),
        ...InputOtherProps,
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          ...getLabelTransform(size),
        },
        classes: {
          root: classNames(classes[`size${_capitalize(size)}InputLabelPropsRoot`], inputLabelRoot),
          formControl: classNames(
            classes[`size${_capitalize(size)}InputLabelPropsFormControl`],
            inputLabelFormControl,
          ),
          ...otherInputLabelPropsClasses,
        },
        ...InputLabelOtherProps,
      }}
      inputProps={{
        className: classNames(classes.input, classes[`size${_capitalize(size)}`], className),
        ...inputOtherProps,
      }}
      {...otherProps}
    />
  );
};

TextField.defaultProps = {
  size: TextFieldSize.large,
  autoComplete: 'off',
};

export default withStyles(styles)(TextField);
