import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {Styles, styles} from './MaintenancePage.style';

export type MaintenancePageProps = Styles & {
  imageUrl?: string;
  imageWidth?: number;
  title: string;
  description: string;
};

const MaintenancePage: FunctionComponent<MaintenancePageProps> = (props) => {
  const {classes, imageUrl, imageWidth, title, description} = props;
  return (
    <div className={classes.root}>
      <img className={classes.image} src={imageUrl} width={imageWidth} />
      <Typography
        variant="h6"
        className={`${classes.titleBorder} ${classes.text}`}
        inline
        children={title}
      />
      <Typography className={classes.text} inline children={description} />
    </div>
  );
};

MaintenancePage.defaultProps = {
  imageUrl: 'https://onsmart-uploads-prod.s3.amazonaws.com/misc/OUT_PRIMARY_LOGO_POS_RGB_BLACK.svg',
  imageWidth: 185,
};

export default withStyles(styles)(MaintenancePage);
