import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: '0rem 1rem 2rem',
      fontSize: '0.8rem',
      color: 'rgba(255,255,255,0.8)',
      lineHeight: 2,
    },
    contact: {
      color: 'rgba(255,255,255,0.8)',
    },
  });

export type Styles = WithStyles<typeof styles>;
