import React, {Component, MouseEvent} from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './PageSizeControl.style';

export type PageSizeControlProps = Styles & {
  sizeOptions: number[];
  size: number;
  onChange: (size: number) => void;
};

const initialState = {
  anchorEl: null as HTMLElement | null,
};

type State = typeof initialState;

class PageSizeControl extends Component<PageSizeControlProps, State> {
  constructor(props: PageSizeControlProps) {
    super(props);
    this.state = initialState;
  }

  handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handleOnChange = () => (e: MouseEvent<HTMLElement>) => {
    this.props.onChange(parseInt(e.currentTarget.innerText, 10));
    this.handleClose();
  };

  render() {
    const {sizeOptions, size, classes} = this.props;
    const {anchorEl} = this.state;
    return (
      <>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          variant="text"
          classes={{root: classes.button, disabled: classes.disabledButton}}
          onClick={this.handleClick}
        >
          {size}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: 80,
            },
          }}
        >
          {sizeOptions.map((o) => (
            <MenuItem key={o} selected={o === size} onClick={this.handleOnChange()}>
              {o}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}
export default withStyles(styles)(PageSizeControl);
