import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    itemTextRoot: {},
    itemTextPrimary: {
      fontWeight: 500,
      color: '#000',
    },
    textDense: {},
    disabledItem: {},
    disabledText: {},
  });

export type Styles = WithStyles<typeof styles>;
