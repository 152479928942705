import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (_theme: Theme) =>
  createStyles({
    popperContent: {
      width: 220,
    },
    inputBackground: {
      backgroundColor: '#f7f7f7',
      color: '#636363',
    },
    button: {
      margin: 0,
      minWidth: 'initial',
      fontSize: 11,
      marginLeft: '-6px',
    },
    textAreaFont: {
      fontFamily: 'monospace',
      fontSize: 12,
    },
    textAreaPadding: {
      padding: 10,
    },
  });

export type Styles = WithStyles<typeof styles>;
