import {Theme} from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import {CSSProperties, WithStyles} from '@material-ui/core/styles/withStyles';

const smallFont = '14px';
const largeFont = '16px';

const input = (theme: Theme) => {
  return {
    color: theme.palette.text.primary,
    '& input': {
      font: 'inherit',
    },
  };
};
const groupRoot = (theme: Theme) =>
  ({
    marginTop: `${theme.spacing.unit * 1.5}px`,
    '&:first-child': {
      marginTop: 0,
    },
  } as CSSProperties);
const groupHeader = {
  textTransform: 'uppercase',
  color: '#888888',
  lineHeight: '36px',
} as CSSProperties;
const control = {
  display: 'flex',
};
const noOptionsMessageSmall = {
  textAlign: 'center',
  color: '#999999',
} as CSSProperties;

const valueContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  alignItems: 'center',
  overflow: 'hidden',
} as CSSProperties;

const singleValue = {
  maxWidth: 'calc(100% - 6px)',
  letterSpacing: 'initial',
};

export const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    inputLarge: {
      ...input(theme),
      fontSize: largeFont,
      paddingBottom: 1,
    },
    inputSmall: {
      ...input(theme),
      fontSize: smallFont,
    },
    inputFilled: {marginLeft: -2},
    controlLarge: {
      ...control,
      padding: '10px 6px 10px 14px',
    },
    controlSmall: {
      ...control,
      padding: '2px 0px 2px 14px',
      minHeight: 36,
    },
    controlIsMulti: {
      paddingLeft: '8px',
    },
    valueContainer: valueContainer,
    option: {
      color: '#4A4A4A',
      height: 'auto',
      minHeight: 24,
      whiteSpace: 'unset',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.14)',
      },
    },
    optionSmall: {
      fontSize: smallFont,
      padding: '8px 12px',
    },
    optionLarge: {},
    selectedOption: {
      backgroundColor: '#f8effe',
      '&:hover': {
        backgroundColor: '#edeaff !important',
      },
    },
    noOptionsMessageSmall: {
      ...noOptionsMessageSmall,
      fontSize: smallFont,
      padding: '8px 12px',
    },
    noOptionsMessageLarge: {
      ...noOptionsMessageSmall,
      fontSize: largeFont,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    menuList: {
      marginTop: '4px !important',
      marginBottom: '4px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
    placeholder: {
      position: 'absolute',
      fontSize: smallFont,
      color: '#A9A9A9',
      marginLeft: 2,
    },
    disabled: {
      opacity: 0.5,
    },
    multiValueChipSmall: {
      fontSize: 12,
    },
    multiValueChipLarge: {
      fontSize: 14,
    },
    singleValueSmall: {
      ...singleValue,
      fontSize: smallFont,
    },
    singleValueLarge: {
      ...singleValue,
      fontSize: largeFont,
    },
    paper: {
      position: 'absolute',
      zIndex: 2,
      marginTop: `${theme.spacing.unit}px`,
      marginBottom: `${theme.spacing.unit}px`,
      left: 0,
      right: 0,
      borderRadius: 4,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px',
      minWidth: 'fit-content',
    },
    paperMarginNormal: {
      marginTop: '0px',
      marginBottom: '0px',
    },
    paperMarginDense: {
      marginTop: `${theme.spacing.unit / 2}px`,
      marginBottom: `${theme.spacing.unit / 2}px`,
    },
    paperMarginNone: {},
    groupRoot: groupRoot(theme),
    groupHeaderSmall: {
      ...groupHeader,
      paddingLeft: 12,
      paddingRight: 12,
      fontSize: 11,
    },
    groupHeaderLarge: {
      ...groupHeader,
      paddingLeft: 16,
      paddingRight: 16,
      fontSize: 14,
    },
    primaryOptionTextLarge: {},
    secondaryOptionTextLarge: {},
    primaryOptionTextSmall: {
      fontSize: 14,
    },
    secondaryOptionTextSmall: {
      fontSize: 14,
    },
  });
};

export type Styles = Partial<WithStyles<typeof styles>>;
