import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '54px',
      borderBottom: '1px solid #E6E6E6',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '0 0.2rem',
    },
    leftContent: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: '1rem',
      marginLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.9rem',
      },
    },
    subtitle: {
      fontWeight: 400,
      '&:before': {
        padding: 5,
        content: '"/"',
      },
    },
    loading: {
      backgroundColor: '#F6F6F6',
      width: '30%',
    },
    rightContent: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  });

export type Styles = WithStyles<typeof styles>;
