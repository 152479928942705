import {FilterColorChip, FilterColorChipProps, filterColorChipStylesDecorator} from '../FilterChip';

interface Props extends FilterColorChipProps<boolean> {}

class Chip extends FilterColorChip<Props, boolean> {
  getLabel(value: boolean) {
    return `Prime Location`;
  }
}

export default filterColorChipStylesDecorator(Chip);
