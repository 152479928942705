import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {PopperContent, PopperContentSection} from '../../Content';
import {PopperHeader, PopperHeaderText, PopperHeaderTextProps} from '../../Header';
import {PopperPaper, PopperPaperProps} from '../../Paper';
import {styles, Styles} from './Paper.style';

export type DisclaimerPaperProps = PopperPaperProps &
  Styles & {
    headerTitle?: PopperHeaderTextProps['children'];
  };

const Paper: FunctionComponent<DisclaimerPaperProps> = (props) => {
  const {classes, headerTitle, children, className, ...otherProps} = props;

  return (
    <PopperPaper className={`${className} ${classes.root}`} {...otherProps}>
      <PopperHeader>
        <PopperHeaderText>{headerTitle}</PopperHeaderText>
      </PopperHeader>
      <PopperContentSection>
        <PopperContent>{children}</PopperContent>
      </PopperContentSection>
    </PopperPaper>
  );
};

Paper.defaultProps = {
  headerTitle: 'Disclaimer',
};

export default withStyles(styles)(Paper);
