import React from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import RootRef from '@material-ui/core/RootRef';
import withStyles from '@material-ui/core/styles/withStyles';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';

import {conditionalCall, conditionalReturn, safeCall} from '../../utils/function';
import {Portal} from '../Portal';
import {styles, Styles} from './CssTooltip.style';

const GhostElement = (
  props: {targetRef: HTMLElement} & Pick<
    CssTooltipProps,
    'classes' | 'title' | 'placement' | 'centerArrow'
  >,
) => {
  const {top, left, width, height} = props.targetRef.getBoundingClientRect();
  const style = {
    pointerEvents: 'none',
    position: 'fixed',
    top,
    left,
    width,
    height,
  } as React.CSSProperties;

  return (
    <CssTooltip
      open
      portalTarget={null}
      classes={props.classes}
      title={props.title}
      placement={props.placement}
      centerArrow={props.centerArrow}
    >
      <span style={style} tabIndex={0} />
    </CssTooltip>
  );
};

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface CssTooltipProps extends Styles {
  title: string;
  open?: boolean;
  centerArrow?: boolean;
  placement?: Placement;
  enterDelay?: number;
  enterTouchDelay?: number;
  children: React.ReactElement<React.HTMLAttributes<HTMLElement>>;
  portalTarget?: HTMLElement;
  disabledPositionListener?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const usePositionListener = (ref: HTMLElement, enabled: boolean, onPositionChange: () => void) => {
  const handleTopPosition = (initialTop: number) => {
    if (ref) {
      const {top} = ref.getBoundingClientRect();
      const precision = 5;
      const precisionExceeded = Math.abs(top - initialTop) > precision;
      conditionalCall(precisionExceeded, onPositionChange);
    }
  };

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!!ref && enabled) {
      const {top} = ref.getBoundingClientRect();
      handleTopPosition(top);
      interval = setInterval(() => handleTopPosition(top), 300);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [enabled, handleTopPosition]);
};

const useTooltipEvents = (props: CssTooltipProps, ref: HTMLElement) => {
  const {
    open: propOpen,
    children,
    enterDelay,
    enterTouchDelay,
    disabledPositionListener,
    onOpen,
    onClose,
  } = props;
  const {onMouseEnter, onMouseLeave, onTouchStart, onTouchEnd} = children.props;

  const [open, setOpen] = React.useState(false);
  const [touched, setTouched] = React.useState(false);

  const isOpen = React.useMemo(() => (!isNil(propOpen) ? propOpen : open), [open, propOpen]);

  const handleOpen = React.useCallback(
    (_open: boolean) => {
      setOpen(_open);
      safeCall(_open ? onOpen : onClose);
    },
    [safeCall, onOpen, onClose],
  );

  usePositionListener(ref, !disabledPositionListener && isOpen, () => handleOpen(false));

  const setOpenByHoverDebounced = React.useMemo(() => debounce(handleOpen, enterDelay), [
    enterDelay,
  ]);
  const setOpenByTouchDebounced = React.useMemo(() => debounce(handleOpen, enterTouchDelay), [
    enterTouchDelay,
  ]);

  const handleMouseEnter = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      safeCall(onMouseEnter, ev);
      conditionalCall(!isOpen && !touched, setOpenByHoverDebounced, true);
    },
    [isOpen, touched, setOpenByHoverDebounced, onMouseEnter],
  );

  const handleMouseLeave = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setOpenByHoverDebounced.cancel();
      safeCall(onMouseLeave, ev);
      conditionalCall(!touched, handleOpen, false);
    },
    [touched, setOpenByHoverDebounced, onMouseLeave, handleOpen],
  );

  const handleTouchStart = React.useCallback(
    (ev: React.TouchEvent<HTMLDivElement>) => {
      setOpenByHoverDebounced.cancel();
      safeCall(onTouchStart, ev);
      conditionalCall(!touched, setTouched, true);
      conditionalCall(!isOpen, setOpenByTouchDebounced, true);
    },
    [isOpen, touched, setOpenByTouchDebounced, setOpenByHoverDebounced, onTouchStart],
  );

  const handleTouchEnd = React.useCallback(
    (ev: React.TouchEvent<HTMLDivElement>) => {
      setOpenByTouchDebounced.cancel();
      setOpenByHoverDebounced.cancel();
      safeCall(onTouchEnd, ev);
    },
    [setOpenByTouchDebounced, setOpenByHoverDebounced, onTouchEnd],
  );

  const handleClickAway = React.useCallback(() => {
    setOpenByTouchDebounced.cancel();
    setOpenByHoverDebounced.cancel();
    handleOpen(false);
  }, [handleOpen, setOpenByTouchDebounced, setOpenByHoverDebounced]);

  return {
    isOpen,
    handleClickAway,
    handleTouchEnd,
    handleTouchStart,
    handleMouseLeave,
    handleMouseEnter,
  };
};

const useTooltip = (props: CssTooltipProps, ref: HTMLElement) => {
  const {classes, children, title, placement, centerArrow, portalTarget} = props;

  const {
    isOpen,
    handleMouseEnter,
    handleMouseLeave,
    handleTouchStart,
    handleTouchEnd,
    handleClickAway,
  } = useTooltipEvents(props, ref);

  const childrenProps = React.useMemo(
    () => ({
      ...children.props,
      ...conditionalReturn(
        !portalTarget && isOpen && !!title,
        {
          'data-tooltip': title,
          'data-flow': placement || 'bottom-start',
          ...conditionalReturn(centerArrow, {'data-center-arrow': true}, {}),
          className: `${classes.root} ${children.props.className}`,
        },
        {},
      ),
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onTouchStart: handleTouchStart,
      onTouchEnd: handleTouchEnd,
    }),
    [classes, isOpen, title, placement, centerArrow, children.props],
  );

  return {childrenProps, isOpen, handleClickAway};
};

const CssTooltip: React.FC<CssTooltipProps> = (props) => {
  const {children, portalTarget} = props;

  const [ref, setRef] = React.useState<HTMLElement>(null);
  const {childrenProps, isOpen, handleClickAway} = useTooltip(props, ref);

  const clone = React.useMemo(() => {
    return React.cloneElement(children, childrenProps);
  }, [children, childrenProps]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <RootRef rootRef={setRef}>{clone}</RootRef>
      </ClickAwayListener>
      <Portal open={isOpen && !!portalTarget && !!ref} portalTarget={portalTarget}>
        <GhostElement
          classes={props.classes}
          title={props.title}
          placement={props.placement}
          centerArrow={props.centerArrow}
          targetRef={ref}
        />
      </Portal>
    </>
  );
};

CssTooltip.defaultProps = {
  placement: 'bottom-start' as Placement,
  enterDelay: 0,
  enterTouchDelay: 200,
  portalTarget: document.body,
};

export default withStyles(styles)(CssTooltip);
