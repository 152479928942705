import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {ContextMenuItem, ContextMenuItemProps} from '../MenuItem';
import {Styles, styles} from './HeaderMenuItem.style';

export interface ContextHeaderMenuItemProps
  extends Styles,
    Omit<ContextMenuItemProps, 'button' | 'onClick'> {}

const Presentational: FC<ContextHeaderMenuItemProps> = (props) => {
  return <ContextMenuItem button={false} {...props} />;
};

Presentational.defaultProps = {};

export default withStyles(styles)(Presentational);
