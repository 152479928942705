import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      fontSize: 16,
      margin: 5,
      transition: 'all 0.2s',
    },
    rotate: {
      transform: 'rotate(180deg)',
    },
  });

export type Styles = WithStyles<typeof styles>;
