import React, {FunctionComponent, RefObject} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {DatagridContext} from '../../../Datagrid/Datagrid.context';
import {DatagridSorting} from '../../../Datagrid/Datagrid.model';
import {Scrollbars, ScrollbarsType} from '../../../Scrollbars';

import {DatagridCardViewBody} from './Body';
import {Styles, styles} from './CardView.style';

interface Props extends Styles {
  sorting: DatagridSorting;
  onSortingChange: (sorting: DatagridSorting) => void;
  horizontalScrollbarsRef?: RefObject<ScrollbarsType>;
  verticalScrollbarsRef?: RefObject<ScrollbarsType>;
}

export type DatagridCardViewScroll = ScrollbarsType;

const DatagridCardsView: FunctionComponent<Props> = (props) => {
  const {classes, children, horizontalScrollbarsRef, verticalScrollbarsRef} = props;

  return (
    <DatagridContext.Consumer>
      {({printable}) => {
        return (
          <div className={classes.root}>
            <Scrollbars ref={horizontalScrollbarsRef as any}>
              <div className={classes.grid}>
                <DatagridCardViewBody
                  classes={{root: classes.body}}
                  scrollbarsRef={verticalScrollbarsRef}
                >
                  {children}
                </DatagridCardViewBody>
              </div>
            </Scrollbars>
          </div>
        );
      }}
    </DatagridContext.Consumer>
  );
};

export default withStyles(styles)(DatagridCardsView);
