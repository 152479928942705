import Sniffr from 'sniffr';

export const isUserOnMac = () => {
  if (process.browser) {
    const ua = navigator.userAgent;
    const s = new Sniffr();
    s.sniff(ua);
    return s.os.name === 'macos';
  } else {
    return false;
  }
};

export const returnMessageByOs = `${isUserOnMac() ? '⌘+Return' : 'CTRL+Enter'}`;
