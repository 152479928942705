import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {},
    contentSection: {},
    contentSectionTitle: {
      padding: '0 15px 0',
      fontSize: 10,
      color: 'rgba(0,0,0,0.54)',
      letterSpacing: -0.05,
    },
    divider: {
      marginTop: 5,
      marginBottom: 5,
    },
    extraOptionsTitle: {
      marginTop: 6,
    },
  });

export type Styles = WithStyles<typeof styles>;
