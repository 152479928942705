import React, {Component, createRef, KeyboardEvent, MouseEvent, RefObject} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';

import {Popper, PopperHeader, PopperPaper, PopperProps} from '../Popper';
import {Scrollbars, ScrollbarsProps} from '../Scrollbars';
import {SearchField, SearchFieldProps} from '../SearchField';
import {SearchPopperList, SearchPopperListOption, SearchPopperListProps} from './List';
import {Styles, styles} from './SearchPopper.style';

export type SearchPopperOption = SearchPopperListOption;

export type SearchPopperProps = Styles &
  Pick<PopperProps, 'open' | 'anchorEl' | 'onClickAway' | 'placement' | 'modifiers'> &
  Pick<
    SearchPopperListProps,
    | 'extraOptions'
    | 'mainOptions'
    | 'options'
    | 'mainOptionsHeader'
    | 'clearOption'
    | 'onClearClick'
  > & {
    noOptionsMessage?: string;
    searchPlaceholder?: SearchFieldProps['placeholder'];
    searchTerm?: SearchFieldProps['value'];
    onSearch?: SearchFieldProps['onChange'];
    heightMin?: ScrollbarsProps['autoHeightMin'];
    heightMax?: ScrollbarsProps['autoHeightMax'];
    defaultOption?: SearchPopperListProps['options'][number];
    onClickItem?: (
      option: SearchPopperListOption,
      isDefault: boolean,
      evt: MouseEvent | KeyboardEvent,
    ) => void;
  };

const initialState = {
  selectedIndex: 0,
};

type State = typeof initialState;

class SearchPopper extends Component<SearchPopperProps, State> {
  static defaultProps: Partial<SearchPopperProps> = {
    mainOptionsHeader: 'Frequently Used',
    noOptionsMessage: 'Option not found.',
    heightMax: 420,
    onClearClick: () => null,
  };

  parentListRef: RefObject<HTMLDivElement>;

  constructor(props: SearchPopperProps) {
    super(props);
    this.state = initialState;
    this.parentListRef = createRef<HTMLDivElement>();
  }

  componentWillReceiveProps(nextProps: SearchPopperProps) {
    if (!this.props.open && nextProps.open) {
      this.resetIndex();
    }
  }

  resetIndex = () => {
    const newIndex = this.calcNextIndex(-1);
    if (!isNaN(newIndex)) {
      this.setState({selectedIndex: newIndex});
    }
  };

  calcNextIndex = (currentIndex: number) => {
    const allOptions = this.getAllOptions();
    if (allOptions.some((o) => !o.disabled)) {
      const candidate = currentIndex === allOptions.length - 1 ? 0 : currentIndex + 1;
      const candidateOptions = allOptions[candidate];
      return candidateOptions && candidateOptions.disabled
        ? this.calcNextIndex(candidate)
        : candidate;
    }
  };

  calcPrevIndex = (currentIndex: number) => {
    const allOptions = this.getAllOptions();
    if (allOptions.some((o) => !o.disabled)) {
      const candidate = currentIndex === 0 ? allOptions.length - 1 : currentIndex - 1;
      const candidateOptions = allOptions[candidate];
      return candidateOptions && candidateOptions.disabled
        ? this.calcPrevIndex(candidate)
        : candidate;
    }
  };

  handleSearchArrowDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const {selectedIndex} = this.state;
    const newIndex = this.calcNextIndex(selectedIndex);

    if (!isNaN(newIndex)) {
      this.setState({selectedIndex: newIndex});
    }
  };

  handleSearchArrowUp = (event: KeyboardEvent<HTMLDivElement>) => {
    const {selectedIndex} = this.state;
    const newIndex = this.calcPrevIndex(selectedIndex);

    if (!isNaN(newIndex)) {
      this.setState({selectedIndex: newIndex});
    }
  };

  handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const key = event.key;

    switch (key) {
      case 'Tab':
        event.preventDefault();
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.handleSearchArrowUp(event);
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.handleSearchArrowDown(event);
        break;
      case 'Enter':
        event.preventDefault();
        this.handleSearchEnter(event);
        break;
      case 'Tab':
        event.preventDefault();
        break;
      case 'Escape':
        const {onClickAway} = this.props;
        if (onClickAway) {
          event.preventDefault();
          onClickAway(event);
        }
        break;
      default:
        break;
    }
  };

  handleSearchEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    const {onClickItem, onClearClick, clearOption} = this.props;
    const selectedItem = this.getSelectedItem();
    if (selectedItem) {
      const isDefault = this.showDefaultOption();
      if (clearOption && selectedItem.id === clearOption.id) {
        onClearClick();
      } else {
        onClickItem(selectedItem, isDefault, event);
      }
    }
  };

  handleSearchChange = (searchTerm: string) => {
    this.props.onSearch(searchTerm);
    this.resetIndex();
  };

  handleMouseEnterItem = (option: SearchPopperListOption, evt: MouseEvent<HTMLElement>) => {
    const allOptions = this.getAllOptions();
    const selectedIndex = allOptions.findIndex(
      (o) => o.id === option.id && o.title === option.title,
    );

    this.setState({selectedIndex});
  };

  getAllOptions = () => {
    const {extraOptions, mainOptions, options, clearOption} = this.props;

    const extra = extraOptions
      ? extraOptions.map((e) => e.options).reduce((acc, val) => acc.concat(val), [])
      : ([] as SearchPopperListOption[]);

    if (this.showDefaultOption()) {
      return [...this.getDefaultOption(), ...extra];
    } else {
      return [
        ...(mainOptions || ([] as SearchPopperListOption[])),
        ...(options || ([] as SearchPopperListOption[])),
        ...(clearOption ? [clearOption] : ([] as SearchPopperListOption[])),
        ...extra,
      ];
    }
  };

  getSelectedItem = () => {
    const {selectedIndex} = this.state;
    const allOptions = this.getAllOptions();
    return allOptions.some((o) => !o.disabled) && allOptions.length + 1 >= selectedIndex
      ? allOptions[selectedIndex]
      : null;
  };

  handleClickItem = (option: SearchPopperListOption, evt: MouseEvent | KeyboardEvent) => {
    const isDefault = this.showDefaultOption();
    this.props.onClickItem(option, isDefault, evt);
  };

  handleRef = (ref?: HTMLElement) => {
    if (ref && this.parentListRef.current) {
      if (
        ref.getBoundingClientRect().top < this.parentListRef.current.getBoundingClientRect().top
      ) {
        ref.scrollIntoView(true);
      } else if (
        ref.getBoundingClientRect().bottom >
        this.parentListRef.current.getBoundingClientRect().bottom
      ) {
        ref.scrollIntoView(false);
      }
    }
  };

  getDefaultOption = () => {
    const {defaultOption, searchTerm} = this.props;
    return defaultOption
      ? [{...defaultOption, title: `${defaultOption.title}: ${searchTerm}`}]
      : [];
  };

  hasOptions = () => {
    const {mainOptions, options, clearOption} = this.props;

    return !isEmpty(mainOptions) || !isEmpty(options) || !isEmpty(clearOption);
  };

  showDefaultOption = () => {
    const {defaultOption} = this.props;

    const noOptions = !this.hasOptions();
    return noOptions && !!defaultOption;
  };

  renderHeader = () => {
    const {classes, searchTerm, searchPlaceholder} = this.props;
    return (
      <PopperHeader className={classes.header}>
        <SearchField
          placeholder={searchPlaceholder}
          value={searchTerm}
          onChange={this.handleSearchChange}
        />
      </PopperHeader>
    );
  };

  renderList = () => {
    const {
      extraOptions,
      mainOptions,
      options,
      mainOptionsHeader,
      clearOption,
      onClearClick,
    } = this.props;

    const showDefaultOption = this.showDefaultOption();
    return (
      <SearchPopperList
        extraOptions={extraOptions}
        mainOptions={mainOptions}
        options={showDefaultOption ? this.getDefaultOption() : options}
        onClickItem={this.handleClickItem}
        onMouseEnterItem={this.handleMouseEnterItem}
        mainOptionsHeader={mainOptionsHeader}
        selectedOptionRef={this.handleRef}
        selectedOption={this.getSelectedItem()}
        clearOption={!showDefaultOption && clearOption}
        onClearClick={onClearClick}
      />
    );
  };

  render() {
    const {
      classes,
      anchorEl,
      open,
      placement,
      modifiers,
      noOptionsMessage,
      heightMin,
      heightMax,
      onClickAway,
    } = this.props;

    const noOptions = !this.hasOptions();

    return (
      <Popper
        anchorEl={anchorEl}
        open={open}
        onClickAway={onClickAway}
        onKeyDown={this.handleKeyDown}
        placement={placement}
        modifiers={modifiers}
      >
        <PopperPaper classes={{root: classes.paper}}>
          {this.renderHeader()}
          <div ref={this.parentListRef}>
            <Scrollbars autoHeight autoHeightMin={heightMin} autoHeightMax={heightMax}>
              {noOptions && (
                <Typography variant="body2" className={classes.filterListMessage}>
                  {noOptionsMessage}
                </Typography>
              )}
              {this.renderList()}
            </Scrollbars>
          </div>
        </PopperPaper>
      </Popper>
    );
  }
}

export default withStyles(styles)(SearchPopper);
