import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: '#9f25b3',
      backgroundImage: 'linear-gradient(to top, #4023ae 0%, #9f25b3 100%)',
      minWidth: '320px',
    },
  });

export type Styles = WithStyles<typeof styles>;
