import {pascalCase} from '../../../../utils/string';
import {FilterColorChip, FilterColorChipProps, filterColorChipStylesDecorator} from '../FilterChip';

export interface RadioFilterChipProps extends FilterColorChipProps<string> {
  chipPrefix?: string;
  templateLabel?: (item: string) => string;
}

class Chip extends FilterColorChip<RadioFilterChipProps, string> {
  static defaultProps: Partial<RadioFilterChipProps> = {
    templateLabel: (item) => pascalCase(item.replace(/-/g, ' ')),
  };

  getLabel(value: string = '') {
    const {chipPrefix, templateLabel} = this.props;
    const result = templateLabel(value);

    return chipPrefix ? `${chipPrefix} ${result}` : result;
  }
}

export default filterColorChipStylesDecorator(Chip);
