import React, {FunctionComponent, HTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {styles, Styles} from './Header.style';

export type PopperHeaderProps = Styles & HTMLAttributes<HTMLDivElement>;

const Header: FunctionComponent<PopperHeaderProps> = (props) => {
  const {classes, className, children, ...otherProps} = props;

  return (
    <div className={`${classes.root} ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default withStyles(styles)(Header);
