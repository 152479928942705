import React, {FunctionComponent} from 'react';

import {filterColorChipStylesDecorator} from '../FilterChip';
import {MultipleCheckFilterChip, MultipleCheckFilterChipProps} from '../MultipleCheckFilter';

interface Props extends MultipleCheckFilterChipProps {}

const Chip: FunctionComponent<Props> = (props) => {
  return <MultipleCheckFilterChip {...props} />;
};

export default filterColorChipStylesDecorator(Chip);
