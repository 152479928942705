import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    barRoot: {
      margin: '5px 0',
      borderRadius: 6,
      minHeight: 15,
      backgroundColor: '#E6E6E6',
      '&:hover': {
        backgroundColor: '#E6E6E6',
      },
    },
    barPercentage: {
      background: 'linear-gradient(90deg, #B0F089 0%, #90D666 99.87%)',
      height: 15,
      borderRadius: 6,
    },
    barOver: {
      background: 'linear-gradient(90deg, #F59567 0%, #FF1717 99.87%)',
    },
    barUnderHalf: {
      background: 'linear-gradient(90deg, #FFE81D 0%, #FFBC00 99.87%)',
    },
  });

export type Styles = WithStyles<typeof styles>;
