import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      width: '1.1em',
      height: '1.1em',
      color: '#666666',
      marginLeft: 10,
      backgroundColor: '#F7F7F7',
      padding: '0.2em',
      borderRadius: 50,
      border: 'solid 1px #EDEDED',
    },
  });

export type Styles = WithStyles<typeof styles>;
