import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {Styles, styles} from './Text.style';

interface Props extends Styles {}

const DatagridText: FunctionComponent<Props> = (props) => {
  const {children, classes} = props;
  return (
    <Typography variant="caption" className={classes.root}>
      {children}
    </Typography>
  );
};

export default withStyles(styles)(DatagridText);
