import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {},
    info: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    current: {
      color: '#666666',
      fontWeight: 500,
      fontSize: 14,
    },
    total: {
      letterSpacing: -1,
      color: '#666666',
      fontWeight: 900,
      fontSize: 24,
    },
  });

export type Styles = WithStyles<typeof styles>;
