import React from 'react';

import classNames from 'classnames';
import _capitalize from 'lodash/capitalize';
import {components} from 'react-select';

const Menu = (props) => {
  const {selectProps} = props;
  const {'data-testid': dataTestId, classes, TextFieldProps} = selectProps;
  const {margin = 'none'} = TextFieldProps || {};

  return (
    <components.Menu
      {...props}
      className={classNames(classes.paper, classes[`paperMargin${_capitalize(margin)}`])}
      data-testid={dataTestId ? `${dataTestId}-menu` : dataTestId}
    >
      {props.children}
    </components.Menu>
  );
};

Menu.muiName = 'Menu';

export default Menu;
