import React, {Component, ComponentType} from 'react';

import {Subtract} from 'utility-types';

export interface InjectedArrowTooltipableProps {
  tooltipArrowRef: HTMLSpanElement | null;
  onSetTooltipArrowRef(): void;
}

const initialState = {
  arrowRef: null as HTMLSpanElement,
};

type State = typeof initialState;

export const makeArrowTooltipable = <P extends InjectedArrowTooltipableProps>(
  WraptComponent: ComponentType<P>,
) =>
  class MakeCounter extends Component<Subtract<P, InjectedArrowTooltipableProps>, State> {
    state: State = initialState;

    onSetTooltipArrowRef = (arrowRef: HTMLSpanElement) => {
      this.setState({arrowRef});
    };

    render() {
      return (
        <WraptComponent
          {...this.props as P}
          tooltipArrowRef={this.state.arrowRef}
          onSetTooltipArrowRef={this.onSetTooltipArrowRef}
        />
      );
    }
  };
