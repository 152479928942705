import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    day: {
      letterSpacing: -1,
      fontSize: 32,
      fontWeight: 900,
      color: '#666666',
      lineHeight: 1,
    },
    year: {
      fontSize: 18,
      fontWeight: 500,
      color: '#666666',
    },
    time: {fontSize: 12, fontWeight: 500, color: '#888888'},
  });

export type Styles = WithStyles<typeof styles>;
