import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      borderLeft: 'none',
      borderTop: 'none',
      fontSize: '0.8rem',
      fontWeight: 400,
      flexFlow: 'row nowrap',
      flexGrow: 1,
      flexBasis: 0,
      padding: '0.5em 1rem',
      wordBreak: 'break-word',
    },
    text: {
      color: '#4A4A4A',
      fontSize: '0.8rem',
      fontWeight: 400,
      width: '100%',
    },
    headRoot: {
      color: 'rgba(0, 0, 0, 0.54)',
      backgroundColor: '#f9f9f9',
      borderWidth: '1px',
      cursor: 'pointer',
    },
    headText: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
      fontWeight: 500,
    },
    highlightedText: {
      fontWeight: 700,
    },
    placeholder: {
      '& $text': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '0.8rem',
        backgroundColor: '#f3f3f3',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
