import React, {FunctionComponent, HTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './Content.style';

interface LayoutContentProps extends Styles, HTMLAttributes<HTMLDivElement> {}

const LayoutContent: FunctionComponent<LayoutContentProps> = ({
  classes,
  className,
  children,
  ...otherProps
}) => (
  <div className={classNames(classes.root, className)} {...otherProps}>
    {children}
  </div>
);

export default withStyles(styles)(LayoutContent);
