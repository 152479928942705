import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography, {TypographyProps} from '@material-ui/core/Typography';

import {Style, styles} from './LoadingDots.style';

export type LoadingDotsProps = TypographyProps & Style & {};

const LoadingDots: FunctionComponent<LoadingDotsProps> = (props) => {
  return <Typography {...props} />;
};

LoadingDots.defaultProps = {
  children: 'Loading',
};

export default withStyles(styles)(LoadingDots);
