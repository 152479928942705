import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './Row.style';

interface Props extends Styles, React.HTMLAttributes<HTMLDivElement> {
  isSelected?: boolean;
}

const DatagridTableViewRow: FunctionComponent<Props> = (props) => {
  const {children, classes, isSelected} = props;
  return (
    <div className={classNames(classes.root, {[classes.selected]: isSelected})}>{children}</div>
  );
};

DatagridTableViewRow.defaultProps = {
  isSelected: false,
};

export default withStyles(styles)(DatagridTableViewRow);
