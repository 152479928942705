import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      flex: 1,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '-0.02em',
      color: '#000000',
    },
  });

export type Styles = WithStyles<typeof styles>;
