import React, {Component} from 'react';

import List, {ListProps} from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './Menu.style';

export type DrawerMenuProps = ListProps & Styles;

class DrawerContent extends Component<DrawerMenuProps> {
  render() {
    const {classes, className, children, ...others} = this.props;

    return (
      <List component="nav" className={classNames(classes.root, className)} {...others}>
        {children}
      </List>
    );
  }
}

export default withStyles(styles)(DrawerContent);
