import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './Right.style';

interface Props extends Styles {}

const HeaderRight: FunctionComponent<Props> = (props) => {
  const {classes, children} = props;

  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(HeaderRight);
