import React, {MouseEvent} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {Styles, styles} from './ServiceNavigationMenu.style';
import {ServiceNavigationMenuItem} from './ServiceNavigationMenuItem';
import {
  ServiceNavigationMenuPopper,
  ServiceNavigationMenuPopperProps,
} from './ServiceNavigationMenuPopper';
import {ServiceOption} from './utils';

export type ServiceNavigationMenuProps = Styles &
  Pick<ServiceNavigationMenuPopperProps, 'title'> & {
    options: ServiceOption[];
  };

const ServiceNavigationMenu: React.FC<ServiceNavigationMenuProps> = React.memo((props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = React.useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const {children, title, options, classes} = props;

  return (
    <>
      <IconButton
        color="primary"
        aria-label="More"
        aria-owns={anchorEl ? 'long-menu' : ''}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <KeyboardArrowDownIcon />
      </IconButton>

      <ServiceNavigationMenuPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClickAway={handleClose}
        title={title}
        classes={classes}
      >
        {options.map((option) => (
          <ServiceNavigationMenuItem
            onClick={option.onClick}
            key={`ServiceNavigationMenuItem-${option.label}`}
          >
            {option.label}
          </ServiceNavigationMenuItem>
        ))}
        {children}
      </ServiceNavigationMenuPopper>
    </>
  );
});

export default withStyles(styles)(ServiceNavigationMenu);
