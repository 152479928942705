import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    trackVertical: {
      zIndex: 9999,
      right: 2,
      bottom: 2,
      top: 2,
      borderRadius: 3,
    },
    renderView: {
      zIndex: 1,
    },
    trackHorizontal: {
      zIndex: 9999,
      right: 2,
      bottom: 2,
      left: 2,
      borderRadius: 3,
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
