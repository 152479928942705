import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {$PropertyType} from 'utility-types';

import {DatagridContext} from '../../../../Datagrid/Datagrid.context';

import {Styles, styles} from './Card.style';

interface Props extends Styles {
  onClick?: $PropertyType<React.DOMAttributes<HTMLDivElement>, 'onClick'>;
  onMouseOver?: $PropertyType<React.DOMAttributes<HTMLDivElement>, 'onMouseOver'>;
  onMouseLeave?: $PropertyType<React.DOMAttributes<HTMLDivElement>, 'onMouseLeave'>;
}

const DatagridCardViewCards: React.FunctionComponent<Props> = (props) => {
  const {children, classes, onClick, onMouseOver, onMouseLeave} = props;

  return (
    <DatagridContext.Consumer>
      {({loading}) => {
        return (
          <div
            className={classes.card}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            {loading && (
              <>
                <div className={classNames({[classes.placeholder]: loading})} />
                <br />
                <div
                  className={classNames({
                    [classes.placeholder]: loading,
                  })}
                />
                <br />
                <div
                  className={classNames({
                    [classes.placeholder]: loading,
                  })}
                />
                <br />
                <div
                  className={classNames({
                    [classes.placeholder]: loading,
                  })}
                />
              </>
            )}
            {!loading && children}
          </div>
        );
      }}
    </DatagridContext.Consumer>
  );
};

DatagridCardViewCards.defaultProps = {
  onClick: () => null,
  onMouseOver: () => null,
  onMouseLeave: () => null,
};

export default withStyles(styles)(DatagridCardViewCards);
