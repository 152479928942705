import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import moment, {Moment} from 'moment';
import {DateRangePicker, DateRangePickerShape, FocusedInputShape} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {Period} from '../../models/Period';
import {TextField, TextFieldProps, TextFieldSize} from '../TextField';
import {style, Style} from './DateRangePicker.style';

export type FocusedInput = FocusedInputShape;

const CustomDateRangePicker = (props: DateRangePickerShape) => {
  const ref = React.useRef<Component<DateRangePickerShape> & {dayPickerContainer: HTMLElement}>(
    null,
  );

  React.useEffect(() => {
    const {dayPickerContainer} = ref.current;
    // Set focus to calendar container
    if (props.focusedInput && dayPickerContainer) {
      dayPickerContainer.tabIndex = 0;
      dayPickerContainer.focus();
    }
  }, [props.focusedInput]);

  return <DateRangePicker ref={ref} {...props} />;
};

type DateRangePickerWrapperProps = Omit<
  DateRangePickerShape,
  'startDate' | 'endDate' | 'focusedInput' | 'onFocusChange' | 'onDatesChange'
> & {
  startDate?: Moment;
  endDate?: Moment;
  inputRef?: React.RefObject<HTMLDivElement>;
  onDatesChange: (dates: Period) => void;
  focusedInput?: FocusedInput;
  onFocusChange?: (focusedInput: FocusedInput) => void;
};

interface State {
  focusedInput: FocusedInput | null;
}
class DateRangePickerWrapper extends Component<DateRangePickerWrapperProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }
  onDatesChange = ({startDate, endDate}: Pick<DateRangePickerShape, 'startDate' | 'endDate'>) => {
    const format = 'YYYY-MM-DD';
    this.props.onDatesChange({
      startDate: startDate ? startDate.format(format) : '',
      endDate: endDate ? endDate.format(format) : '',
    });
  };

  onFocusChange = (focusedInput?: FocusedInput) => {
    this.setState({focusedInput});
  };

  render() {
    const {
      inputRef,
      startDate,
      endDate,
      onDatesChange,
      isOutsideRange,
      appendToBody,
      regular,
      focusedInput,
      onFocusChange,
      anchorDirection,
      minimumNights,
      openDirection,
      ...other
    } = this.props;

    return (
      <div {...other} ref={inputRef}>
        <CustomDateRangePicker
          anchorDirection={anchorDirection}
          startDate={startDate}
          startDateId={'startDate'}
          endDate={endDate}
          endDateId={'endDate'}
          regular={regular}
          focusedInput={onFocusChange ? focusedInput : this.state.focusedInput}
          onDatesChange={this.onDatesChange}
          onFocusChange={onFocusChange ? onFocusChange : this.onFocusChange}
          showDefaultInputIcon
          noBorder
          minimumNights={minimumNights}
          hideKeyboardShortcutsPanel
          isOutsideRange={isOutsideRange}
          appendToBody={appendToBody}
          customArrowIcon={<KeyboardBackspaceIcon style={{transform: 'rotate(180deg)'}} />}
          openDirection={openDirection}
        />
      </div>
    );
  }
}

export type DateRangePickerProps = {
  startDate: string;
  endDate: string;
} & Style &
  Pick<DateRangePickerWrapperProps, 'onDatesChange'> &
  Partial<
    Pick<
      DateRangePickerWrapperProps,
      | 'onFocusChange'
      | 'focusedInput'
      | 'anchorDirection'
      | 'minimumNights'
      | 'openDirection'
      | 'isOutsideRange'
      | 'appendToBody'
      | 'regular'
    >
  > &
  Pick<TextFieldProps, 'id' | 'label' | 'required' | 'fullWidth'>;

const Presentational: React.SFC<DateRangePickerProps> = ({
  classes,
  id,
  label,
  required,
  fullWidth,
  regular,
  startDate,
  endDate,
  onDatesChange,
  isOutsideRange,
  appendToBody,
  focusedInput,
  onFocusChange,
  anchorDirection,
  minimumNights,
  openDirection,
}) => {
  return (
    <TextField
      id={id}
      size={TextFieldSize.small}
      label={label}
      required={required}
      fullWidth={fullWidth}
      className={classes.textField}
      InputProps={{
        inputProps: {
          startDate: startDate ? moment(startDate) : null,
          endDate: endDate ? moment(endDate) : null,
          className: classes.outlined,
          onDatesChange,
          regular,
          appendToBody,
          isOutsideRange,
          focusedInput,
          onFocusChange,
          anchorDirection,
          minimumNights,
          openDirection,
        },
        inputComponent: DateRangePickerWrapper as any,
      }}
    />
  );
};

Presentational.defaultProps = {
  regular: false,
  isOutsideRange: (_day) => false,
  appendToBody: false,
};

export default withStyles(style)(Presentational);
