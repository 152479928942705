import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 48,
    },
    title: {
      flex: 1,
      fontWeight: 700,
      fontSize: 14,
      color: '#888888',
    },
    settingsIcon: {
      transform: 'translateX(12px)',
    },
  });

export type Styles = WithStyles<typeof styles>;
