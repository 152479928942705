import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {
  Popper,
  PopperContent,
  PopperHeader,
  PopperPaper,
  PopperProps,
} from '../../Popper';
import {
  ServiceNavigationMenuItem,
} from '../../ServiceNavigationMenu/ServiceNavigationMenuItem';

import {Styles, styles} from './ServiceNavigationMenuPopover.style';

export type ServiceNavigationMenuPopperProps = Pick<
  PopperProps,
  'open' | 'anchorEl' | 'onClickAway'
> &
  Styles & {
    title: string;
  };

const ServiceNavigationMenu: FunctionComponent<ServiceNavigationMenuPopperProps> = ({
  open,
  anchorEl,
  onClickAway,
  classes,
  title,
  children,
}) => {
  return (
    <Popper open={open} anchorEl={anchorEl} onClickAway={onClickAway}>
      <PopperPaper>
        <PopperHeader classes={{root: classes.header}}>
          <ServiceNavigationMenuItem disabled classes={{itemTextPrimary: classes.headerText}}>
            {title}
          </ServiceNavigationMenuItem>
        </PopperHeader>
        <PopperContent>{children}</PopperContent>
      </PopperPaper>
    </Popper>
  );
};

export default withStyles(styles)(ServiceNavigationMenu);
