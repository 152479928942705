import React from 'react';

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import {Scrollbars, ScrollbarsType} from '../../../../Scrollbars';

import {Styles, styles} from './Body.style';

export interface DatagridCardViewBodyProps extends Styles {
  scrollbarsRef?: React.RefObject<ScrollbarsType>;
}

const DatagridCardViewBody: React.FunctionComponent<DatagridCardViewBodyProps> = (props) => {
  const {children, classes, scrollbarsRef} = props;
  return (
    <div className={classes.root}>
      <Scrollbars ref={scrollbarsRef as any}>
        <div className={classes.cardWrapper}>
          <Grid container spacing={16}>
            {children}
          </Grid>
        </div>
      </Scrollbars>
    </div>
  );
};

DatagridCardViewBody.displayName = 'DatagridCardViewBody';

export default withStyles(styles)(DatagridCardViewBody);
