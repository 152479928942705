import React, {KeyboardEvent, MouseEvent, PureComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Add from '@material-ui/icons/Add';
import isNil from 'lodash/isNil';

import {CssTooltipProps} from '../CssTooltip';
import {FilterChip, FilterChipProps} from '../FilterChip';
import {SearchPopper, SearchPopperOption, SearchPopperProps} from '../SearchPopper';
import {Styles, styles} from './FilterAdd.style';

const initialState = {
  searchTerm: '',
};

type State = typeof initialState;

export type FilterAddOption = SearchPopperOption;

export interface FilterAddProps
  extends Styles,
    Pick<SearchPopperProps, 'heightMin' | 'heightMax' | 'onClearClick' | 'modifiers'> {
  open: boolean;
  anchorEl?: HTMLElement;
  defaultOption?: SearchPopperProps['options'][number];
  mainFilters?: SearchPopperProps['mainOptions'];
  extraFilters?: SearchPopperProps['extraOptions'];
  onSearch?: SearchPopperProps['onSearch'];
  filters?: SearchPopperProps['options'];
  tooltipProps?: Partial<CssTooltipProps>;
  onSelect: (
    filterId: SearchPopperProps['options'][number]['id'],
    filterInitialValue: SearchPopperProps['options'][number]['initialValue'],
    evt: MouseEvent | KeyboardEvent,
  ) => void;
  onOpen: (anchorEl: HTMLElement) => void;
  onClose: () => void;
  disabled: boolean;
  noFiltersMessage?: SearchPopperProps['noOptionsMessage'];
  chipRef?: React.RefObject<{}>;
  rootChipRef?: FilterChipProps['rootRef'];
  clearOptionTitle?: string;
  clearOptionDescription?: string;
  hideClearOption?: boolean;
}

class FilterAdd extends PureComponent<FilterAddProps, State> {
  static defaultProps: Partial<FilterAddProps> = {
    noFiltersMessage: 'Filter not found.',
    clearOptionTitle: 'Clear Filters',
    clearOptionDescription: 'Start from scratch, remove all the filters',
    chipRef: null,
    modifiers: {
      offset: {
        offset: '0,20px',
      },
    },
  };

  constructor(props: FilterAddProps) {
    super(props);
    this.state = initialState;
  }

  componentWillReceiveProps(nextProps: FilterAddProps) {
    if (this.props.open && !nextProps.open) {
      this.setState({searchTerm: initialState.searchTerm});
      this.props.onSearch('');
    }
  }

  handleClick = (event: MouseEvent<HTMLDivElement>) => {
    this.props.onOpen(event.currentTarget);
  };

  handleSelection = (
    option: SearchPopperProps['options'][number],
    isDefault: boolean,
    evt: MouseEvent | KeyboardEvent,
  ) => {
    const {searchTerm} = this.state;
    this.props.onSelect(
      option.id,
      isDefault && isNil(option.initialValue) ? searchTerm : option.initialValue,
      evt,
    );
  };

  handleSearch = (searchTerm: string) => {
    this.setState({searchTerm});
    const {onSearch} = this.props;
    if (onSearch) {
      onSearch(searchTerm);
    }
  };

  filterOptions = (opts: SearchPopperProps['mainOptions'] | SearchPopperProps['options']) => {
    const {searchTerm} = this.state;
    return (opts || []).filter(
      ({title = '', description = ''}) =>
        title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        description.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };

  getMainOptions = () => {
    const {mainFilters} = this.props;
    return this.filterOptions(mainFilters);
  };

  getOptions = () => {
    const {filters} = this.props;
    return this.filterOptions(filters);
  };

  getAddIcon = () => {
    const {classes, disabled} = this.props;
    return !disabled ? <Add /> : <Add className={classes.disabledIcon} />;
  };

  getClearOption = () => {
    const {hideClearOption, clearOptionTitle, clearOptionDescription} = this.props;
    if (hideClearOption) {
      return undefined;
    } else {
      const clearOption = {
        id: '__clear_all__',
        title: clearOptionTitle,
        description: clearOptionDescription,
      } as SearchPopperProps['options'][number];
      return this.filterOptions([clearOption])[0];
    }
  };

  render() {
    const {
      anchorEl,
      open,
      heightMin,
      heightMax,
      onClose,
      noFiltersMessage,
      defaultOption,
      onClearClick,
      modifiers,
      extraFilters,
    } = this.props;

    const {searchTerm} = this.state;

    const mainOptions = this.getMainOptions();
    const options = this.getOptions();

    return (
      <>
        {this.renderFilterChip()}
        <SearchPopper
          anchorEl={anchorEl}
          open={open}
          onClickAway={onClose}
          defaultOption={defaultOption}
          mainOptions={mainOptions}
          extraOptions={extraFilters}
          options={options}
          searchPlaceholder="Select a filter"
          onClickItem={this.handleSelection}
          noOptionsMessage={noFiltersMessage}
          onSearch={this.handleSearch}
          searchTerm={searchTerm}
          heightMin={heightMin}
          heightMax={heightMax}
          clearOption={this.getClearOption()}
          onClearClick={onClearClick}
          modifiers={modifiers}
        />
      </>
    );
  }

  renderFilterChip = () => {
    const {classes, disabled, chipRef, rootChipRef, tooltipProps} = this.props;
    const icon = this.getAddIcon();
    return (
      <FilterChip
        tooltipProps={tooltipProps}
        isDashed
        icon={icon}
        handleClick={this.handleClick}
        isLoaded={!disabled}
        label="Add filter"
        chipRef={chipRef}
        rootRef={rootChipRef}
        classes={{root: classes.chipRoot}}
      />
    );
  };
}

export default withStyles(styles)(FilterAdd);
