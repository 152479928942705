import React, {FunctionComponent, HTMLProps} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import classNames from 'classnames';

import {styles, Styles} from './PageHeader.style';

export type PageHeaderProps = HTMLProps<HTMLElement> &
  Styles & {
    hideBackButton?: boolean;
    loading?: boolean;
    title: string;
    subtitle?: string;
    onClickBackButton?: () => void;
  };

export const PageHeader: FunctionComponent<PageHeaderProps> = ({
  classes,
  children,
  className,
  hideBackButton,
  title,
  subtitle,
  onClickBackButton,
  loading,
  ...otherProps
}) => (
  <header className={classNames(classes.root, className)} {...otherProps}>
    <div className={classes.leftContent}>
      {!hideBackButton && (
        <IconButton color="primary" title="Go back" onClick={onClickBackButton}>
          <ArrowBack />
        </IconButton>
      )}
      {loading ? (
        <Typography className={classNames(classes.title, classes.loading)}>&nbsp;</Typography>
      ) : (
        <Typography className={classes.title}>
          {title}
          {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
        </Typography>
      )}
    </div>
    <div className={classes.rightContent}>{children}</div>
  </header>
);

PageHeader.displayName = 'PageHeader';

PageHeader.defaultProps = {
  hideBackButton: false,
  loading: false,
  onClickBackButton: () => null,
};

export default withStyles(styles)(PageHeader);
