import React, {FunctionComponent, HTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {styles, Styles} from './Section.style';

export type PopperContentSectionProps = Styles &
  HTMLAttributes<HTMLDivElement> & {
    dense?: boolean;
  };

const Section: FunctionComponent<PopperContentSectionProps> = (props) => {
  const {classes, className, children, dense, ...otherProps} = props;

  return (
    <div className={classNames(classes.root, className, {[classes.dense]: dense})} {...otherProps}>
      {children}
    </div>
  );
};

export default withStyles(styles)(Section);
