import {pascalCase} from '../../../../utils/string';
import {FilterColorChip, FilterColorChipProps, filterColorChipStylesDecorator} from '../FilterChip';

export interface MultipleCheckFilterChipProps extends FilterColorChipProps<string[]> {
  chipPrefix?: string;
  templateLabel?: (item: string) => string;
}

class Chip extends FilterColorChip<MultipleCheckFilterChipProps, string[]> {
  static defaultProps: Partial<MultipleCheckFilterChipProps> = {
    isLoaded: true,
    templateLabel: (item) => pascalCase(item.replace(/-/g, ' ')),
  };

  getLabel(value: string[] = []) {
    let result = '';
    const getItem = (index: number) => {
      const item = value[index];
      return this.props.templateLabel(item);
    };

    if (value.length > 2) {
      result = `${getItem(0)}, ${getItem(1)} and more`;
    } else if (value.length === 2) {
      result = `${getItem(0)} and ${getItem(1)}`;
    } else if (value.length === 1) {
      result = `${getItem(0)}`;
    }

    const {chipPrefix} = this.props;

    return chipPrefix ? `${this.props.chipPrefix} ${result}` : result;
  }
}

export default filterColorChipStylesDecorator(Chip);
