import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    sizeSmallTextAreaFont: {
      fontSize: 14,
    },
    sizeLargeTextAreaFont: {},
    textAreaPadding: {
      padding: 10,
    },
  });

export type Styles = WithStyles<typeof styles>;
