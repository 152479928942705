import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';
import {SelectComponentsConfig} from 'react-select/lib/components';
import Creatable, {Props} from 'react-select/lib/Creatable';
import {Option as SelectOption} from 'react-select/lib/filters';
import {createFilter} from 'react-select/lib/filters';

import {AutocompleteSize, defaultAutoCompleteStyles} from '../Autocomplete';
import {styles, Styles} from '../Autocomplete.style';
import {asyncComponents} from '../utils';

type CreatableProps = Props<SelectOption>;

export type CreatableAutocompleteProps = CreatableProps &
  Styles & {
    virtualized?: boolean;
    size?: AutocompleteSize;
    SingleValueProps?: TypographyProps;
    components?: SelectComponentsConfig<SelectOption>;
  };

const CreatableAutocomplete: FunctionComponent<CreatableAutocompleteProps> = (props) => {
  const {
    className,
    classes,
    virtualized,
    filterOption,
    components = {},
    styles = {},
    ...otherProps
  } = props;
  const {root, ...otherClasses} = classes;

  return (
    <Creatable
      className={classNames(root, className)}
      classes={otherClasses}
      components={{...asyncComponents, ...components}}
      virtualized={virtualized}
      filterOption={filterOption || (virtualized && createFilter({ignoreAccents: false}))} // this makes all the difference!
      styles={{...defaultAutoCompleteStyles, ...styles}}
      {...otherProps}
    />
  );
};

CreatableAutocomplete.defaultProps = {
  menuPortalTarget: process.browser ? document.body : null,
  menuPlacement: 'auto',
  isMulti: false,
  label: '',
  size: AutocompleteSize.large,
  menuShouldScrollIntoView: true,
  instanceId: 'creatable-auto-complete',
};

export default withStyles(styles)(CreatableAutocomplete);
