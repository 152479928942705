import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {ActionPopper, ActionPopperProps} from '../../../ActionPopper';
import {PopperContent} from '../../../Popper';
import {Styles, styles} from './SettingsPopper.style';

export interface SettingsPopperProps
  extends Pick<
      ActionPopperProps,
      'open' | 'anchorEl' | 'disabled' | 'modifiers' | 'placement' | 'onSubmit' | 'onClose'
    >,
    Styles {
  title?: string;
}

const Presentational: FC<SettingsPopperProps> = (props) => {
  const {
    open,
    anchorEl,
    title,
    modifiers,
    disabled,
    children,
    placement,
    onSubmit,
    onClose,
  } = props;
  return (
    <ActionPopper
      modifiers={modifiers}
      placement={placement}
      open={open}
      anchorEl={anchorEl}
      headerTitle={title}
      disabled={disabled}
      onSubmit={onSubmit}
      onCancel={onClose}
      onClose={onClose}
    >
      <PopperContent>{children}</PopperContent>
    </ActionPopper>
  );
};
Presentational.defaultProps = {
  title: 'Settings',
  placement: 'bottom-end',
  modifiers: {
    offset: {offset: '-10px,10px'},
    preventOverflow: {
      enabled: true,
    },
  },
};
export default withStyles(styles)(Presentational);
