import React from 'react';

import ListItem, {ListItemProps} from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {Omit} from 'utility-types';

import {Styles, styles} from './SideListItem.style';

export type SideListItemProps = Styles &
  Omit<ListItemProps, 'classes'> & {
    ListItemClasses?: ListItemProps['classes'];
    active?: boolean;
  };

const SideListItem: React.SFC<SideListItemProps> = (props) => {
  const {classes, active, children, ListItemClasses, ...otherProps} = props;
  return (
    <ListItem
      className={classNames(classes.listItem, {[classes.active]: active})}
      classes={ListItemClasses}
      {...otherProps}
    >
      {children}
    </ListItem>
  );
};

export default withStyles(styles)(SideListItem);
