import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      color: 'rgba(0,0,0,0.4)',
      fontWeight: 500,
      fontSize: '0.55rem',
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 8',
      borderRadius: 30,
      marginLeft: 8,
    },
    warning: {
      backgroundColor: '#F3D63B',
    },
    danger: {
      backgroundColor: '#ffa8c6',
    },
  });

export type Styles = WithStyles<typeof styles>;
