import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {
      margin: '-10px 0px',
      backgroundColor: '#666666',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      padding: '0px 15px',
    },
    info: {
      color: '#fff',
      fontSize: 13,
      fontWeight: 500,
    },
  });

export type Styles = WithStyles<typeof styles>;
