import React from 'react';

import Button, {ButtonProps} from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import {returnMessageByOs} from '../../utils/os';
import {CssTooltip, CssTooltipProps} from '../CssTooltip';
import {HelpProps} from '../Help';
import {Help} from '../Help';
import {PopperFooter, PopperFooterProps} from '../Popper';
import {Spinner} from '../Spinner';
import {ActionPopperProps} from './ActionPopper';
import {styles, Styles} from './ActionPopper.style';

export type ActionPopperFooterProps = Pick<ActionPopperProps, 'classes'> & {
  submitTooltip?: string;
  submitTooltipPlacement?: CssTooltipProps['placement'];
  submitTooltipCenterArrow?: CssTooltipProps['centerArrow'];
  disabled?: boolean;
  onSubmitLabel?: string;
  onCancelLabel?: string;
  children?: PopperFooterProps['children'];
  onCancel?: ButtonProps['onClick'];
  onSubmit?: ButtonProps['onClick'];
  onHelpClick?: HelpProps['onClick'];
  showSpinner?: boolean;
} & Styles;

const Footer = (props: ActionPopperFooterProps) => {
  const {
    classes,
    children,
    disabled,
    onSubmitLabel,
    onHelpClick,
    submitTooltip,
    submitTooltipPlacement,
    submitTooltipCenterArrow,
    onSubmit,
    onCancel,
    onCancelLabel,
    showSpinner,
  } = props;

  return (
    <PopperFooter classes={{root: classes.footerRoot}}>
      {onHelpClick && (
        <Help onClick={onHelpClick} hideTooltip classes={{root: classes.helpButton}} />
      )}
      {children}
      {onCancel && (
        <Button
          variant="text"
          onClick={onCancel}
          classes={{
            root: `${classes.footerButton} ${classes.cancelButton}`,
            label: classes.footerButtonLabel,
          }}
        >
          {onCancelLabel}
        </Button>
      )}
      {showSpinner && <Spinner />}

      {onSubmit && (
        <CssTooltip
          title={submitTooltip}
          placement={submitTooltipPlacement}
          centerArrow={submitTooltipCenterArrow}
        >
          <Button
            variant="text"
            onClick={onSubmit}
            disabled={disabled}
            classes={{
              root: classes.footerButton,
              label: classes.footerButtonLabel,
            }}
          >
            {onSubmitLabel}
          </Button>
        </CssTooltip>
      )}
    </PopperFooter>
  );
};

Footer.defaultProps = {
  onSubmitLabel: 'Apply',
  onCancelLabel: 'Cancel',
  submitTooltip: returnMessageByOs,
  submitTooltipPlacement: 'bottom' as 'bottom',
  showSpinner: false,
};

export default withStyles(styles)(Footer);
