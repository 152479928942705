import React, {FunctionComponent} from 'react';

import Link, {LinkProps as MuiLinkProps} from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './Link.style';

export type LinkProps = Styles & MuiLinkProps;

const Presentational: FunctionComponent<LinkProps> = ({
  classes,
  className,
  children,
  ...linkProps
}) => {
  return (
    <Link className={classNames(classes.root, className)} {...linkProps}>
      {children}
    </Link>
  );
};

Presentational.defaultProps = {};

export default withStyles(styles)(Presentational);
