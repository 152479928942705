import React, {createRef, RefObject} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {onElementHeightChange} from '../../../../utils/element';
import {DatagridContext} from '../../../Datagrid/Datagrid.context';
import {ColumnHeaderProperty, DatagridSorting} from '../../../Datagrid/Datagrid.model';
import {Scrollbars, ScrollbarsType} from '../../../Scrollbars';
import {DatagridTableViewBody} from './Body';
import {DatagridTableViewHead} from './Head';
import {DatagridTableViewPlaceholder} from './Placeholder';
import {Styles, styles} from './TableView.style';

interface Props extends Styles {
  properties: ColumnHeaderProperty[];
  sorting: DatagridSorting;
  onSortingChange: (sorting: DatagridSorting) => void;
  scrollbarsRef?: RefObject<ScrollbarsType>;
}

export type DatagridTableViewProps = Props;

export type DatagridTableViewScroll = ScrollbarsType;

class DatagridTableView extends React.Component<Props> {
  containerRef = createRef<HTMLDivElement>();
  tableViewBodyRef = createRef<HTMLDivElement>();
  PLACEHOLDER_HEIGHT = 54;

  componentDidMount() {
    onElementHeightChange(this.tableViewBodyRef.current, () => {
      this.forceUpdate();
    });
  }

  calculateNumberOfPlaceholders = (loading: boolean) => {
    const defaultHeight = Math.ceil(window.screen.height / this.PLACEHOLDER_HEIGHT);
    if (loading && this.containerRef.current) {
      const height = this.containerRef.current.offsetHeight;
      return Math.ceil(height / this.PLACEHOLDER_HEIGHT);
    } else if (this.tableViewBodyRef.current) {
      const containerHeight =
        (this.containerRef && this.containerRef.current.offsetHeight) || defaultHeight;
      const tableBodyHeight = this.tableViewBodyRef.current.offsetHeight;
      const height = containerHeight > tableBodyHeight ? containerHeight : tableBodyHeight;
      return Math.ceil(height / this.PLACEHOLDER_HEIGHT);
    }
    return defaultHeight;
  };

  render() {
    const {
      classes,
      children,
      properties,
      sorting,
      onSortingChange,
      scrollbarsRef = createRef<ScrollbarsType>(),
    } = this.props;

    return (
      <DatagridContext.Consumer>
        {({printable, loading}) => {
          const numberOfPlaceholders = this.calculateNumberOfPlaceholders(loading);
          return (
            <div className={classes.root}>
              <Scrollbars innerRef={scrollbarsRef}>
                <div ref={this.containerRef} className={classes.grid}>
                  <DatagridTableViewHead
                    classes={{root: classes.head, row: classes.headRow}}
                    properties={properties}
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                  />
                  {!printable && (
                    <DatagridTableViewPlaceholder
                      rows={numberOfPlaceholders}
                      properties={properties}
                    />
                  )}
                  <DatagridTableViewBody
                    bodyRef={this.tableViewBodyRef}
                    classes={{root: classes.body, rows: classes.bodyRows}}
                  >
                    {children}
                  </DatagridTableViewBody>
                </div>
              </Scrollbars>
            </div>
          );
        }}
      </DatagridContext.Consumer>
    );
  }
}

export default withStyles(styles)(DatagridTableView);
