import withStyles from '@material-ui/core/styles/withStyles';

import FilterSlotComp from './FilterSlot';
import {styles} from './FilterSlot.style';
import {removeFalsyFilters} from './removeFalsyFilters';

export const FilterSlot = withStyles(styles)(
  removeFalsyFilters(FilterSlotComp, ['appliedFilters', 'initialAppliedFilters']),
);

FilterSlot.displayName = 'withStyles(FilterSlot)';
