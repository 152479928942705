import {createContext} from 'react';

import {DatagridViewMode} from './Datagrid.model';

export const DatagridContext = createContext({
  items: [],
  viewMode: DatagridViewMode.table,
  loading: false,
  printable: false,
});
