import React, {FunctionComponent, HTMLProps} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {styles, Styles} from './LoadingSpinner.style';

export interface LoadingSpinnerProps extends HTMLProps<HTMLDivElement>, Styles {}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
  children,
  classes,
  className,
  ...otherProps
}: LoadingSpinnerProps) => (
  <div className={classNames(classes.spinner, className)} {...otherProps}></div>
);

LoadingSpinner.displayName = 'LoadingSpinner';

export default withStyles(styles)(LoadingSpinner);
