import React, {FunctionComponent} from 'react';

import {filterColorChipStylesDecorator} from '../FilterChip';
import {RadioFilterChip, RadioFilterChipProps} from '../RadioFilter';

interface Props extends RadioFilterChipProps {}

const Chip: FunctionComponent<Props> = (props) => {
  return <RadioFilterChip {...props} />;
};

Chip.defaultProps = {
  chipPrefix: 'Format:',
};

export default filterColorChipStylesDecorator(Chip);
