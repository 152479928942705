import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import {CssTooltip} from '../../../CssTooltip';
import {styles, Styles} from './Slice.style';

export type Props = Styles & {
  className?: string;
  background?: string;
  percentual: number;
  title?: string;
};

const Slice: React.SFC<Props> = (props) => {
  const {classes, percentual, background, className, title} = props;
  const tip = `${percentual}% ${title}`;

  return (
    <CssTooltip title={tip} placement="bottom">
      <Typography
        component="div"
        className={classNames([classes.root, className])}
        style={{
          width: `${percentual}%`,
          background,
        }}
      />
    </CssTooltip>
  );
};

export default withStyles(styles)(Slice);
