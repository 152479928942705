import React, {DOMAttributes, FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {$PropertyType} from 'utility-types';

import {DatagridContext} from '../../../../Datagrid/Datagrid.context';

import {Styles, styles} from './Column.style';

interface Props extends Styles {
  style?: React.CSSProperties
  head?: boolean;
  highlighted?: boolean;
  onClick?: $PropertyType<DOMAttributes<HTMLDivElement>, 'onClick'>;
  onMouseOver?: $PropertyType<DOMAttributes<HTMLDivElement>, 'onMouseOver'>;
  onMouseLeave?: $PropertyType<DOMAttributes<HTMLDivElement>, 'onMouseLeave'>;
}

const DatagridTableViewColumn: FunctionComponent<Props> = (props) => {
  const {children, style, classes, head, highlighted, onClick, onMouseOver, onMouseLeave} = props;

  return (
    <DatagridContext.Consumer>
      {({loading}) => {
        const showPlaceholder = !head && loading;

        return (
          <div
            style={style}
            className={classNames(classes.root, {
              [classes.headRoot]: head,
              [classes.placeholder]: showPlaceholder,
            })}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            <Typography
              component="div"
              className={classNames(classes.text, {
                [classes.headText]: head,
                [classes.highlightedText]: highlighted,
              })}
            >
              {!showPlaceholder && children}
            </Typography>
          </div>
        );
      }}
    </DatagridContext.Consumer>
  );
};

DatagridTableViewColumn.defaultProps = {
  head: false,
  highlighted: false,
  onClick: () => null,
  onMouseOver: () => null,
  onMouseLeave: () => null,
};

export default withStyles(styles)(DatagridTableViewColumn);
