import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    backdrop: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    controls: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
    },
    button: {
      color: '#fff',
    },
    container: {
      width: '100%',
      maxWidth: 600,
      flex: '0 1 auto',
      maxHeight: 'calc(100% - 96px)',
      marginTop: 5,
      display: 'flex',
      position: 'relative',
      overflowY: 'auto',
      flexDirection: 'column',
      boxShadow:
        '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
      borderRadius: '8px',
      backgroundColor: '#fff',
    },
    content: {
      maxHeight: '85vh',
    },
  });

export type Styles = WithStyles<typeof styles>;
