import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    disabledInputBase: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    sizeSmall: {
      padding: 0,
    },
    label: {
      color: 'rgba(0,0,0,0.6)',
      lineHeight: '12px',
    },
    staticValue: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 14,
      padding: '6px 0 7px',
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
