import {FilterColorChip, FilterColorChipProps, filterColorChipStylesDecorator} from '../FilterChip';

interface Props extends FilterColorChipProps<string> {}

class Chip extends FilterColorChip<Props, string> {
  getLabel(value: string) {
    return `Search: ${value}`;
  }
}

export default filterColorChipStylesDecorator(Chip);
