import React, {FunctionComponent, HTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {styles, Styles} from './Content.style';

export type PopperContentProps = Styles & HTMLAttributes<HTMLDivElement>;

const Content: FunctionComponent<PopperContentProps> = (props) => {
  const {classes, children, className, ...otherProps} = props;

  return (
    <div className={classNames(classes.root, className)} {...otherProps}>
      {children}
    </div>
  );
};

export default withStyles(styles)(Content);
