export const safeCall = <Fn extends (...args: unknown[]) => unknown>(
  fn: Fn,
  ...args: Parameters<Fn>
) => {
  if (fn) {
    fn(...args);
  }
};

export const conditionalCall = <Fn extends (...args: unknown[]) => unknown>(
  condition: boolean,
  fn: Fn,
  ...args: Parameters<Fn>
) => {
  if (condition) {
    safeCall(fn, ...args);
  }
};

export const nope = () => {
  return;
};

export const conditionalReturn = (condition: boolean, truth: any, fallback: any = {}) =>
  condition ? truth : fallback;
