import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {BaseFilterProps} from '../..';
import {Option} from '../../../../models/Option';
import {debounce} from '../../../../utils/promise';
import {BaseFilter} from '../../BaseFilter';
import Chip from './Chip';
import Popper from './Popper';

type Props = BaseFilterProps<Option[]> & {
  onLoadMore: (s: string) => Promise<Option[]>;
};
const FILTER_ID = 'owner' as const;

export class OwnerFilter extends BaseFilter<Props, Option[]> {
  static id: typeof FILTER_ID = FILTER_ID;

  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Owner',
    description: 'Filter by owner name.',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: OwnerFilter.defaultFilterAddOption,
  } as Partial<Props>;

  debouncedLoad: (search: string) => Promise<Option[]>;

  constructor(props: Props) {
    super(props);
    this.debouncedLoad = debounce(this.props.onLoadMore, 500);
  }

  handleSearch = (searchTerm: string) => {
    return this.debouncedLoad(searchTerm);
  };

  render() {
    const {disabled, anchorEl} = this.context;
    const {value, initialValue, isOpen, resetTempValue, tempValue} = this;
    return (
      <div>
        {(value || []).length > 0 && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={(value || []).map((v) => v.label)}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {isOpen && (
          <Popper
            open={isOpen}
            loadOptions={this.handleSearch}
            anchorEl={anchorEl}
            value={value}
            tempValue={tempValue}
            hasChanges={!isEmpty(tempValue)}
            onPopperClose={this.closePopper}
            onChange={this.onChange}
            resetTempValue={resetTempValue}
          />
        )}
      </div>
    );
  }
}
