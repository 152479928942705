import React, {HTMLAttributes, SFC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './TileInfoRow.style';

export type TileInfoRowProps = Styles & HTMLAttributes<HTMLDivElement> & {};

const Presentational: SFC<TileInfoRowProps> = ({classes, className, children, ...divProps}) => {
  return (
    <div className={classNames(classes.root, className)} {...divProps}>
      {children}
    </div>
  );
};

Presentational.defaultProps = {};

export default withStyles(styles)(Presentational);
