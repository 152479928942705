import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import isEmpty from 'lodash/isEmpty';

import {Option} from '../../../../models/Option';
import {sortBy, uniqueArray} from '../../../../utils/array';
import {onCloseType} from '../../../ActionPopper';
import {AsyncAutocomplete} from '../../../Autocomplete';
import {FilterActionPopper} from '../../../FilterActionPopper';
import {MultiSelectList} from '../../../MultiSelectList';
import {Styles, styles} from './Popper.style';

interface Props extends Styles {
  value: Option[];
  tempValue?: Option[];
  open: boolean;
  anchorEl: HTMLElement;
  loadOptions: (s: string) => Promise<Option[]>;
  hasChanges: boolean;
  resetTempValue: () => void;
  onChange: (owner: unknown[], ev?: React.SyntheticEvent) => void;
  onPopperClose: (temp?: unknown[]) => void;
}

interface State {
  isMenuOpen: boolean;
  selected: Option[];
}

class Popper extends Component<Props, State> {
  filterComponent: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      selected: props.tempValue || props.value,
    };
  }

  handleSubmit = (ev: React.SyntheticEvent<Element, Event>) => {
    const {onChange} = this.props;
    const {selected} = this.state;

    onChange(selected, ev);
  };

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<unknown>,
    type?: onCloseType,
  ) => {
    if (type === 'away' && this.state.isMenuOpen) {
      return;
    }

    const {selected} = this.state;
    this.props.onPopperClose(selected);
  };

  handleMenuOpen = () => {
    this.setState({isMenuOpen: true});
  };

  handleMenuClose = () => {
    this.setState({isMenuOpen: false});
  };

  handleMultiSelectedValueChange = (value: Option[]) => {
    sortBy('label')(value);
    const newValue = uniqueArray(value);
    this.setState({selected: newValue});
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const selectedStateLength = (this.state.selected || []).length;
    const prevStateLength = (prevState.selected || []).length;
    if (
      this.state.selected !== prevState.selected &&
      selectedStateLength > prevStateLength &&
      this.filterComponent?.scrollbars
    ) {
      this.filterComponent.scrollbars.scrollToBottom(true);
    }
  }

  onReset = () => {
    const {value, resetTempValue} = this.props;
    resetTempValue();
    this.setState({selected: value});
  };

  render() {
    const {classes, anchorEl, open, hasChanges, loadOptions} = this.props;
    const {selected} = this.state;

    const isSelectedEmpty = isEmpty(selected);

    return (
      <FilterActionPopper
        innerRef={(ref) => (this.filterComponent = ref)}
        anchorEl={anchorEl}
        open={open}
        hasChanges={hasChanges}
        onClose={this.handleClose}
        headerTitle="Owner"
        onCancelLabel="Reset"
        onCancel={hasChanges && this.onReset}
        disabled={isSelectedEmpty}
        onSubmit={this.handleSubmit}
      >
        <MultiSelectList
          label="Select Owner"
          values={selected}
          defaultOptions
          autoFocus
          SelectComponent={AsyncAutocomplete}
          loadOptions={loadOptions}
          placeholder=""
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          onChange={this.handleMultiSelectedValueChange}
          classes={{rootWithValues: classes.selectWithValues}}
        />
      </FilterActionPopper>
    );
  }
}

export default withStyles(styles)(Popper);
