import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {DatagridContext} from '../../../Datagrid/Datagrid.context';

import {Styles, styles} from './Content.style';

interface Props extends Styles {}

const DatagridContent: FunctionComponent<Props> = (props) => {
  const {children} = props;

  const renderProps = children as (items, viewMode) => JSX.Element;

  return (
    <DatagridContext.Consumer>
      {({items, viewMode}) => renderProps(items, viewMode)}
    </DatagridContext.Consumer>
  );
};

export default withStyles(styles)(DatagridContent);
