import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      zIndex: 5,
    },
    row: {},
    hideSortArrow: {
      opacity: 0,
    },
    smoothSortArrow: {
      opacity: 0.5,
    },
    cursorInitial: {
      cursor: 'initial',
    },
    extraContainerClass: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  });

export type Styles = WithStyles<typeof styles>;
