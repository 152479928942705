import React from 'react';

import ClearIcon from '@material-ui/icons/Close';
import {components} from 'react-select';

import {AdornmentIcon} from '../../TextField';
import {getIndicatorProps} from './indicator-utils';

const ClearIndicator = (props) => {
  const indicatorProps = getIndicatorProps(props, 'clearIndicator');

  return (
    <components.ClearIndicator {...indicatorProps}>
      <AdornmentIcon Icon={ClearIcon} />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
