import React, {FunctionComponent} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';

import {Styles, styles} from './MainButton.style';

export interface DatagridHeaderLeftMainButtonProps extends Styles {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const MainButton: FunctionComponent<DatagridHeaderLeftMainButtonProps> = (props) => {
  const {classes, disabled, onClick} = props;

  return (
    <div className={classes.root}>
      <IconButton onClick={onClick} disabled={disabled}>
        <AddIcon classes={{root: classes.button}} />
      </IconButton>
    </div>
  );
};

MainButton.defaultProps = {
  onClick: () => null,
};

export default withStyles(styles)(MainButton);
