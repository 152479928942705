import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Link, LinkProps} from '../../../../Link';

import {Styles, styles} from './Link.style';

export type FooterLinkProps = Styles & LinkProps;

const Presentational: FunctionComponent<FooterLinkProps> = ({
  classes,
  children,
  ...anchorProps
}) => {
  return (
    <Link className={classes.root} {...anchorProps}>
      {children}
    </Link>
  );
};

Presentational.defaultProps = {
  target: '_blank',
  rel: 'noopener',
};

export default withStyles(styles)(Presentational);
