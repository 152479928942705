export const numberWithCommas = (number: number): string => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '';
  }
};

export const toUSLocale = (
  value: string | number,
  decimals?: number,
  maximumFractionDigits?: number,
) => {
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits,
  });
};

export const toUSLocaleCurrency = (
  value: string | number,
  decimals?: number,
  maximumFractionDigits?: number,
) => {
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits,
    style: 'currency',
    currency: 'USD',
  });
};
