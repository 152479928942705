import React, {ReactNode} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {positionValues} from 'react-custom-scrollbars';

import {ActionPopper, ActionPopperProps} from '../ActionPopper';
import {ConditionalWrap} from '../ConditionalWrap';
import {Scrollbars, ScrollbarsType} from '../Scrollbars';
import {Styles, styles} from './FilterActionPopper.style';

export type FilterActionPopperProps = ActionPopperProps &
  Styles & {
    maxHeight?: number | string;
    footerBorderOnMaxHeight?: boolean;
    hasChanges?: boolean;
    hasChangesTooltipTitle?: string;
  };

const initialState = {
  shouldHaveBorder: false,
};

type State = typeof initialState;

class FilterActionPopper extends React.Component<FilterActionPopperProps, State> {
  scrollbars: ScrollbarsType;
  state: State = initialState;

  static defaultProps: Partial<FilterActionPopperProps> = {
    maxHeight: 600,
    footerBorderOnMaxHeight: true,
    hasChanges: false,
    modifiers: {
      offset: {
        offset: '0,20px',
      },
    },
  };

  onUpdate = (values: positionValues) => {
    const {scrollHeight} = values;
    const {maxHeight, footerBorderOnMaxHeight} = this.props;

    if (scrollHeight > maxHeight && footerBorderOnMaxHeight) {
      this.setState({
        shouldHaveBorder: true,
      });
    } else {
      this.setState({
        shouldHaveBorder: false,
      });
    }
  };

  headerTextWrapper = (children: ReactNode) => {
    const {classes} = this.props;
    return <div className={classes.headerTitleWrapper}>{children}</div>;
  };

  render() {
    const {
      maxHeight,
      children,
      classes,
      footerBorderOnMaxHeight,
      hasChanges,
      hasChangesTooltipTitle,
      headerTitle,
      ...otherProps
    } = this.props;
    const {shouldHaveBorder} = this.state;
    const {
      headerTitleWrapper,
      footerRoot,
      footerBorder,
      changeIndicator,
      tempChangesIndicator,
      ...otherClasses
    } = classes;

    const footerRootClass = footerBorderOnMaxHeight
      ? shouldHaveBorder
        ? footerBorder
        : null
      : footerRoot;

    return (
      <ActionPopper
        classes={{
          ...otherClasses,
          headerRoot: classNames({[changeIndicator]: hasChanges}),
          footerRoot: footerRootClass,
        }}
        headerTitle={
          <ConditionalWrap condition={hasChanges} wrap={this.headerTextWrapper}>
            {headerTitle}
          </ConditionalWrap>
        }
        onCancelLabel="Reset"
        {...otherProps}
      >
        <Scrollbars
          innerRef={(instance) => (this.scrollbars = instance)}
          autoHeight
          autoHeightMax={maxHeight}
          onUpdate={this.onUpdate}
        >
          {children}
        </Scrollbars>
      </ActionPopper>
    );
  }
}
export default withStyles(styles)(FilterActionPopper);
