import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';
import Select from 'react-select';
import {SelectComponentsConfig} from 'react-select/lib/components';
import {createFilter} from 'react-select/lib/filters';
import {Props as SelectProps} from 'react-select/lib/Select';

import {ScrollbarsContext} from '../Scrollbars';
import {styles, Styles} from './Autocomplete.style';
import {commonComponents} from './utils';

export enum AutocompleteSize {
  small = 'small',
  large = 'large',
}

export type AutocompleteProps = SelectProps &
  Styles & {
    virtualized?: boolean;
    size?: AutocompleteSize;
    SingleValueProps?: TypographyProps;
    components?: SelectComponentsConfig<SelectProps>;
  };

const AutoComplete: FunctionComponent<AutocompleteProps> = (props) => {
  const {
    className,
    classes,
    virtualized,
    filterOption,
    components = {},
    styles = {},
    ...otherProps
  } = props;
  const {root, ...otherClasses} = classes;

  const scrollbars = React.useContext(ScrollbarsContext);

  return (
    <Select
      className={classNames(root, className)}
      classes={otherClasses}
      components={{...commonComponents, ...components}}
      virtualized={virtualized}
      filterOption={filterOption || (virtualized && createFilter({ignoreAccents: false}))} // this makes all the difference!
      styles={{...defaultAutoCompleteStyles, ...styles}}
      onMenuOpen={(...args) => {
        otherProps.onMenuOpen?.(...args);
        scrollbars.setDisableVerticalScrolling(true);
      }}
      onMenuClose={(...args) => {
        otherProps.onMenuClose?.(...args);
        scrollbars.setDisableVerticalScrolling(false);
      }}
      {...otherProps}
    />
  );
};

AutoComplete.defaultProps = {
  menuPortalTarget: process.browser ? document.body : null,
  menuPlacement: 'auto',
  isMulti: false,
  label: '',
  size: AutocompleteSize.large,
  menuShouldScrollIntoView: true,
  instanceId: 'auto-complete',
  placeholder: '',
};

export const defaultAutoCompleteStyles = {
  clearIndicator: (provided, state) => ({...provided, padding: 4}),
  dropdownIndicator: (provided, state) => ({...provided, padding: 4}),
};

export default withStyles(styles)(AutoComplete);
