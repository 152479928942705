import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

const margin = '8px';
const arrowSize = '5px';
const sideOffset = '5px';

export const styles = () =>
  createStyles({
    root: {
      '&[data-tooltip]': {
        position: 'relative',
        zIndex: 2000,
      },
      '&[data-tooltip]:before, &[data-tooltip]:after': {
        pointerEvents: 'none',
        position: 'absolute',
        boxSizing: 'border-box',
      },
      '&[data-tooltip]:before': {
        content: '""',
        border: `${arrowSize} solid #000000`,
        zIndex: 100,
      },
      '&[data-tooltip]:after': {
        lineHeight: '1.4em',
        fontSize: '0.625rem',
        content: 'attr(data-tooltip)',
        textAlign: 'center',
        minWidth: '3em',
        maxWidth: '25em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '4px 8px',
        borderRadius: '4px',
        background: '#000000',
        color: '#FFFFFF',
        fontFamily: 'Roboto,"sans-serif"',
        fontWeight: 400,
        zIndex: 99,
      },

      // define y position for left and right flows (center Y)
      '&[data-tooltip][data-flow="left"]:before, &[data-tooltip][data-flow="left"]:after, &[data-tooltip][data-flow="right"]:before, &[data-tooltip][data-flow="right"]:after': {
        top: '50%',
        transform: 'translateY(-50%)',
      },

      // left arrow tooltip
      '&[data-tooltip][data-flow="left"]:before': {
        borderRightWidth: 0,
        // borderLeftColor: '#000000',
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        left: `calc(0em - ${arrowSize} - ${margin})`,
      },
      // left text tooltip
      '&[data-tooltip][data-flow="left"]:after': {
        right: `calc(100% + ${arrowSize} + ${margin})`,
      },

      // right arrow tooltip
      '&[data-tooltip][data-flow="right"]:before': {
        borderLeftWidth: 0,
        // borderRightColor: '#000000',
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        right: `calc(0em - ${arrowSize} - ${margin})`,
      },
      // right text tooltip
      '&[data-tooltip][data-flow="right"]:after': {
        left: `calc(100% + ${arrowSize} + ${margin})`,
      },

      // define y position for arrow top* flows,
      '&[data-tooltip][data-flow^="top"]:before': {
        bottom: `calc(100% + ${margin})`,
        borderBottomWidth: 0,
        // borderTopColor: '#000000',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
      },
      // define y position for tooltip top* flows,
      '&[data-tooltip][data-flow^="top"]:after': {
        bottom: `calc(100% + ${margin} + ${arrowSize})`,
      },

      // define y position for arrow bottom* flows,
      '&[data-tooltip][data-flow^="bottom"]:before': {
        top: `calc(100% + ${margin})`,
        borderTopWidth: 0,
        // borderBottomColor: '#000000',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
      },
      // define y position for tooltip bottom* flow,
      '&[data-tooltip][data-flow^="bottom"]:after': {
        top: `calc(100% + ${margin} + ${arrowSize})`,
      },

      // define x position for top and bottom flows (center X)
      [`&[data-tooltip][data-flow="top"]:before,
      &[data-tooltip][data-flow="top"]:after,
      &[data-tooltip][data-flow="bottom"]:before,
      &[data-tooltip][data-flow="bottom"]:after,
      &[data-tooltip][data-flow^="bottom"][data-center-arrow="true"]:before,
      &[data-tooltip][data-flow^="top"][data-center-arrow="true"]:before`]: {
        left: '50%',
        transform: 'translateX(-50%)',
      },

      '&[data-tooltip][data-flow$="-start"]:not([data-center-arrow]):before, &[data-tooltip][data-flow$="-start"]:after': {
        left: 0,
      },
      '&[data-tooltip][data-flow$="-start"]:not([data-center-arrow]):before': {
        transform: `translateX(${sideOffset})`,
      },

      '&[data-tooltip][data-flow$="-end"]:not([data-center-arrow]):before, &[data-tooltip][data-flow$="-end"]:after': {
        right: 0,
      },
      '&[data-tooltip][data-flow$="-end"]:not([data-center-arrow]):before': {
        transform: `translateX(-${sideOffset})`,
      },

      '&[data-tooltip=""]:after, &[data-tooltip=""]:before': {
        display: 'none',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
