import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography, {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';

import {Style, styles} from './LoadingTypography.style';

export type LoadingTypographyProps = TypographyProps &
  Style & {
    loading?: boolean;
    placeholder?: string;
  };

const LoadingTypography = ({
  classes,
  placeholder,
  className,
  loading,
  children,
  ...otherProps
}: LoadingTypographyProps) => {
  const {loading: loadingClass, hasPlaceholder, ...otherClasses} = classes;
  return (
    <Typography
      className={classNames(className, classes.root, {
        [loadingClass]: loading,
        [hasPlaceholder]: !!placeholder,
      })}
      classes={otherClasses}
      {...otherProps}
    >
      {loading ? (
        <span style={{color: 'rgba(0, 0, 0, 0)'}}>{placeholder || '\u00a0'}</span>
      ) : (
        children
      )}
    </Typography>
  );
};

export default withStyles(styles)(LoadingTypography);
