import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    button: {
      minWidth: 20,
      fontSize: 12,
    },
    disabledButton: {
      color: 'inherit !important',
    },
  });

export type Styles = WithStyles<typeof styles>;
