import React, {Component} from 'react';

import Input, {InputProps} from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import Search from '@material-ui/icons/Search';

import {styles, Styles} from './SideListSearch.style';

export type SideListSearchProps = Styles &
  Omit<InputProps, 'classes'> & {
    InputClasses?: InputProps['classes'];
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  };

class SideListSearch extends Component<SideListSearchProps> {
  static defaultProps = {
    placeholder: 'Search',
    disableUnderline: true,
  };

  render() {
    const {classes, onChange, children, InputClasses, ...otherProps} = this.props;
    return (
      <div className={classes.searchWrapper}>
        <Input
          onChange={onChange}
          fullWidth
          startAdornment={
            <InputAdornment
              classes={{
                positionStart: classes.startAdornment,
              }}
              position="start"
            >
              <Search />
            </InputAdornment>
          }
          classes={InputClasses}
          {...otherProps}
        />
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(SideListSearch);
