import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      overflow: 'hidden',
      padding: 0,
      position: 'relative',
      minHeight: '35px',
      marginBottom: '3px',
      borderRadius: '3px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
    },
    bar: {
      background: 'linear-gradient(to left, #ffde7c, #fff1c8)',
      borderRadius: '3px',
      position: 'absolute',
      margin: 0,
      top: 0,
      width: 0,
      height: '35px',
      transformOrigin: '0% 50%',
      maxWidth: '100%',
      transition: 'all 0.2s',
      '@media print': {
        background: 'linear-gradient(to left, #ffde7c, #fff1c8)',
        '-webkit-print-color-adjust': 'exact',
      },
    },
    noAnimation: {
      transformOrigin: 'none',
      transition: 'none',
    },
    itemDetails: {
      position: 'relative',
      fontSize: '12px',
      color: '#4A4A4A',
      display: 'flex',
      height: '35px',
      alignItems: 'center',
      padding: '0 10px',
    },
    itemLabel: {
      lineHeight: '20px',
      // fontSize: 12,
      flex: 1,
    },
    itemValue: {
      // fontSize: 12,
    },
  });

export type Styles = WithStyles<typeof styles>;
