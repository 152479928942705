import React, {FC, MouseEvent, useState} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Period} from '../../../../models/Period';
import SettingsPopper, {SettingsPopperProps} from '../../common/SettingsPopper';
import Content from './Content';
import {Styles, styles} from './SettingsPopper.style';

interface Props
  extends Pick<SettingsPopperProps, 'open' | 'anchorEl' | 'onClose' | 'placement' | 'title'>,
    Styles {
  campaign: Period;
  minimumNights?: number;
  openDirection?: 'up' | 'down';
  onChange: (changes: Period) => void;
}

const Presentational: FC<Props> = (props) => {
  const {
    open,
    anchorEl,
    campaign,
    minimumNights,
    placement,
    title,
    openDirection,
    onChange,
    onClose,
  } = props;

  const [period, setPeriod] = useState(campaign);
  const {startDate, endDate} = period || ({} as typeof period);

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    onChange({...period});
    onClose(event);
  };

  return (
    <SettingsPopper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      title={title}
      disabled={!startDate || !endDate}
      onSubmit={handleSubmit}
      onClose={onClose}
    >
      <Content
        startDate={startDate}
        endDate={endDate}
        onDatesChange={setPeriod}
        minimumNights={minimumNights}
        openDirection={openDirection}
      />
    </SettingsPopper>
  );
};
export default withStyles(styles)(Presentational);
