import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      backgroundImage: 'linear-gradient(to bottom right, #FFF8BC, #FFEAC2)',
      margin: '0 15px',
      height: 'fit-content',
      width: 220,
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
