import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 40,
      height: 53,
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      paddingLeft: 10,
    },
    chipsContainer: {
      flex: 1,
      height: '100%',
    },
    scrollContent: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      paddingLeft: 98,
    },
    noAddFilterButton: {
      paddingLeft: 0,
      '& > div:first-child': {
        marginLeft: -5,
      },
    },
    lastItemPadding: {
      '& > div:last-child': {
        paddingRight: 5,
      },
    },
    applyButton: {
      color: theme.palette.background.paper,
      borderColor: theme.palette.primary.main,
      paddingLeft: 3,
      width: 24,
      height: 24,
      margin: 4,
    },
    disableApply: {
      color: theme.palette.action.disabled,
      borderColor: theme.palette.action.disabledBackground,
      '& svg': {
        color: theme.palette.action.disabled,
      },
    },
    applyButtonIcon: {
      color: theme.palette.primary.main,
      fontSize: 18,
      marginLeft: 14,
    },
    applyButtonContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },
    applyButtonContainerWithBorder: {
      borderLeft: 'solid 1px #E6E6E6',
      boxSizing: 'border-box',
    },
    cancelButton: {
      color: 'rgba(0, 0, 0, 0.26)',
      borderColor: 'rgba(0, 0, 0, 0.26)',
      paddingLeft: 3,
      width: 24,
      height: 24,
      margin: 4,
    },
    cancelButtonIcon: {
      color: 'rgba(0, 0, 0, 0.26)',
      fontSize: 18,
      marginLeft: 13,
    },
  });

export type Styles = WithStyles<typeof styles>;
