import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {toUSLocaleCurrency} from '../../../utils/number';
import {BudgetBar} from '../../BudgetBar';
import Content from '../common/Content';
import Widget, {Props as WidgetProps} from '../common/Widget';
import SettingsPopper from './SettingsPopper';
import {Styles, styles} from './TargetBudget.style';

export type TargetBudgetValue = {
  onPeriod: number;
  maxValue: number;
};

interface Props
  extends Styles,
    Pick<
      WidgetProps,
      | 'renderBeforeSettingsMenuItem'
      | 'renderAfterSettingsMenuItem'
      | 'renderBeforeDeleteMenuItem'
      | 'renderAfterDeleteMenuItem'
    > {
  budget: TargetBudgetValue;
  hideOptions?: boolean;
  onChange: (changes: TargetBudgetValue) => void;
  onDelete: () => void;
}

const Presentational: FC<Props> = (props) => {
  const {classes, budget, hideOptions, onChange, onDelete} = props;
  const {
    renderBeforeSettingsMenuItem,
    renderAfterSettingsMenuItem,
    renderBeforeDeleteMenuItem,
    renderAfterDeleteMenuItem,
  } = props;
  const {onPeriod, maxValue} = budget;

  return (
    <Widget
      classes={{root: classes.root}}
      hideOptions={hideOptions}
      title="Target Budget"
      renderBeforeSettingsMenuItem={renderBeforeSettingsMenuItem}
      renderAfterSettingsMenuItem={renderAfterSettingsMenuItem}
      renderBeforeDeleteMenuItem={renderBeforeDeleteMenuItem}
      renderAfterDeleteMenuItem={renderAfterDeleteMenuItem}
      onDelete={onDelete}
      settingsPopper={({anchorEl, openSettings, onUpdateState}) =>
        openSettings && (
          <SettingsPopper
            open={true}
            budget={budget}
            anchorEl={anchorEl}
            onChange={onChange}
            onClose={() => onUpdateState({anchorEl: null, openMenu: false, openSettings: false})}
          />
        )
      }
    >
      <Content>
        <div className={classes.info}>
          <Typography className={classes.current}>{`Current: ${toUSLocaleCurrency(
            onPeriod,
            0,
          )}`}</Typography>
          <Typography className={classes.total}>{toUSLocaleCurrency(maxValue, 0)}</Typography>
        </div>
        <BudgetBar value={onPeriod} budget={maxValue} />
      </Content>
    </Widget>
  );
};

export default withStyles(styles)(Presentational);
