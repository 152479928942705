import React, {FunctionComponent, HTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {styles, Styles} from './Footer.style';

export type PopperFooterProps = Styles & HTMLAttributes<HTMLDivElement>;

const Footer: FunctionComponent<PopperFooterProps> = (props) => {
  const {classes, children, ...otherProps} = props;

  return (
    <div className={classes.root} {...otherProps}>
      {children}
    </div>
  );
};

export default withStyles(styles)(Footer);
