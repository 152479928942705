import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './Text.style';

interface Props extends Styles {}

const DatagridCardViewText: React.FunctionComponent<Props> = (props) => {
  const {children, classes} = props;
  return <div className={classes.cardText}>{children}</div>;
};

export default withStyles(styles)(DatagridCardViewText);
