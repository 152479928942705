import React, {FunctionComponent} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';

import {CssTooltip, CssTooltipProps} from '../CssTooltip';
import {styles, Styles} from './Button.style';

export type ListItemButtonProps = Styles & {
  label?: string;
  Icon: React.ComponentType<any>;
  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  tooltip?: string;
  tooltipPlacement?: CssTooltipProps['placement'];
  tooltipCenterArrow?: CssTooltipProps['centerArrow'];
};

const Item: FunctionComponent<ListItemButtonProps> = (props) => {
  const {classes, tooltip, tooltipPlacement, tooltipCenterArrow, Icon, label, onClick} = props;

  return (
    <CssTooltip centerArrow={tooltipCenterArrow} title={tooltip} placement={tooltipPlacement}>
      <IconButton
        color="primary"
        classes={{root: classes.button}}
        aria-label={label}
        onClick={onClick}
      >
        <Icon classes={{root: classes.icon}} />
      </IconButton>
    </CssTooltip>
  );
};

Item.defaultProps = {
  tooltipPlacement: 'bottom',
};

export default withStyles(styles)(Item);
