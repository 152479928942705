import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {BaseFilter, BaseProps} from '../../BaseFilter';
import {MultipleCheckFilterPopper} from '../MultipleCheckFilter';
import Chip from './Chip';

export enum SharedOptions {
  global = 'global',
  shared = 'shared',
}

type Props = BaseProps<SharedOptions[]> & {
  options: SharedOptions[];
};
const FILTER_ID = 'shared' as const;

export class SharedFilter extends BaseFilter<Props, SharedOptions[]> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Shared',
    description: 'Filter by shared and global',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: SharedFilter.defaultFilterAddOption,
  } as Partial<Props>;

  render() {
    const {value = [], context, isOpen: open, tempValue, initialValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;
    const hasMultipleOptions = (this.props?.options || []).length > 1;

    return (
      <div>
        {!isEmpty(value) && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {open && hasMultipleOptions && (
          <MultipleCheckFilterPopper
            headerTitle={this.props.filterAddOption.title}
            options={this.props.options}
            open={open}
            anchorEl={anchorEl}
            value={value}
            tempValue={tempValue}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  }
}
