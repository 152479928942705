import React, {FunctionComponent} from 'react';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {styles} from './styles';

export interface ListItemProps extends WithStyles<typeof styles> {}

const ListItem: FunctionComponent<ListItemProps> = (props) => {
  const {classes, children} = props;

  return (
    <Typography variant="body2" component="div" className={classes.root}>
      {children}
    </Typography>
  );
};

export default withStyles(styles)(ListItem);
