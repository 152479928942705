import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import moment, {Moment} from 'moment';
import {isInclusivelyAfterDay} from 'react-dates';

import {PartialBy} from '../../../../utils/types';
import {style} from '../../../DateRangePicker/DateRangePicker.style';
import {DateRangePickerFormat, DateRangePickerFormatProps} from '../../../DateRangePickerFormat';
import {PopperContentSection} from '../../../Popper';

const disablePastDates = (day: Moment) => !isInclusivelyAfterDay(day, moment());
type Props = PartialBy<DateRangePickerFormatProps, 'format'>;

const Presentational: FC<Props> = (props) => {
  return (
    <PopperContentSection>
      <DateRangePickerFormat {...props} />
    </PopperContentSection>
  );
};

Presentational.defaultProps = {
  format: 'YYYY-MM-DD',
  label: 'Date Range',
  appendToBody: false,
  fullWidth: true,
  required: true,
  minimumNights: 27,
  isOutsideRange: disablePastDates,
};

export default withStyles(style)(Presentational);
