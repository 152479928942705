import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography, {TypographyProps} from '@material-ui/core/Typography';

import {styles} from './Row.style';

export interface DisclaimerRowProps extends TypographyProps {}

const Row: FunctionComponent<DisclaimerRowProps> = (props) => {
  return <Typography {...props} />;
};

Row.defaultProps = {
  gutterBottom: true,
};

export default withStyles(styles)(Row);
