export function onElementHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight, newHeight;

  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight != newHeight) {
      callback(newHeight)
    }
    lastHeight = newHeight

    if (elm.onElementHeightChangeTimer) {
      clearTimeout(elm.onElementHeightChangeTimer)
    }

    elm.onElementHeightChangeTimer = setTimeout(run, 50)
  })()
}