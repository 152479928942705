import React, {HTMLAttributes, SFC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import {Styles, styles} from './TileInfo.style';

export type TileInfoProps = Styles &
  HTMLAttributes<HTMLDivElement> & {
    tileTitle: React.ReactNode;
  };

const Presentational: SFC<TileInfoProps> = ({
  classes,
  className,
  tileTitle,
  children,
  ...divProps
}) => {
  return (
    <div className={classNames(classes.root, className)} {...divProps}>
      <Typography className={classes.title}>{tileTitle}</Typography>
      {children && <Typography className={classes.content}>{children}</Typography>}
    </div>
  );
};

Presentational.defaultProps = {};

export default withStyles(styles)(Presentational);
