import React, {FunctionComponent} from 'react';

import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Help from '@material-ui/icons/Help';

import {CssTooltip, CssTooltipProps} from '../CssTooltip';
import {styles, Styles} from './Help.style';

export interface HelpProps extends Styles {
  onClick: IconButtonProps['onClick'];
  tooltipCenterArrow?: CssTooltipProps['centerArrow'];
  tooltipPlacement?: CssTooltipProps['placement'];
  tooltip?: string;
  hideTooltip?: boolean;
}

const Presentational: FunctionComponent<HelpProps> = ({
  classes,
  tooltip,
  hideTooltip,
  tooltipCenterArrow = true,
  tooltipPlacement = 'bottom',
  onClick,
}) => (
  <CssTooltip
    centerArrow={tooltipCenterArrow}
    title={hideTooltip ? '' : tooltip}
    placement={tooltipPlacement}
  >
    <IconButton
      classes={{root: classes.root}}
      color="primary"
      aria-label={tooltip}
      onClick={onClick}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <Help color="primary" />
    </IconButton>
  </CssTooltip>
);

Presentational.defaultProps = {
  tooltip: 'Help',
};

export default withStyles(styles)(Presentational);
