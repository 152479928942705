import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 1rem',
      borderLeft: '0.4rem solid transparent',
      minHeight: '54px',
      '&:hover, &:focus': {
        borderColor: 'rgba(81, 30, 113, 0.54)',
        backgroundColor: 'initial',
      },
    },
    active: {
      borderColor: 'rgba(81, 30, 113, 0.54)',
      backgroundColor: 'rgba(225, 54, 255, 0.14)',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(225, 54, 255, 0.14)',
      },
    },
    text: {
      color: '#eee',
      fontSize: '0.7rem',
      letterSpacing: '0.09rem',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
  });

export type Styles = WithStyles<typeof styles>;
