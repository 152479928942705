import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: 54,
      left: 0,
      height: 'calc(100vh - 54px)',
      width: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        left: 5,
        width: 'calc(100% - 5px)',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
