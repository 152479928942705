import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    listItem: {
      borderBottom: '1px solid #E6E6E6',
      borderRight: '4px solid transparent',
    },
    active: {
      borderRightColor: '#8007D4',
      backgroundColor: '#F5F5F5',
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
