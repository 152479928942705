import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit,
    },
    rootWithCounter: {
      marginBottom: 0,
    },
    listItems: {},
    rootWithValues: {},
    listLabel: {
      padding: `${theme.spacing.unit}px 15px`,
      color: 'rgba(0, 0, 0, 0.6)',
    },
    selectRoot: {
      margin: '0px 15px',
    },
    selectWithValues: {
      marginTop: theme.spacing.unit * 2,
    },
    counterRoot: {},
  });

export type Styles = WithStyles<typeof styles>;
