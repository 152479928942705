import React from 'react';

import ListItemText from '@material-ui/core/ListItemText';
import _capitalize from 'lodash/capitalize';

import Option from './Option';

const OptionWithSubtitle = (props) => {
  const {selectProps} = props;
  const {classes, size} = selectProps;
  return (
    <Option {...props}>
      <ListItemText
        primary={props.children}
        secondary={props.data.subtitle}
        classes={{
          primary: classes[`primaryOptionText${_capitalize(size)}`],
          secondary: classes[`secondaryOptionText${_capitalize(size)}`],
        }}
      />
    </Option>
  );
};

Option.muiName = 'Option';

export default OptionWithSubtitle;
