import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    autocomplete: {
      flex: 1,
      '&:first-child': {
        paddingRight: 5,
      },
      '&:last-child': {
        paddingLeft: 5,
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
