import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {Period} from '../../models/Period';
import {DateRangePicker, DateRangePickerProps, FocusedInput} from '../DateRangePicker';
import {style} from '../DateRangePicker/DateRangePicker.style';

export type DateRangePickerFormatProps = Omit<
  DateRangePickerProps,
  'startDate' | 'endDate' | 'onDatesChange'
> & {
  startDate: string;
  endDate: string;
  format: string;
  onDatesChange: (dates: Period) => void;
};

interface State {
  focusedInput: FocusedInput;
}

class Picker extends Component<DateRangePickerFormatProps, State> {
  static defaultProps: Partial<DateRangePickerFormatProps> = {
    format: 'YYYY-MM-DD',
  };

  constructor(props: DateRangePickerFormatProps) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }
  handleSelectedDateChange = (newDates: Period) => {
    const {startDate, endDate} = newDates;
    let formattedStartDate = '';
    let formattedEndDate = '';

    const {format} = this.props;

    if (startDate) {
      formattedStartDate = moment(startDate).format(format);
    }

    const focusedInput =
      this.props.focusedInput !== undefined ? this.props.focusedInput : this.state.focusedInput;

    if (focusedInput === 'endDate' && endDate) {
      formattedEndDate = moment(endDate).format(format);
    }

    this.props.onDatesChange({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  onFocusChange = (focusedInput: FocusedInput) => {
    this.setState({focusedInput});
  };

  render() {
    const {focusedInput, onFocusChange, ...others} = this.props;
    const {focusedInput: focusedInputState} = this.state;

    return (
      <DateRangePicker
        {...others}
        onDatesChange={this.handleSelectedDateChange}
        onFocusChange={onFocusChange !== undefined ? onFocusChange : this.onFocusChange}
        focusedInput={focusedInput !== undefined ? focusedInput : focusedInputState}
      />
    );
  }
}

export default withStyles(style)(Picker);
