import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowDown';
import {components} from 'react-select';

import {AdornmentIcon} from '../../TextField';
import {getIndicatorProps} from './indicator-utils';

const DropdownIndicator = (props) => {
  const indicatorProps = getIndicatorProps(props, 'dropdownIndicator');
  return (
    <components.DropdownIndicator {...indicatorProps}>
      <AdornmentIcon Icon={ExpandMoreIcon} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
