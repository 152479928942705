import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Autocomplete, AutocompleteProps, AutocompleteSize} from '../Autocomplete';
import {RemovableList, RemovableListProps} from '../RemovableList';
import {Styles, styles} from './MultiSelectList.style';

export type MultiSelectListProps = Styles &
  Omit<AutocompleteProps, 'classes' | 'value' | 'isDisabled'> & {
    SelectComponent?: React.ComponentType<
      Omit<AutocompleteProps, 'classes' | 'value' | 'isDisabled'>
    >;
    SelectClasses?: Partial<AutocompleteProps['classes']>;
    ItemClasses?: RemovableListProps['ItemClasses'];
    RemovableListClasses?: RemovableListProps['classes'];
    values: RemovableListProps['items'];
    onChange?: RemovableListProps['onChange'];
    onClickItem?: RemovableListProps['onClickItem'];
    disabled?: RemovableListProps['disabled'];
    totalMessage?: RemovableListProps['totalMessage'];
    showTotalMessageFrom?: RemovableListProps['showTotalMessageFrom'];
    clearAllMessage?: RemovableListProps['clearAllMessage'];
    listLabel?: RemovableListProps['listLabel'];
    children?: RemovableListProps['children'];
    virtualized?: RemovableListProps['virtualized'];
  };

const handleSelectValue = (
  callback: MultiSelectListProps['onChange'],
  values: MultiSelectListProps['values'],
) => (op: RemovableListProps['items'][number]) => {
  callback([...(values || []), op]);
};

const getOptions = (
  options: RemovableListProps['items'],
  values: MultiSelectListProps['values'],
) => {
  if (!values || values.length) {
    return options;
  }
  return options.filter((op) => !values.some((v) => v.value === op.value));
};

const MultiSelectList: FunctionComponent<MultiSelectListProps> = (props) => {
  const {
    classes,
    values = [],
    options = [],
    totalMessage,
    showTotalMessageFrom,
    clearAllMessage,
    onClickItem,
    onChange,
    ItemClasses,
    RemovableListClasses,
    SelectComponent,
    children,
    listLabel,
    SelectClasses,
    virtualized,
    disabled,
    'data-testid': dataTestId,
    ...selectProps
  } = props;
  const hasValues = values && !!values.length;
  return (
    <div
      className={classNames(classes.root, {
        [classes.rootWithValues]: hasValues,
        [classes.rootHasCounter]: hasValues && values.length >= showTotalMessageFrom,
      })}
    >
      <SelectComponent
        {...selectProps}
        classes={{
          root: classNames(classes.selectRoot, {
            [classes.selectWithValues]: hasValues,
          }),
          ...SelectClasses,
        }}
        value={null}
        isDisabled={disabled}
        options={getOptions(options, values)}
        onChange={handleSelectValue(onChange, values)}
      />
      <RemovableList
        data-testid={dataTestId}
        disabled={disabled}
        classes={RemovableListClasses}
        virtualized={virtualized}
        ItemClasses={ItemClasses}
        onClickItem={onClickItem}
        onChange={onChange}
        items={values}
        totalMessage={totalMessage}
        showTotalMessageFrom={showTotalMessageFrom}
        clearAllMessage={clearAllMessage}
        listLabel={listLabel}
        children={children}
      />
    </div>
  );
};

MultiSelectList.defaultProps = {
  size: AutocompleteSize.small,
  SelectComponent: Autocomplete,
  totalMessage: 'total items',
  showTotalMessageFrom: 7,
  clearAllMessage: 'Clear List',
};

export default withStyles(styles)(MultiSelectList);
