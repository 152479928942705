import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {ColumnHeaderProperty} from '../../../../Datagrid/Datagrid.model';

import {DatagridTableViewColumn} from '../../../../Datagrid/ViewModes/Table/Column';
import {DatagridTableViewRow} from '../../../../Datagrid/ViewModes/Table/Row';
import {range} from '../../../../../utils/array';

import {Styles, styles} from './Placeholder.style';

interface Props extends Styles {
  rows: number;
  properties: ColumnHeaderProperty[];
}

const DatagridTableViewPlaceholder: FunctionComponent<Props> = ({classes, rows, properties}) => (
  <div className={classes.root}>
    {range(rows).map((i, index) => (
      <DatagridTableViewRow key={`DatagridTableViewRow-${i}-${index}`}>
        {properties.map((property, index) => (
          <DatagridTableViewColumn
            key={`DatagridTableViewColumn-${i}-${index}`}
            classes={{root: property.columnRootClass}}
          />
        ))}
      </DatagridTableViewRow>
    ))}
  </div>
);
export default withStyles(styles)(DatagridTableViewPlaceholder);
