import React, {ChangeEvent, MouseEvent} from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles from '@material-ui/core/styles/withStyles';

import {ActionPopper, ActionPopperProps} from '../../ActionPopper';
import {Autocomplete, AutocompleteSize} from '../../Autocomplete';
import {PopperContent, PopperContentSection} from '../../Popper';

import {Styles, styles} from './SortPopover.style';

export interface SortProperty {
  sortKey?: string;
  name: string;
}

enum Order {
  asc = 'asc',
  desc = 'desc',
}

export type SortPopoverProps = Styles &
  Pick<ActionPopperProps, 'open' | 'anchorEl' | 'onClose'> & {
    sortProperty: string;
    isDescending: boolean;
    onChange: (sortProperty: string, isDescending?: boolean) => void;
    properties: {label: string; value: string}[];
    autoFocus?: boolean;
    onSelectOpen?: () => void;
    onSelectClose?: () => void;
  };

interface State {
  sortProperty: string;
  isDescending: boolean;
}

class SortPopover extends React.Component<SortPopoverProps, State> {
  constructor(props: SortPopoverProps) {
    super(props);
    this.state = {
      sortProperty: props.sortProperty,
      isDescending: props.isDescending,
    };
  }

  componentWillReceiveProps(nextProps: SortPopoverProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({
        sortProperty: nextProps.sortProperty,
        isDescending: nextProps.isDescending,
      });
    }
  }

  handleApply = (evt: ChangeEvent<{}> | MouseEvent<HTMLElement>) => {
    const {sortProperty, isDescending} = this.state;
    this.props.onChange(sortProperty, sortProperty ? isDescending : null);
    this.props.onClose(evt, 'click');
  };

  handleSortDirection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const order = e.target.value;
    const isDescending = order === Order.desc;

    this.setState({isDescending});
  };

  handleChangeSortProperty = (sortProperty: {label: string; value: string}) => {
    this.setState({sortProperty: sortProperty.value});
  };

  getValue = () => {
    const {properties} = this.props;
    const {sortProperty} = this.state;

    const foundValue = properties.find((o) => o.value === sortProperty);
    return foundValue ? foundValue : null;
  };

  render() {
    const {
      anchorEl,
      open,
      onClose,
      properties,
      onSelectOpen,
      onSelectClose,
      autoFocus,
    } = this.props;

    return (
      <ActionPopper
        headerTitle="Sort by"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        onSubmit={this.handleApply}
      >
        <PopperContent>
          <PopperContentSection>
            <Autocomplete
              autoFocus={autoFocus}
              value={[this.getValue()]}
              options={properties}
              onChange={this.handleChangeSortProperty}
              size={AutocompleteSize.small}
              onMenuOpen={onSelectOpen}
              onMenuClose={onSelectClose}
            />
          </PopperContentSection>

          <PopperContentSection>{this.renderRadioGroup()}</PopperContentSection>
        </PopperContent>
      </ActionPopper>
    );
  }

  renderRadioGroup = () => {
    const {classes} = this.props;
    const {sortProperty, isDescending} = this.state;

    const order = isDescending ? Order.desc : Order.asc;

    return (
      <RadioGroup
        aria-label="gender"
        name="order"
        value={sortProperty ? order : ''}
        onChange={this.handleSortDirection}
        classes={{root: classes.orderContainer}}
      >
        <FormControlLabel
          value={Order.asc}
          control={<Radio color="primary" />}
          label="Asc"
          labelPlacement="end"
          disabled={!sortProperty}
          classes={{root: classes.orderControlLabelRoot, label: classes.orderControlLabel}}
        />
        <FormControlLabel
          value={Order.desc}
          control={<Radio color="primary" />}
          label="Desc"
          labelPlacement="end"
          disabled={!sortProperty}
          classes={{root: classes.orderControlLabelRoot, label: classes.orderControlLabel}}
        />
      </RadioGroup>
    );
  };
}

export default withStyles(styles)(SortPopover);
