import React, {ChangeEvent, FunctionComponent, KeyboardEvent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {returnMessageByOs} from '../../utils/os';
import {Omit} from '../../utils/types';
import {Popper, PopperPaper, PopperProps} from '../Popper';
import {styles, Styles} from './ActionPopper.style';
import Footer, {ActionPopperFooterProps} from './Footer';
import Header, {ActionPopperHeaderProps} from './Header';

export type onCloseType = 'away' | 'click';

export type ActionPopperProps = Styles &
  Omit<PopperProps, 'onSubmit'> & {
    headerTitle: ActionPopperHeaderProps['title'];
    appendToHeader?: ActionPopperHeaderProps['appendTo'];
    showCloseButton?: ActionPopperHeaderProps['showCloseButton'];
    submitTooltip?: ActionPopperFooterProps['submitTooltip'];
    submitTooltipPlacement?: ActionPopperFooterProps['submitTooltipPlacement'];
    disabled?: ActionPopperFooterProps['disabled'];
    onSubmitLabel?: ActionPopperFooterProps['onSubmitLabel'];
    onCancelLabel?: ActionPopperFooterProps['onCancelLabel'];
    onCancel?: ActionPopperFooterProps['onCancel'];
    onSubmit?: ActionPopperFooterProps['onSubmit'];
    onHelpClick?: ActionPopperFooterProps['onHelpClick'];
    onClose: (evt: ChangeEvent<{}> | React.MouseEvent<HTMLElement>, type?: onCloseType) => void;
    showSpinner?: ActionPopperFooterProps['showSpinner'];
  };

const handleExit = (type: onCloseType, callback: ActionPopperProps['onClose']) => (
  evt: ChangeEvent<{}> | React.MouseEvent<HTMLElement>,
) => {
  callback(evt, type);
};

const onKeyDownHandler = (
  onClose: ActionPopperProps['onClose'],
  onSubmit: ActionPopperProps['onSubmit'],
  disabled: ActionPopperProps['disabled'],
) => (event: KeyboardEvent<HTMLDivElement>) => {
  const {ctrlKey, metaKey, key} = event;
  if (key === 'Escape') {
    onClose(event, 'click');
  } else if (key === 'Enter' && (ctrlKey || metaKey)) {
    if (onSubmit && !disabled) {
      onSubmit((event as unknown) as React.MouseEvent<HTMLDivElement>);
    }
  }
};

const ActionPopper: FunctionComponent<ActionPopperProps> = ({
  classes,
  showCloseButton,
  headerTitle,
  appendToHeader,
  children,
  onHelpClick,
  onSubmit,
  disabled,
  submitTooltip,
  submitTooltipPlacement,
  onSubmitLabel,
  onClose,
  onCancel,
  onCancelLabel,
  showSpinner,
  ...otherProps
}) => {
  return (
    <Popper
      classes={{root: classes.root}}
      onClickAway={handleExit('away', onClose)}
      onKeyDown={onKeyDownHandler(onClose, onSubmit, disabled)}
      {...otherProps}
    >
      <PopperPaper classes={{root: classes.paper}}>
        {(!!headerTitle || showCloseButton || !!appendToHeader) && (
          <Header
            classes={classes}
            showCloseButton={showCloseButton}
            title={headerTitle}
            appendTo={appendToHeader}
            onClose={onClose}
          />
        )}
        {children}
        {(onHelpClick || onSubmit) && (
          <Footer
            submitTooltip={submitTooltip}
            submitTooltipPlacement={submitTooltipPlacement}
            classes={classes}
            disabled={disabled}
            onSubmitLabel={onSubmitLabel}
            onHelpClick={onHelpClick}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onCancelLabel={onCancelLabel}
            showSpinner={showSpinner}
          />
        )}
      </PopperPaper>
    </Popper>
  );
};

ActionPopper.defaultProps = {
  onSubmitLabel: 'Apply',
  onCancelLabel: 'Cancel',
  submitTooltip: returnMessageByOs,
  submitTooltipPlacement: 'bottom',
  showSpinner: false,
};

export default withStyles(styles)(ActionPopper);
