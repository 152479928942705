import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {Option} from 'react-select/lib/filters';

import {Autocomplete, AutocompleteSize} from '../Autocomplete';
import {toOption, toOptions} from '../../utils/array';

import {styles, Styles} from './NumberRange.style';

export type NumberRangeProps = Styles & {
  label: string;
  className?: string;
  options: number[];
  start?: number;
  end?: number;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onStartChange?: (start: number) => void;
  onEndChange?: (end: number) => void;
};

const handleChange = (callback: NumberRangeProps['onStartChange' | 'onEndChange']) => (
  op: Option,
) => {
  if (callback) {
    callback(Number(op.value));
  }
};

const NumberRange: FunctionComponent<NumberRangeProps> = (props) => {
  const {
    classes,
    start,
    end,
    className,
    label,
    options,
    onMenuOpen,
    onMenuClose,
    onStartChange,
    onEndChange,
  } = props;
  const autocompleteOptions = toOptions(options);

  return (
    <div className={`${className} ${classes.root}`}>
      <Autocomplete
        classes={{root: classes.autocomplete}}
        label={label}
        size={AutocompleteSize.small}
        options={autocompleteOptions}
        value={toOption(start)}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onChange={handleChange(onStartChange)}
      />
      <Autocomplete
        classes={{root: classes.autocomplete}}
        size={AutocompleteSize.small}
        options={autocompleteOptions}
        value={toOption(end)}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onChange={handleChange(onEndChange)}
      />
    </div>
  );
};

export default withStyles(styles)(NumberRange);
