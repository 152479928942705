const getPrefix = (domain: string) => {
  if (window.location.hostname === 'localhost') {
    return 'stg.';
  }

  const prefix = window.location.hostname.replace(`.${domain}`, '');
  const split = prefix.split('.');

  return split.length > 1 ? `stg.` : '';
};

const getDomain = () => {
  if (window.location.hostname === 'localhost') {
    return 'onsmart.dev';
  }
  const regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}:?[0-9]{0,5}$');
  return regexParse.exec(window.location.hostname)[0];
};

export enum ServiceApp {
  dmp = 'dmp',
  mad = 'mad',
  admin = 'admin',
  geofence = 'geofence',
}

const generateLink = (app: ServiceApp) => {
  const domain = getDomain();
  const prefix = getPrefix(domain);
  const protocol = window.location.protocol;

  return `${protocol}//${prefix}${app}.${domain}`;
};

const onAppClick = (appName: ServiceApp) => (evt: React.MouseEvent<HTMLElement>) => {
  const url = generateLink(appName);
  window.open(url, '_blank');
};

enum MenuService {
  scout = 'smartSCOUT',
  mad = 'MAD Curation Center',
  admin = 'Admin Console',
  geofence = 'Geofence',
}

export const labels: {[app in ServiceApp]: MenuService} = {
  [ServiceApp.dmp]: MenuService.scout,
  [ServiceApp.mad]: MenuService.mad,
  [ServiceApp.admin]: MenuService.admin,
  [ServiceApp.geofence]: MenuService.geofence,
};

export type ServiceOption = {
  label: MenuService;
  onClick: (evt: React.MouseEvent<HTMLElement>) => void;
};

const options: {[key in ServiceApp]: ServiceOption} = {
  [ServiceApp.dmp]: {label: MenuService.scout, onClick: onAppClick(ServiceApp.dmp)},
  [ServiceApp.mad]: {label: MenuService.mad, onClick: onAppClick(ServiceApp.mad)},
  [ServiceApp.admin]: {label: MenuService.admin, onClick: onAppClick(ServiceApp.admin)},
  [ServiceApp.geofence]: {
    label: MenuService.geofence,
    onClick: onAppClick(ServiceApp.geofence),
  },
};

export const getOptionsFromApps = (apps: ServiceApp[]): ServiceOption[] =>
  apps.map((app) => options[app]);
