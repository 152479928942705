import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    tooltip: {
      position: 'relative',
      backgroundColor: 'rgb(0, 0, 0);',
      '-webkit-font-smoothing': 'subpixel-antialiased',
    },
    popper: {
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.81em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%',
          color: '#000',
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.81em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0',
          color: '#000',
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.71em',
        height: '1.15em',
        width: '0.81em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%',
          color: '#000',
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.71em',
        height: '1.15em',
        width: '0.81em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0',
          color: '#000',
        },
      },
    },
    arrow: {
      overflow: 'hidden',
      position: 'absolute',
      width: '1.15em',
      height: '0.81em' /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: 'border-box',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
      },
    },
    tooltipPopper: {},
    popperInteractive: {},
    touch: {},
    tooltipPlacementLeft: {},
    tooltipPlacementRight: {},
    tooltipPlacementTop: {},
    tooltipPlacementBottom: {},
  });
