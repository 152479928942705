import React, {FunctionComponent} from 'react';

import {InputBaseComponentProps} from '@material-ui/core/InputBase';
import {TextFieldProps} from '@material-ui/core/TextField';
import NumberFormat, {NumberFormatProps, NumberFormatValues} from 'react-number-format';

const NumberFormatCustom: FunctionComponent<
  InputBaseComponentProps & NumberFormatProps & Pick<TextFieldProps, 'inputRef'>
> = (props) => {
  const {inputRef, onChange, ...other} = props;
  const onValueChange = React.useCallback(
    (values: NumberFormatValues) => {
      if (onChange && props.value !== values.floatValue) {
        onChange({
          target: {
            value: values.value,
          },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    },
    [onChange, props.value],
  );

  return <NumberFormat {...other} getInputRef={inputRef} onValueChange={onValueChange} />;
};

export default NumberFormatCustom;
