import React from 'react';

import Dialog, {DialogProps} from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';

import Footer from '../ActionPopper/Footer';
import Header from '../ActionPopper/Header';
import {FilterActionPopperProps} from '../FilterActionPopper';
import {Scrollbars, ScrollbarsProps, ScrollbarsType} from '../Scrollbars';
import {styles, Styles} from './CustomDialog.style';

export type CustomDialogProps = Styles &
  Omit<FilterActionPopperProps, 'maxHeight'> & {
    DialogProps: Omit<DialogProps, 'open' | 'onClose'>;
    ScrollProps?: ScrollbarsProps;
    open: boolean;
    alwaysBorderBottom?: boolean;
    footerContent?: React.ReactNode;
  };

export class CustomDialogClass extends React.Component<CustomDialogProps> {
  scrollbars: ScrollbarsType;

  static defaultProps: Partial<CustomDialogProps> = {
    footerBorderOnMaxHeight: true,
    alwaysBorderBottom: false,
    onSubmitLabel: 'Apply',
    onCancelLabel: 'Cancel',
    submitTooltip: null,
    submitTooltipPlacement: 'bottom',
    ScrollProps: {
      autoHeight: true,
      autoHeightMax: '60vh',
    },
  };

  getScrollHeight = () => {
    let clientHeight = 0;
    let scrollHeight = 0;
    if (this.scrollbars) {
      clientHeight = this.scrollbars.getClientHeight();
      scrollHeight = this.scrollbars.getScrollHeight();
    }

    return clientHeight < scrollHeight;
  };

  getFooterBorder = () => {
    const {classes, alwaysBorderBottom, footerBorderOnMaxHeight} = this.props;

    return alwaysBorderBottom
      ? classes.footerBorder
      : footerBorderOnMaxHeight
      ? this.getScrollHeight()
        ? classes.footerBorder
        : null
      : classes.footerRoot;
  };

  render() {
    const {
      classes,
      headerTitle,
      appendToHeader,
      showCloseButton,
      onHelpClick,
      submitTooltip,
      submitTooltipPlacement,
      DialogProps,
    } = this.props;
    const {onSubmit, disabled, onSubmitLabel, footerContent} = this.props;
    const {onCancel, onCancelLabel, children, open, ScrollProps, onClose} = this.props;

    const footerRootClass = this.getFooterBorder();

    return (
      <Dialog onClose={onClose} open={open} classes={{paper: classes.dialogPaper}} {...DialogProps}>
        <Header
          classes={{
            headerRoot: classes.headerRoot,
            headerText: classes.headerText,
            headerButton: classes.headerButton,
          }}
          showCloseButton={showCloseButton}
          title={headerTitle}
          appendTo={appendToHeader}
          onClose={onClose}
        />
        <Scrollbars
          innerRef={(instance) => (this.scrollbars = instance)}
          {...CustomDialogClass.defaultProps.ScrollProps}
          {...ScrollProps}
        >
          <div className={classes.dialogContent}>{children}</div>
        </Scrollbars>
        <DialogActions className={footerRootClass} classes={{root: classes.dialogActions}}>
          {(onHelpClick || onCancel || onSubmit || footerContent) && (
            <Footer
              disabled={disabled}
              submitTooltipPlacement={submitTooltipPlacement}
              submitTooltip={submitTooltip}
              onSubmitLabel={onSubmitLabel}
              onHelpClick={onHelpClick}
              onSubmit={onSubmit}
              onCancel={onCancel}
              onCancelLabel={onCancelLabel}
            >
              {footerContent}
            </Footer>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CustomDialogClass);
