import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {
      //TODO: remove !important when bkn-ui is not more in use.
      color: '#8007d4 !important',
      textDecoration: 'none',
      fontFamily: 'Roboto,"sans-serif"',
      cursor: 'pointer',

      '&a:not([href]):not([tabindex])': {
        color: '#8007d4',
        outline: 1,
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
