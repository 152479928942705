import React from 'react';

import Typography from '@material-ui/core/Typography';
import _capitalize from 'lodash/capitalize';

const NoOptionsMessage = (props) => {
  const {innerProps, children, selectProps} = props;
  const {classes, size, 'data-testid': dataTestId} = selectProps;
  return (
    <Typography
      color="textSecondary"
      className={classes[`noOptionsMessage${_capitalize(size)}`]}
      {...innerProps}
      data-testid={dataTestId ? `${dataTestId}-noOptionsMessage` : dataTestId}
    >
      {children}
    </Typography>
  );
};

NoOptionsMessage.muiName = 'NoOptionsMessage';

export default NoOptionsMessage;
