import React from 'react';

import {BaseFilter, BaseProps} from '../../BaseFilter';
import Chip from './Chip';

type Props = BaseProps<boolean>;
const FILTER_ID = 'prime' as const;

export class PrimeFilter extends BaseFilter<Props, boolean> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Prime',
    description: 'Show only prime assets.',
    initialValue: true,
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: PrimeFilter.defaultFilterAddOption,
  } as Partial<Props>;

  render() {
    const {disabled} = this.context;
    const {value, initialValue} = this;

    return (
      <div>
        {value && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            enableColor={this.hasInitialFilters}
            hasChanges={value !== initialValue}
          />
        )}
      </div>
    );
  }
}
