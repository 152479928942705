import React, {FunctionComponent} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import {toUSLocale} from '../../utils/number';
import {CssTooltip, CssTooltipProps} from '../CssTooltip';
import {PopperContentSection} from '../Popper';
import {Styles, styles} from './Counter.style';

export type ListCounterBar = Styles & {
  'data-testid'?: string;
  total: number;
  totalMessage?: string;
  clearAllMessage?: string;
  onClearAll?: () => void;
  tooltipProps?: Partial<CssTooltipProps>;
};

const Counter: FunctionComponent<ListCounterBar> = (props) => {
  const {classes, totalMessage, clearAllMessage, total, onClearAll, tooltipProps} = props;
  const {'data-testid': dataTestId} = props;
  return (
    <PopperContentSection className={classes.root}>
      <Typography className={classes.countLabel}>
        {toUSLocale(total)} {totalMessage}
      </Typography>
      {!!onClearAll && (
        <CssTooltip title={clearAllMessage} centerArrow {...tooltipProps}>
          <IconButton
            data-testid={dataTestId ? `${dataTestId}-removeAllButton` : dataTestId}
            color="primary"
            className={classes.clearAllButton}
            aria-label={clearAllMessage}
            onClick={onClearAll}
          >
            <ClearAllIcon className={classes.clearAllIcon} />
          </IconButton>
        </CssTooltip>
      )}
    </PopperContentSection>
  );
};
Counter.defaultProps = {
  totalMessage: 'total items',
  clearAllMessage: 'Clear List',
};

export default withStyles(styles)(Counter);
