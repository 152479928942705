import React, {ChangeEvent} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Cancel';

import {PopperHeader, PopperHeaderText, PopperHeaderTextProps} from '../Popper';
import {ActionPopperProps, onCloseType} from './ActionPopper';
import {styles, Styles} from './ActionPopper.style';

const handleExit = (type: onCloseType, callback: ActionPopperProps['onClose']) => (
  evt: ChangeEvent<{}> | React.MouseEvent<HTMLElement>,
) => {
  callback(evt, type);
};

export type ActionPopperHeaderProps = Pick<ActionPopperProps, 'classes' | 'onClose'> &
  Styles & {
    title: PopperHeaderTextProps['children'];
    appendTo?: PopperHeaderTextProps['children'];
    showCloseButton?: boolean;
    onClose: (evt: ChangeEvent<{}> | React.MouseEvent<HTMLElement>, type?: onCloseType) => void;
  };
const Header = (props: ActionPopperHeaderProps) => {
  const {classes, appendTo, showCloseButton, title, onClose} = props;
  return (
    <PopperHeader classes={{root: classes.headerRoot}}>
      {title && <PopperHeaderText classes={{root: classes.headerText}}>{title}</PopperHeaderText>}
      {appendTo}
      {showCloseButton && (
        <IconButton
          className={classes.headerButton}
          aria-label={`Close ${title}`}
          onClick={handleExit('click', onClose)}
          classes={{root: classes.buttonRoot}}
        >
          <CloseIcon />
        </IconButton>
      )}
    </PopperHeader>
  );
};

export default withStyles(styles)(Header);
