import React from 'react';

import List, {ListProps} from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';

import {Scrollbars} from '../Scrollbars';

import {Styles, styles} from './SideList.style';

export type SideListProps = Styles & ListProps & {};

const SideList: React.SFC<SideListProps> = (props) => {
  const {classes, children, ...otherProps} = props;
  return (
    <Scrollbars>
      <List classes={classes} {...otherProps}>
        {children}
      </List>
    </Scrollbars>
  );
};

export default withStyles(styles)(SideList);
