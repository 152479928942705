import React, {Component, MouseEvent} from 'react';

import IconButton from '@material-ui/core/IconButton';
import SortIcon from '@material-ui/icons/Sort';

import {SortPopover, SortPopoverProps} from './SortPopover';

export type SortMenuProps = Pick<
  SortPopoverProps,
  | 'sortProperty'
  | 'isDescending'
  | 'autoFocus'
  | 'onChange'
  | 'properties'
  | 'onSelectClose'
  | 'onSelectOpen'
>;

const initialState = {
  anchorEl: null as (HTMLElement | null),
};

export type SortMenuState = typeof initialState;

class SortMenu extends Component<SortMenuProps, SortMenuState> {
  constructor(props: SortMenuProps) {
    super(props);
    this.state = initialState;
  }

  private handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const {
      isDescending,
      sortProperty,
      properties,
      onChange,
      autoFocus,
      onSelectOpen,
      onSelectClose,
    } = this.props;
    const {anchorEl} = this.state;

    return (
      <>
        <IconButton
          color="primary"
          aria-label="Sort Menu"
          aria-owns={anchorEl ? 'sort-menu' : ''}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <SortIcon />
        </IconButton>

        {open && (
          <SortPopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            autoFocus={autoFocus}
            onClose={this.handleClose}
            onChange={onChange}
            sortProperty={sortProperty}
            properties={properties || []}
            isDescending={isDescending}
            onSelectOpen={onSelectOpen}
            onSelectClose={onSelectClose}
          />
        )}
      </>
    );
  }
}

export default SortMenu;
