import {Component, ReactNode} from 'react';

import {createPortal} from 'react-dom';

interface Props {
  open: boolean;
}

class DialogModal extends Component<Props> {
  element: HTMLDivElement;
  root: HTMLDivElement;

  constructor(props) {
    super(props);
    this.element = process.browser ? document.createElement('div') : null;
  }

  setupRoot = () => {
    const reactDomRoot = process.browser ? document.getElementById('root') : null;
    const {parentNode} = reactDomRoot;

    const modalRoot = process.browser ? document.createElement('div') : null;
    modalRoot.setAttribute('style', 'position: relative; z-index: 999;');

    this.root = parentNode.insertBefore(modalRoot, reactDomRoot.nextSibling);
  };

  componentWillMount() {
    this.setupRoot();
  }

  componentDidMount() {
    this.root.appendChild(this.element);
  }

  componentWillUnmount() {
    this.root.removeChild(this.element);
  }

  render() {
    const {open} = this.props;
    return open ? (createPortal(this.props.children, this.element) as ReactNode) : null;
  }
}

export default DialogModal;
