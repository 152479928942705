import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {styles, Styles} from './Legend.style';

export type Props = Styles & {
  className?: string;
  color: string;
  name: string;
  percentual: number;
};

const Legend: React.SFC<Props> = (props) => {
  const {name, percentual, color, className} = props;

  return <Typography className={className} style={{color}}>{`${percentual}% ${name}`}</Typography>;
};

export default withStyles(styles)(Legend);
