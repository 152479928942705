import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {Moment} from 'moment';

import Section from '../Section';
import {Styles, styles} from './DateSection.style';

interface Props extends Styles {
  date: Moment;
}

const Presentational: FC<Props> = (props) => {
  const {classes, date} = props;
  const day = date.format('MMM DD');
  const year = date.format('YYYY');
  const time = date.format('hh:mm A');

  return (
    <Section classes={{root: classes.root}}>
      <Typography className={classes.day}>{day}</Typography>
      <Typography className={classes.year}>{year}</Typography>
      <Typography className={classes.time}>{time}</Typography>
    </Section>
  );
};
export default withStyles(styles)(Presentational);
