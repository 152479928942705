import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      backgroundColor: '#9425b2',
      backgroundImage: 'linear-gradient(90deg,#9425b2 10%,#814ce6)',
      borderRadius: '0 8px 8px 0',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 54,
    },
    button: {
      color: '#fff',
    },
  });

export type Styles = WithStyles<typeof styles>;
