import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {},
    content: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    endDate: {
      alignItems: 'flex-end',
    },
  });

export type Styles = WithStyles<typeof styles>;
