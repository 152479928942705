import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
    image: {
      margin: 'auto',
      display: 'block',
    },
    text: {
      display: 'inline-flex',
      marginTop: 35,
    },
    titleBorder: {
      marginRight: 15,
      paddingRight: 15,
      borderRight: '1px solid #eee',
    },
  });

export type Styles = WithStyles<typeof styles>;
