import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import moment, {Moment} from 'moment';

import {toUSLocale} from '../../../../utils/number';
import {maybePluralize} from '../../../../utils/string';
import Section from '../Section';
import {Styles, styles} from './SummarySection.style';

const getRelativeTime = (startDate: Moment, endDate: Moment) => {
  const now = moment();

  if (startDate.isAfter(now)) return `Starts ${startDate.fromNow()}`;
  if (endDate.isBefore(now)) return `Finished ${endDate.fromNow()}`;

  return `Started ${startDate.fromNow()}`;
};

// number of days
const flightInDays = 28;

interface Props extends Styles {
  startDate: Moment;
  endDate: Moment;
}

const Presentational: FC<Props> = (props) => {
  const {classes, startDate, endDate} = props;

  // add one day to diff because the should consider the entire day
  // for example: 2020-01-10 minus 2020-01-09 returns 1 day but we must consider 2 entire days
  const diffInDays = endDate.diff(startDate, 'days') + 1;
  const flights = diffInDays / flightInDays;

  return (
    <Section classes={{root: classes.root}}>
      <Typography className={classes.info}>{`${diffInDays} ${maybePluralize(
        diffInDays,
        'Day',
      )}`}</Typography>
      <Typography className={classes.info}>{`${toUSLocale(flights, 0, 2)} ${maybePluralize(
        flights,
        'Flight',
      )}`}</Typography>
      <Typography className={classes.info}>{getRelativeTime(startDate, endDate)}</Typography>
    </Section>
  );
};
export default withStyles(styles)(Presentational);
