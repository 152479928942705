import React from 'react';

import classNames from 'classnames';
import _capitalize from 'lodash/capitalize';
import {components} from 'react-select';

import {AutocompleteSize} from '../Autocomplete';

const Input = (props) => {
  const {selectProps, ...otherProps} = props;
  const {classes, size, 'data-testid': dataTestId} = props.selectProps;
  return (
    <components.Input
      key={`input${size}`}
      {...otherProps}
      className={classNames(classes[`input${_capitalize(size)}`], {
        [classes.inputFilled]: props.value,
      })}
      style={{
        fontSize: size === AutocompleteSize.small ? 14 : 16,
      }}
      data-testid={dataTestId ? `${dataTestId}-input` : dataTestId}
    />
  );
};

export default Input;
