import React, {FunctionComponent} from 'react';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import RootRef, {RootRefProps} from '@material-ui/core/RootRef';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {SearchPopperListItem, SearchPopperListItemProps, SearchPopperListOption} from './Item';
import {Styles, styles} from './List.style';

export type SearchPopperListProps = Styles & {
  mainOptions?: SearchPopperListOption[];
  extraOptions?: {
    title: string;
    options: (SearchPopperListOption & Required<Pick<SearchPopperListOption, 'dynamicId'>>)[];
  }[];
  options: SearchPopperListOption[];
  clearOption?: SearchPopperListOption;
  mainOptionsHeader?: string;
  selectedOption?: SearchPopperListOption;
  onClickItem?: SearchPopperListItemProps['onClick'];
  onClearClick?: () => void;
  onMouseEnterItem?: SearchPopperListItemProps['onMouseEnter'];
  selectedOptionRef?: (instance: HTMLElement | null) => void;
};

const handleClickItem = (
  clearOption: SearchPopperListProps['clearOption'] = {
    id: '',
  } as SearchPopperListProps['clearOption'],
  onClickItem: SearchPopperListProps['onClickItem'] = (..._args: any[]) => ({}),
  onClearClick: SearchPopperListProps['onClearClick'] = (..._args: any[]) => ({}),
) => (option: SearchPopperListOption, evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
  if (clearOption && option.id === clearOption.id) {
    onClearClick();
  } else {
    onClickItem(option, evt);
  }
};

const updateSelectedRef = (
  selected: boolean,
  key: string,
  rootRef: RootRefProps['rootRef'],
  comp: any,
) =>
  selected ? (
    <RootRef key={key} rootRef={rootRef}>
      {comp}
    </RootRef>
  ) : (
    comp
  );

const SearchPopperList: FunctionComponent<SearchPopperListProps> = (props) => {
  const {
    classes,
    extraOptions,
    mainOptions,
    options,
    selectedOption,
    mainOptionsHeader,
    onClickItem,
    onMouseEnterItem,
    selectedOptionRef,
    onClearClick,
    clearOption,
  } = props;

  const renderList = (opts: SearchPopperListOption[]) =>
    opts.map((opt) => {
      const selected =
        selectedOption &&
        selectedOption.id === opt.id &&
        selectedOption.title === opt.title &&
        selectedOption.dynamicId === opt.dynamicId;
      const comp = (
        <SearchPopperListItem
          // prevent to use same ids in extra options
          key={opt.id + opt.title + opt.dynamicId || ''}
          option={opt}
          selected={selected}
          onClick={handleClickItem(clearOption, onClickItem, onClearClick)}
          onMouseEnter={onMouseEnterItem}
        />
      );

      return updateSelectedRef(selected, opt.id + opt.title, selectedOptionRef, comp);
    });

  const hasMainOptions = mainOptions && !!mainOptions.length;
  const hasOptions = options && !!options.length;

  return (
    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
      {hasMainOptions && (
        <div className={classes.contentSection}>
          {mainOptionsHeader && (
            <Typography variant="overline" className={classes.contentSectionTitle}>
              {mainOptionsHeader}
            </Typography>
          )}
          {renderList(mainOptions)}
          {hasOptions && <Divider className={classes.divider} />}
        </div>
      )}
      {hasOptions && <div className={classes.contentSection}>{renderList(options)}</div>}
      {!!clearOption && (
        <div className={classes.contentSection}>
          {(hasMainOptions || hasOptions) && <Divider className={classes.divider} />}
          {renderList([clearOption])}
        </div>
      )}
      {!!extraOptions &&
        !!extraOptions.length &&
        extraOptions.map((extra, index) => (
          <div key={extra.title + index} className={classes.contentSection}>
            {extra.title && (
              <Typography
                variant="overline"
                className={`${classes.contentSectionTitle} ${classes.extraOptionsTitle}`}
              >
                {extra.title}
              </Typography>
            )}
            {renderList(extra.options)}
          </div>
        ))}
    </List>
  );
};

SearchPopperList.defaultProps = {
  mainOptionsHeader: 'Frequently Used',
  onClearClick: () => null,
};

export default withStyles(styles)(SearchPopperList);
