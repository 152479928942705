import React, {FunctionComponent, RefObject} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './Body.style';

export interface DatagridTableViewBodyProps extends Styles {
  bodyRef?: RefObject<HTMLDivElement>;
}

const DatagridTableViewBody: FunctionComponent<DatagridTableViewBodyProps> = (props) => {
  const {children, classes, bodyRef} = props;

  return (
    <div ref={bodyRef} className={classNames(classes.root, classes.rows)}>
      {children}
    </div>
  );
};

export default withStyles(styles)(DatagridTableViewBody);
