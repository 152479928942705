import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import moment, {Moment} from 'moment';
import {SingleDatePicker, SingleDatePickerShape} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {TextField, TextFieldProps, TextFieldSize} from '../TextField';
import {style, Style} from './DatePicker.style';

type DatePickerWrapperProps = Omit<SingleDatePickerShape, 'onDateChange' | 'onFocusChange'> & {
  inputRef?: React.RefObject<HTMLDivElement>;
  format?: string;
  onDateChange: (date: string, format: string) => void;
  onFocusChange: (focused: boolean) => void;
};

const CustomSingleDatePicker = (props: SingleDatePickerShape) => {
  const ref = React.useRef<Component<SingleDatePickerShape> & {dayPickerContainer: HTMLElement}>(
    null,
  );

  React.useEffect(() => {
    const {dayPickerContainer} = ref.current;
    // Set focus to calendar container
    if (props.focused && dayPickerContainer) {
      dayPickerContainer.tabIndex = 0;
      dayPickerContainer.focus();
    }
  }, [props.focused]);

  return <SingleDatePicker ref={ref} {...props} />;
};

interface State {
  focused: boolean | null;
}
class DatePicker extends Component<DatePickerWrapperProps, State> {
  static defaultProps: Partial<DatePickerWrapperProps> = {
    id: 'date-picker',
    format: 'YYYY-MM-DD',
  };

  constructor(props: DatePickerWrapperProps) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  onDateChange = (date: Moment | null) => {
    const {format, onDateChange} = this.props;
    onDateChange(date ? date.format(format) : '', format);
  };

  onFocusChange = ({focused}: {focused: boolean}) => {
    const {onFocusChange} = this.props;

    if (onFocusChange) {
      onFocusChange(focused);
    } else {
      this.setState({focused});
    }
  };

  render() {
    const {
      inputRef,
      isOutsideRange,
      appendToBody,
      regular,
      anchorDirection,
      openDirection,
      id,
      focused,
      date,
      numberOfMonths,
      onDateChange,
      onFocusChange,
      ...other
    } = this.props;

    return (
      <div {...other} ref={inputRef}>
        <CustomSingleDatePicker
          focused={onFocusChange ? focused : this.state.focused}
          id={id}
          onFocusChange={this.onFocusChange}
          date={date}
          onDateChange={this.onDateChange}
          anchorDirection={anchorDirection}
          regular={regular}
          showDefaultInputIcon
          noBorder
          hideKeyboardShortcutsPanel
          isOutsideRange={isOutsideRange}
          appendToBody={appendToBody}
          openDirection={openDirection}
          numberOfMonths={numberOfMonths}
        />
      </div>
    );
  }
}

export type DatePickerProps = {
  date: string;
} & Style &
  Pick<DatePickerWrapperProps, 'onDateChange'> &
  Partial<
    Pick<
      DatePickerWrapperProps,
      | 'onFocusChange'
      | 'focused'
      | 'anchorDirection'
      | 'openDirection'
      | 'isOutsideRange'
      | 'appendToBody'
      | 'regular'
      | 'format'
      | 'numberOfMonths'
    >
  > &
  Pick<TextFieldProps, 'id' | 'label' | 'required' | 'fullWidth'>;

const Presentational: React.SFC<DatePickerProps> = ({
  classes,
  id,
  date,
  label,
  required,
  fullWidth,
  regular,
  onDateChange,
  isOutsideRange,
  appendToBody,
  focused,
  format,
  numberOfMonths,
  onFocusChange,
  anchorDirection,
  openDirection,
}) => {
  return (
    <TextField
      id={id}
      size={TextFieldSize.small}
      label={label}
      required={required}
      fullWidth={fullWidth}
      className={classes.textField}
      InputProps={{
        inputProps: {
          date: date ? moment(date) : null,
          className: classes.outlined,
          onDateChange,
          regular,
          appendToBody,
          isOutsideRange,
          focused,
          format,
          onFocusChange,
          anchorDirection,
          openDirection,
          numberOfMonths,
        },
        inputComponent: DatePicker as any,
      }}
    />
  );
};

Presentational.defaultProps = {
  regular: false,
  isOutsideRange: (_day) => false,
  appendToBody: false,
  numberOfMonths: 1,
};

export default withStyles(style)(Presentational);
