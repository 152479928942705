import React, {HTMLAttributes, SFC} from 'react';

import CircularProgress, {CircularProgressProps} from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './Loading.style';

export type LoadingProps = Styles &
  HTMLAttributes<HTMLDivElement> & {
    circularProgressProps?: CircularProgressProps;
  };

const Presentational: SFC<LoadingProps> = ({
  classes,
  className,
  circularProgressProps,
  ...divProps
}) => {
  return (
    <div className={classNames(classes.root, className)} {...divProps}>
      <CircularProgress {...circularProgressProps} />
    </div>
  );
};

Presentational.defaultProps = {
  circularProgressProps: {
    size: 24,
    thickness: 5,
  },
};

export default withStyles(styles)(Presentational);
