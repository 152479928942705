import React from 'react';

import classNames from 'classnames';
import _equal from 'fast-deep-equal';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import {nope} from '../../../utils/function';
import {FilterAdd, FilterAddProps} from '../../FilterAdd';
import Scrollbars from '../../Scrollbars/Scrollbars';
import {SearchPopperProps} from '../../SearchPopper';
import {BaseProps} from '../BaseFilter';
import {FilterContext, initialState} from '../FilterContext';
import FilterSlotButton from './Components/FilterSlotButton';
import {State} from './FilterSlot.Provider';
import {Styles} from './FilterSlot.style';
import {KeydownWrap} from './KeydownWrap';

type FilterDictionary = State['appliedFilters'];

export type Props = {
  hideAddButton?: boolean;
  defaultFilter?: string;
  disabled?: boolean;
  loading?: boolean;
  children: Array<React.ReactElement<BaseProps<any>>>;
  appliedFilters?: FilterDictionary;
  initialAppliedFilters?: FilterDictionary;
  extraFilterAddEntries?: FilterAddProps['extraFilters'];
  onFilterAddSearch?: FilterAddProps['onSearch'];
  onFilterAddSelect?: (
    filterId: SearchPopperProps['options'][number]['id'],
    filterInitialValue: SearchPopperProps['options'][number]['initialValue'],
    evt: React.MouseEvent | React.KeyboardEvent,
  ) => void | boolean;
  onApply?: (f: FilterDictionary, ev?: React.SyntheticEvent) => void;
  onCancelQuery?: () => void;
  onResetAll?: () => void;
  applyDisabled?: boolean;
  onRemoveFilter?: (id: string) => void;
  onMenuOpen?: () => void;
} & Styles;

export default class FilterSlot extends React.PureComponent<Props, State> {
  static contextType = FilterContext;
  context!: React.ContextType<typeof FilterContext>;
  filterAddChipRef = React.createRef<HTMLElement>();
  uniqueId: string;

  static defaultProps = {
    disabled: false,
    loading: false,
    applyDisabled: true,
    onApply: nope,
    onResetAll: nope,
    onFilterAddSearch: nope,
    onFilterAddSelect: nope,
    onRemoveFilter: nope,
    onMenuOpen: nope,
  };

  constructor(props: Props, context: React.ContextType<typeof FilterContext>) {
    super(props, context);

    context.addRemoveFilterListener(props.onRemoveFilter);
    context.setState({
      ...initialState,
      order: Object.keys(props.appliedFilters || {}),
      renderSplit: false,
      shouldOpenTipToApply: false,
      disabled: props.disabled || props.loading,
      initialAppliedFilters: props.initialAppliedFilters,
      addChipRef: this.filterAddChipRef,
      ...(props.appliedFilters
        ? {
            shownFilters: props.appliedFilters,
            appliedFilters: props.appliedFilters,
          }
        : {}),
    });
  }

  statesAreEqual = () => {
    const {shownFilters, appliedFilters} = this.context;
    return _equal(shownFilters, appliedFilters);
  };
  canCancel = () => {
    const {onCancelQuery, loading} = this.props;
    return loading && Boolean(onCancelQuery);
  };

  shouldDisableApply = () => {
    const {applyDisabled} = this.props;
    return applyDisabled ? this.statesAreEqual() : applyDisabled;
  };

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.onRemoveFilter !== this.props.onRemoveFilter) {
      this.context.addRemoveFilterListener(this.props.onRemoveFilter);
    }
  }

  componentDidUpdate() {
    const next = this.props;
    const current = this.context;

    let newContext = {} as State;

    if (!_equal(next.appliedFilters, current.appliedFilters)) {
      this.processChipOrderState(next);

      const shownFilters = next.appliedFilters;
      newContext.shownFilters = shownFilters;
      newContext.appliedFilters = shownFilters;
    }

    if ((next.disabled || next.loading) !== current.disabled) {
      newContext.disabled = next.disabled || next.loading;
    }

    if (!_equal(next.initialAppliedFilters, current.initialAppliedFilters)) {
      newContext.initialAppliedFilters = next.initialAppliedFilters;
    }

    if (!isEmpty(newContext)) {
      this.context.setState({...newContext});
    }
  }

  keyPressHandler = (event: KeyboardEvent) => {
    if (event.key === '/') {
      event.preventDefault();
      this.context.handleChipFilterClick('add')(this.filterAddChipRef.current);
      this.props.onMenuOpen();
    }

    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault();
      this.canCancel() ? this.handleCancel() : this.handleApply();
    }
  };

  handleRemoveAllFilters = () => {
    this.context.handleRemoveAllFilters();
    this.props.onResetAll();
  };

  handleCancel = () => {
    const {onCancelQuery} = this.props;
    if (onCancelQuery) {
      onCancelQuery();
    }
  };

  handleApply = (ev?: React.SyntheticEvent) => {
    const {onApply} = this.props;
    const newState = {} as State;

    if (onApply && !this.shouldDisableApply()) {
      const {shownFilters} = this.context;
      newState.appliedFilters = shownFilters;
      newState.tempFilters = {};
      onApply(shownFilters, ev);
    }
    this.context.setState({...newState, shouldOpenTipToApply: false});
  };

  handleUpdateScrollBar = ({scrollWidth, clientWidth}) => {
    const {renderSplit} = this.context;

    if (scrollWidth > clientWidth && !renderSplit) {
      this.context.setState({renderSplit: true});
    } else if (scrollWidth <= clientWidth && renderSplit) {
      this.context.setState({renderSplit: false});
    }
  };

  orderMap = () => {
    const {order} = this.context;

    return (
      {
        props: {
          filterAddOption: {id: a},
        },
      },
      {
        props: {
          filterAddOption: {id: b},
        },
      },
    ) => {
      return order.indexOf(a) - order.indexOf(b);
    };
  };

  private processChipOrderState(next: Readonly<Props>) {
    const {order} = this.context;

    const keys = Object.keys(next.appliedFilters || {});
    // keepFilters is the difference between the last order minus nextProps
    const keepFilters = order.filter((n) => keys.includes(n));
    // if newFilters has value is because some filter was added by Props, not by state
    /// so it has to be added to state order.
    const newFilters = keys.filter((n) => !order.includes(n));
    this.context.setState({order: [...keepFilters, ...newFilters]});
  }

  render() {
    const {classes, disabled, hideAddButton} = this.props;
    const {renderSplit: shouldScroll, openFilter, scrollbarsRef} = this.context;

    const children = React.Children.toArray(this.props.children) as Array<
      React.ReactElement<BaseProps<any>>
    >;

    const keydownDisabled = !!openFilter || disabled;

    return (
      <KeydownWrap disable={keydownDisabled} onKeyDown={debounce(this.keyPressHandler, 200)}>
        <div className={classes.root}>
          {!hideAddButton && this.renderFilterAdd()}
          <div className={classes.chipsContainer}>
            <Scrollbars
              innerRef={scrollbarsRef}
              renderView={ScrollbarsView}
              onUpdate={this.handleUpdateScrollBar}
            >
              <div
                className={classNames(classes.scrollContent, {
                  [classes.noAddFilterButton]: hideAddButton,
                  [classes.lastItemPadding]: shouldScroll,
                })}
              >
                {children.sort(this.orderMap()).map((item) => item)}
                {!shouldScroll && this.renderApplyButton()}
              </div>
            </Scrollbars>
          </div>
          {shouldScroll && this.renderApplyButton()}
        </div>
      </KeydownWrap>
    );
  }

  renderApplyButton() {
    const hasFilters = (filters: FilterDictionary[]) =>
      filters.some((filter) => Object.keys(filter).length > 0);

    const {shownFilters, appliedFilters, shouldOpenTipToApply} = this.context;
    const {onApply} = this.props;
    const hasScroll = this.context.renderSplit;

    if (!onApply || !hasFilters([shownFilters, appliedFilters])) {
      return null;
    }

    const toggleTooltip = (open: boolean) => () =>
      this.context.setState({shouldOpenTipToApply: open});

    return (
      <FilterSlotButton
        appliedFilters={appliedFilters}
        disabled={this.shouldDisableApply()}
        handleApply={this.handleApply}
        hasScroll={hasScroll}
        shouldOpenTipToApply={shouldOpenTipToApply}
        shownFilters={shownFilters}
        toggleTooltip={toggleTooltip}
        handleCancelQuery={this.handleCancel}
        showCancel={this.canCancel()}
      />
    );
  }

  handleFilterAddSelect = (
    filterId: SearchPopperProps['options'][number]['id'],
    filterInitialValue: SearchPopperProps['options'][number]['initialValue'],
    evt: React.MouseEvent | React.KeyboardEvent,
  ) => {
    const abortAdding = this.props.onFilterAddSelect(filterId, filterInitialValue, evt) === false;
    if (abortAdding) {
      this.context.setState({openFilter: ''});
    } else {
      this.context.handleAddFilter(filterId, filterInitialValue);
    }
  };

  handleMenuOpen = (event: HTMLDivElement) => {
    const {onMenuOpen} = this.props;
    if (onMenuOpen) {
      onMenuOpen();
    }
    this.context.handleChipFilterClick('add')(event);
  };

  renderFilterAdd() {
    const {
      children,
      disabled,
      loading,
      extraFilterAddEntries,
      onFilterAddSearch,
      defaultFilter = '',
    } = this.props;
    const {openFilter, anchorEl} = this.context;

    const availableFilters = children.map(({props}) => ({
      ...props.filterAddOption,
      main: props.isMain,
    }));
    const defaultOption = availableFilters.find(({id}) => defaultFilter === id);
    const mainAvailableFilters = availableFilters.filter(({main}) => main);
    const secondFilters = availableFilters.filter(
      ({id}) => !mainAvailableFilters.some(({id: mainId}) => mainId === id),
    );

    const open = openFilter === 'add';
    const tooltipProps = {
      title: open ? '' : 'Press "/" to open',
      placement: 'bottom' as 'bottom',
    };

    return (
      <FilterAdd
        anchorEl={anchorEl}
        rootChipRef={this.filterAddChipRef}
        mainFilters={mainAvailableFilters}
        defaultOption={defaultOption}
        filters={secondFilters}
        open={open}
        disabled={disabled || loading}
        onSelect={this.handleFilterAddSelect}
        onOpen={this.handleMenuOpen}
        onClose={this.context.handlePopperClose}
        onClearClick={this.handleRemoveAllFilters}
        extraFilters={extraFilterAddEntries}
        onSearch={onFilterAddSearch}
        tooltipProps={tooltipProps}
      />
    );
  }
}

const ScrollbarsView = (props: any) => <div {...props} style={{...props.style}} />;
