import React from 'react';

type Props = {
  onKeyDown: (e: KeyboardEvent) => void;
  disable: boolean;
};

export class KeydownWrap extends React.Component<Props> {
  componentWillReceiveProps({disable}: Props) {
    if (disable) {
      setImmediate(() => this.removeEvent());
    } else if (!disable && this.props.disable) {
      setImmediate(() => this.addEvent());
    }
  }

  componentDidMount() {
    if (!this.props.disable) {
      this.addEvent();
    }
  }

  componentWillUnmount() {
    this.removeEvent();
  }

  addEvent() {
    window.addEventListener('keydown', this.keyPressHandler, false);
  }
  removeEvent() {
    window.removeEventListener('keydown', this.keyPressHandler, false);
  }

  keyPressHandler = (event: KeyboardEvent) => {
    this.props.onKeyDown(event);
  };

  render() {
    return this.props.children;
  }
}
