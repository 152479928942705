import React, {FunctionComponent} from 'react';

import List, {ListProps} from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';

import {Popper, PopperPaper, PopperProps} from '../Popper';
import {Styles, styles} from './Menu.style';

export type ContextMenuProps = PopperProps &
  Styles & {
    listProps?: ListProps;
    classes?: PopperProps['classes'] & Styles['classes'];
  };

const Presentational: FunctionComponent<ContextMenuProps> = (props) => {
  const {classes, listProps, children, ...otherProps} = props;
  const {paper, ...otherClasses} = classes;
  return (
    <Popper classes={otherClasses} {...otherProps}>
      <PopperPaper classes={{root: classes.paper}}>
        <List {...listProps}>{children}</List>
      </PopperPaper>
    </Popper>
  );
};

Presentational.defaultProps = {
  placement: 'bottom-end',
  listProps: {
    component: 'nav',
    'aria-labelledby': 'nested-list-subheader',
  },
};

export default withStyles(styles)(Presentational);
