import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    itemRoot: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'rgba(0,0,0,0.08)',
      },
      '&$selected': {
        backgroundColor: 'rgba(0,0,0,0.08)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.08)',
        },
      },
    },
    selected: {},
    itemTextRoot: {
      paddingRight: 0,
    },
    itemTextPrimary: {
      fontSize: 14,
      '&$textDense': {
        fontSize: 14,
      },
    },
    itemTextSecondary: {
      '&$textDense': {
        fontSize: 12,
      },
    },
    textDense: {},
    badge: {
      height: 15,
      color: 'rgba(0,0,0,0.4)',
      fontWeight: 500,
      fontSize: 8,
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 5px',
      borderRadius: 30,
      marginLeft: 8,
    },
    warning: {
      backgroundColor: '#F3D63B',
    },
    danger: {
      backgroundColor: '#ffa8c6',
    },
    newItemTextPrimaryRoot: {
      display: 'flex',
      alignItems: 'center',
    },
  });

export type Styles = WithStyles<typeof styles>;
