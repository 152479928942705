import React from 'react';

import classNames from 'classnames';
import _capitalize from 'lodash/capitalize';

import {TextField} from '../../TextField';

function inputComponent({inputRef, ...props}: any) {
  return <div ref={inputRef} {...props} />;
}

const Control = (props) => {
  const {innerProps, innerRef, children, selectProps} = props;
  const {'data-testid': dataTestId, classes, label, size, TextFieldProps} = selectProps;
  return (
    <TextField
      fullWidth
      size={size}
      label={label}
      variant="outlined"
      InputProps={{
        inputComponent,
      }}
      inputProps={{
        className: classNames(classes[`control${_capitalize(size)}`], {
          [classes.controlIsMulti]: selectProps.isMulti,
        }),
        inputRef: innerRef,
        children: children,
        ...innerProps,
      }}
      {...TextFieldProps}
      data-testid={dataTestId ? `${dataTestId}-control` : dataTestId}
    />
  );
};

Control.muiName = 'Control';

export default Control;
