import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './Dialog.style';
import DialogContent from './DialogContent';
import DialogModal from './DialogModal';
import {Option} from './model';

interface Props extends Styles {
  onClose: () => void;
  open: boolean;
  actions?: Option[];
}

class Dialog extends Component<Props, {}> {
  render() {
    const {classes, children, open, onClose, actions} = this.props;

    return (
      <DialogModal open={open}>
        <DialogContent classes={classes} onClose={onClose} actions={actions}>
          {children}
        </DialogContent>
      </DialogModal>
    );
  }
}

export default withStyles(styles)(Dialog);
