import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Scrollbars, ScrollbarsProps} from '../../Scrollbars';

import {Styles, styles} from './Content.style';

export type DrawerContentProps = ScrollbarsProps & Styles;

class DrawerContent extends Component<DrawerContentProps> {
  render() {
    const {classes, children, ref, ...scrollProps} = this.props;

    return (
      <Scrollbars {...scrollProps}>
        <div className={classes.root}>{children}</div>
      </Scrollbars>
    );
  }
}

export default withStyles(styles)(DrawerContent);
