import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import {Option} from '../../models/Option';
import {ListCounterBar} from '../ListCounterBar';
import {VirtualizedList, VirtualizedListProps} from '../VirtualizedList';
import {RemovableListItem, RemovableListItemProps} from './Item';
import {Styles, styles} from './RemovableList.style';

export type RemovableListProps = Styles & {
  'data-testid'?: string;
  disabled?: boolean;
  virtualized?: Pick<VirtualizedListProps, 'rowHeight' | 'scrollElement'>;
  ItemClasses?: Partial<RemovableListItemProps['classes']>;
  selected?: Option;
  items: Option[];
  totalMessage?: string;
  showTotalMessageFrom?: number;
  clearAllMessage?: string;
  listLabel?: string;
  onChange?: (value: Option[]) => void;
  onClickItem?: RemovableListItemProps['onClick'];
  children?: RemovableListItemProps['children'];
};

const initialState = {
  maxItemCounter: 0,
  tooltipIsOpen: false,
};

type RemovableListState = typeof initialState;

class List extends React.Component<RemovableListProps, RemovableListState> {
  static defaultProps = {
    totalMessage: 'total items',
    showTotalMessageFrom: 7,
    clearAllMessage: 'Clear List',
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    return nextProps.items.length < prevState.maxItemCounter
      ? null
      : {maxItemCounter: nextProps.items.length};
  };

  constructor(props: RemovableListProps) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  handleClearAll = () => {
    const {onChange} = this.props;
    onChange([]);
    this.setState({tooltipIsOpen: false, maxItemCounter: 0});
  };

  handleRemove = (op: Option) => {
    const {onChange, items, showTotalMessageFrom} = this.props;
    const {maxItemCounter} = this.state;
    if (!items || !items.length) {
      onChange(null);
    } else {
      const filteredItems = items.filter((v) => v.value !== op.value);
      onChange(filteredItems);
      if (
        filteredItems.length >= showTotalMessageFrom &&
        maxItemCounter - filteredItems.length >= 3
      ) {
        this.setTooltipIsOpen(true)();
      }
    }
  };

  RowRender = ({index, style}: {index: number; style?: RemovableListItemProps['style']}) => {
    const {
      'data-testid': dataTestId,
      items,
      disabled,
      ItemClasses,
      selected,
      children,
      onClickItem,
    } = this.props;
    const item = items[index];

    const showCounterBar = this.shouldShowCounterBar();

    return (
      <RemovableListItem
        data-testid={dataTestId}
        disabled={disabled}
        style={style}
        classes={ItemClasses}
        key={item.value}
        item={item}
        selected={selected && selected === item}
        children={children}
        onClick={onClickItem}
        onRemove={this.handleRemove}
        hideBorderToLast={showCounterBar}
      />
    );
  };

  renderList = () => {
    const {virtualized, items} = this.props;

    return !virtualized ? (
      (items || []).map((item, index) => this.RowRender({index}))
    ) : (
      <VirtualizedList
        rowHeight={virtualized.rowHeight || 38}
        scrollElement={virtualized.scrollElement}
        items={items}
      >
        {this.RowRender}
      </VirtualizedList>
    );
  };

  shouldShowCounterBar = () => {
    const {items = [], showTotalMessageFrom} = this.props;
    const hasValues = items && !!items.length;

    return (
      hasValues && typeof showTotalMessageFrom === 'number' && items.length >= showTotalMessageFrom
    );
  };

  setTooltipIsOpen = (value: boolean) => () => {
    this.setState({tooltipIsOpen: value});
  };

  render() {
    const {classes, items = [], totalMessage, clearAllMessage, listLabel, disabled} = this.props;
    const {'data-testid': dataTestId} = this.props;
    const hasValues = items && !!items.length;
    const {tooltipIsOpen} = this.state;

    const showCounterBar = this.shouldShowCounterBar();

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootWithCounter]: showCounterBar,
        })}
      >
        {listLabel && hasValues && (
          <Typography variant="caption" className={classes.listLabel}>
            {listLabel}
          </Typography>
        )}
        <div className={classes.listItems}>{this.renderList()}</div>
        {showCounterBar && (
          <ListCounterBar
            data-testid={dataTestId}
            classes={{root: classes.counterRoot}}
            totalMessage={totalMessage}
            clearAllMessage={clearAllMessage}
            total={items.length}
            onClearAll={disabled ? undefined : this.handleClearAll}
            tooltipProps={{
              open: tooltipIsOpen,
              onOpen: this.setTooltipIsOpen(true),
              onClose: this.setTooltipIsOpen(false),
            }}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(List);
