import React, {FunctionComponent} from 'react';

import ListItem, {ListItemProps} from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './MenuItem.style';

export type DrawerMenuItemProps = ListItemProps &
  Styles & {
    active?: boolean;
  };

const Presentational: FunctionComponent<DrawerMenuItemProps> = ({
  classes,
  active,
  children,
  ...listItemProps
}) => {
  const {active: activeClass, text: textClass, ...listItemClass} = classes;
  return (
    <ListItem
      className={classNames({[activeClass]: active})}
      classes={listItemClass}
      {...listItemProps}
    >
      <ListItemText classes={{primary: textClass}} primary={children} />
    </ListItem>
  );
};

Presentational.defaultProps = {
  button: true,
  component: 'a',
};

export default withStyles(styles)(Presentational);
