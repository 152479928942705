import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    cardText: {
      fontSize: 14,
      marginBottom: 0,
      // .d-block {
      //   display: block !important;
      // }
    },
  });

export type Styles = WithStyles<typeof styles>;
