import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
