import React from 'react';

import _capitalize from 'lodash/capitalize';
import {components} from 'react-select';
import {CellMeasurer, CellMeasurerCache} from 'react-virtualized';

import {VirtualizedList} from '../../VirtualizedList';
import {AutocompleteSize} from '../Autocomplete';

interface CellMeasurerVirtualizedListProps {
  defaultHeight: number;
  maxMenuHeight: number;
  options: any[];
  children: any[];
}
const CellMeasurerVirtualizedList = ({
  defaultHeight,
  children,
  options,
  maxMenuHeight,
}: CellMeasurerVirtualizedListProps) => {
  const cache = new CellMeasurerCache({
    defaultHeight,
    fixedWidth: true,
  });
  return (
    <VirtualizedList
      items={options.slice(0, children.length)}
      scrollAutoHeightMax={maxMenuHeight}
      deferredMeasurementCache={cache}
      rowHeight={cache.rowHeight}
    >
      {({index, style, parent, key}) => (
        <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
          <div style={style}>{children[index]}</div>
        </CellMeasurer>
      )}
    </VirtualizedList>
  );
};

const MenuList = (props) => {
  const {children, selectProps} = props;
  const {size, classes, virtualized, maxMenuHeight} = selectProps;

  return (
    <components.MenuList {...props} className={classes.menuList}>
      {!children.length || !virtualized ? (
        children
      ) : (
        <CellMeasurerVirtualizedList
          options={props.options}
          maxMenuHeight={maxMenuHeight}
          defaultHeight={size === AutocompleteSize.small ? 40 : 46}
        >
          {children}
        </CellMeasurerVirtualizedList>
      )}
    </components.MenuList>
  );
};

MenuList.muiName = 'Menu';

export default MenuList;
