import React, {FC, MouseEvent, ReactNode} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {
  ContextMenu,
  ContextMenuDivider,
  ContextMenuItem,
  ContextMenuProps,
} from '../../../ContextMenu';
import {Styles, styles} from './MenuPopper.style';

interface Props
  extends Pick<ContextMenuProps, 'open' | 'anchorEl' | 'modifiers' | 'onClickAway'>,
    Styles {
  beforeSettingsItem?: ReactNode;
  afterSettingsItem?: ReactNode;
  beforeDeleteItem?: ReactNode;
  afterDeleteItem?: ReactNode;
  onSettingsClick: (evt: MouseEvent) => void;
  onDeleteClick: (evt: MouseEvent) => void;
}

const Presentational: FC<Props> = (props) => {
  const {open, anchorEl, modifiers, onClickAway, onSettingsClick, onDeleteClick} = props;
  const {beforeSettingsItem, afterSettingsItem, beforeDeleteItem, afterDeleteItem} = props;
  return (
    <ContextMenu anchorEl={anchorEl} open={open} modifiers={modifiers} onClickAway={onClickAway}>
      {beforeSettingsItem}
      <ContextMenuItem name="Settings" onClick={onSettingsClick} />
      {afterSettingsItem}
      <ContextMenuDivider />
      {beforeDeleteItem}
      <ContextMenuItem name="Delete" onClick={onDeleteClick} />
      {afterDeleteItem}
    </ContextMenu>
  );
};
Presentational.defaultProps = {
  modifiers: {
    offset: {offset: '0px,0px'},
  },
};
export default withStyles(styles)(Presentational);
