import React from 'react';

import ListSubheader from '@material-ui/core/ListSubheader';
import _capitalize from 'lodash/capitalize';

import {ConditionalWrap} from '../../ConditionalWrap';

const wrap = ({dataTestId, classes, size, label, headingProps}) => (children) => (
  <div className={classes.groupRoot}>
    <ListSubheader
      classes={{root: classes[`groupHeader${_capitalize(size)}`]}}
      disableSticky
      component="div"
      {...headingProps}
      data-testid={dataTestId ? `${dataTestId}-groupHeader` : headingProps.id}
    >
      {label}
    </ListSubheader>
    <div data-testid={dataTestId ? `${dataTestId}-groupValueContainer` : dataTestId}>
      {children}
    </div>
  </div>
);

const Group = (props) => {
  const {selectProps, label, children, headingProps} = props;
  const {classes, size, 'data-testid': dataTestId} = selectProps;
  return (
    <ConditionalWrap
      condition={!!label}
      wrap={wrap({
        dataTestId,
        classes,
        size,
        label,
        headingProps,
      })}
    >
      {children}
    </ConditionalWrap>
  );
};

Group.muiName = 'Group';

export default Group;
