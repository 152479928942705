import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {toUSLocale} from '../../utils/number';
import {HorizontalBar} from '../charts';
import {CssTooltip, CssTooltipProps} from '../CssTooltip';
import {Styles, styles} from './BudgetBar.style';

export interface BudgetBarProps extends Styles {
  budget: number;
  value: number;
  tooltipLabel?: (percentage: number) => string;
  tooltipPlacement?: CssTooltipProps['placement'];
  tooltipCenterArrow?: CssTooltipProps['centerArrow'];
  underHalfWarning?: boolean;
  disableAnimation?: boolean;
}

const Presentational: FC<BudgetBarProps> = (props) => {
  const {
    classes,
    value,
    budget,
    tooltipPlacement,
    tooltipLabel,
    tooltipCenterArrow,
    underHalfWarning,
    disableAnimation,
  } = props;

  const percentage = (value / budget) * 100;

  return (
    <CssTooltip
      centerArrow={tooltipCenterArrow}
      title={tooltipLabel(percentage)}
      placement={tooltipPlacement}
    >
      <div>
        <HorizontalBar
          fillInPercentage={percentage ? Math.min(Math.max(percentage, 0.5), 100) : 0}
          disableAnimation={disableAnimation}
          classes={{
            root: classes.barRoot,
            bar: classNames(
              classes.barPercentage,
              {[classes.barOver]: percentage > 100},
              {[classes.barUnderHalf]: underHalfWarning && percentage <= 50},
            ),
          }}
        />
      </div>
    </CssTooltip>
  );
};

Presentational.defaultProps = {
  tooltipLabel: (percentage) => `${toUSLocale(percentage, 0, 2)}%`,
  tooltipPlacement: 'top',
  underHalfWarning: false,
};

export default withStyles(styles)(Presentational);
