export const getIndicatorProps = (props, suffix: string) => {
  const {'data-testid': dataTestId} = props.selectProps;
  const {innerProps} = props;

  return {
    ...props,
    innerProps: {
      ...innerProps,
      'data-testid': dataTestId ? `${dataTestId}-${suffix}` : dataTestId,
    },
  };
};
