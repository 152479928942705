import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      paddingTop: 0,
    },
  });

export type Styles = WithStyles<typeof styles>;
