import React, {FC, MouseEvent} from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './MenuItem.style';

export interface ContextMenuItemProps extends Styles {
  name: React.ReactNode;
  disabled?: boolean;
  onClick?: (evt: MouseEvent<HTMLElement>) => void;
  button?: boolean;
  selected?: boolean;
}

const Presentational: FC<ContextMenuItemProps> = (props) => {
  const {classes, button, name, selected, disabled, onClick} = props;

  return (
    <ListItem
      button={button}
      disabled={disabled}
      selected={selected}
      onClick={!disabled ? onClick : undefined}
      classes={{
        disabled: classes.disabledItem,
      }}
    >
      <ListItemText
        primary={name}
        classes={{
          root: classes.itemTextRoot,
          primary: classNames(classes.itemTextPrimary, {[classes.disabledText]: disabled}),
          textDense: classes.textDense,
        }}
      />
    </ListItem>
  );
};

Presentational.defaultProps = {
  button: true,
};

export default withStyles(styles)(Presentational);
