import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      padding: 0,
      position: 'relative',
      width: 48,
      height: 48,
    },
    avatar: {
      width: 30,
      height: 30,
    },
    progress: {
      position: 'absolute',
      right: 5,
      top: 5,
      zIndex: 1,
    },
  });

export type Styles = WithStyles<typeof styles>;
