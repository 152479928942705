import React, {Component, createContext, createRef, RefObject} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import ReactCustomScrollbars, {
  positionValues,
  ScrollbarProps as ReactCustomScrollbarProps,
} from 'react-custom-scrollbars';

import {easeOutExpo} from '../../utils/easing';
import {conditionalReturn} from '../../utils/function';
import {safeCall} from '../../utils/function';
import {Styles, styles} from './Scrollbars.style';

const initialContextState = {
  isScrolling: false,
  scrollTop: null as number,
  view: null as Element,
  disableVerticalScrolling: false,
};

const initialContextActions = {
  scrollToX: (x: number = 0, smooth?: boolean, duration?: number) => {
    return null;
  },
  setDisableVerticalScrolling: (disabled: boolean) => {
    return;
  },
  // scrollbarsRef: null as RefObject<ReactCustomScrollbarsRef>,
};

export type FilterContextActions = typeof initialContextActions;

const initialState = {
  ...initialContextState,
};

type State = typeof initialState;

export const ScrollbarsContext = createContext({
  ...initialContextState,
  ...initialContextActions,
});

export type ReactCustomScrollbarsRef = ReactCustomScrollbars & {view: Element};
export type ScrollbarsProps = ReactCustomScrollbarProps &
  Styles & {
    disableHorizontalScrolling?: boolean;
    disableVerticalScrolling?: boolean;
  };

class Scrollbars extends Component<ScrollbarsProps, State> {
  static defaultProps: Partial<ScrollbarsProps> = {
    autoHide: true,
  };

  private scrollbars: RefObject<ReactCustomScrollbarsRef>;

  constructor(props: ScrollbarsProps) {
    super(props);
    this.state = initialState;
    this.scrollbars = createRef();
  }

  getContextValue = () => {
    const {scrollbars} = this;
    const {scrollTop, isScrolling, disableVerticalScrolling} = this.state;
    const {view} = scrollbars.current || ({} as typeof scrollbars.current);

    return {
      scrollTop,
      isScrolling,
      view,
      scrollToX: this.scrollToX,
      disableVerticalScrolling,
      setDisableVerticalScrolling: this.setDisableVerticalScrolling,
      // scrollbarsRef: this.scrollbars,
    };
  };

  setDisableVerticalScrolling = (disabled: boolean) => {
    this.setState({disableVerticalScrolling: disabled});
  };

  handleUpdate = (values: positionValues) => {
    this.setState((state) => {
      const currentScrollTop = state.scrollTop;
      if (currentScrollTop !== values.scrollTop) {
        return {scrollTop: values.scrollTop};
      }
    });

    const {onUpdate} = this.props;
    if (onUpdate) {
      onUpdate(values);
    }
  };

  onScrollStart = () => {
    this.setState({isScrolling: true});
    const {onScrollStart} = this.props;
    safeCall(onScrollStart);
  };

  onScrollStop = () => {
    this.setState({isScrolling: false});
    const {onScrollStop} = this.props;
    safeCall(onScrollStop);
  };

  render() {
    const {classes, children, disableHorizontalScrolling, style, ...otherProps} = this.props;

    const disableVerticalScrolling =
      this.state.disableVerticalScrolling || this.props.disableVerticalScrolling;

    return (
      <ScrollbarsContext.Provider value={this.getContextValue()}>
        <ReactCustomScrollbars
          ref={this.scrollbars}
          style={style}
          renderView={(props) => {
            return (
              <div
                {...props}
                className={classes.renderView}
                style={{
                  ...props.style,

                  ...conditionalReturn(
                    disableHorizontalScrolling,
                    {
                      overflowX: 'hidden',
                      marginBottom: 0,
                    },
                    {
                      overflowX: props.style.overflowX || 'scroll',
                      marginBottom: props.style.marginBottom,
                    },
                  ),
                  ...conditionalReturn(
                    disableVerticalScrolling,
                    {
                      overflowY: 'hidden',
                      marginRight: 0,
                    },
                    {
                      overflowY: props.style.overflowY || 'scroll',
                      marginRight: props.style.marginRight,
                    },
                  ),
                }}
              />
            );
          }}
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              className={classes.trackHorizontal}
              style={{
                ...props.style,
                display: disableHorizontalScrolling ? 'none' : props.style.display || 'block',
              }}
            />
          )}
          renderTrackVertical={(props) => (
            <div
              {...props}
              className={classes.trackVertical}
              style={{
                ...props.style,
                display: disableVerticalScrolling ? 'none' : props.style.display || 'block',
              }}
            />
          )}
          {...otherProps}
          onUpdate={this.handleUpdate}
          onScrollStart={this.onScrollStart}
          onScrollStop={this.onScrollStop}
        >
          {children}
        </ReactCustomScrollbars>
      </ScrollbarsContext.Provider>
    );
  }

  animateScroll = (
    type: 'vertically' | 'horizontally',
    from: number,
    to: number,
    duration: number,
    startTime?: number,
  ) => (timestamp: number) => {
    if (!startTime) startTime = timestamp;
    const spentTime = timestamp - startTime;
    const progress = easeOutExpo(spentTime / duration);
    const stepSize = (to - from) * progress;

    if (spentTime <= duration && this.scrollbars.current) {
      if (type === 'vertically') {
        this.scrollbars.current.scrollTop(from + stepSize);
      } else {
        this.scrollbars.current.scrollLeft(from + stepSize);
      }
      window.requestAnimationFrame(this.animateScroll(type, from, to, duration, startTime));
    }
  };

  scrollToX = (x: number = 0, smooth: boolean = false, duration: number = 800) => {
    const {current: currentScrollRef} = this.scrollbars;
    if (currentScrollRef)
      if (smooth) {
        const currentX = currentScrollRef.getScrollTop();
        window.requestAnimationFrame(this.animateScroll('vertically', currentX, x, duration));
      } else {
        currentScrollRef.scrollTop(x);
      }
  };

  scrollToY = (y: number = 0, smooth: boolean = false, duration: number = 800) => {
    const {current: currentScrollRef} = this.scrollbars;
    if (currentScrollRef) {
      if (smooth) {
        const currentY = currentScrollRef.getScrollLeft();
        window.requestAnimationFrame(this.animateScroll('horizontally', currentY, y, duration));
      } else {
        currentScrollRef.scrollLeft(y);
      }
    }
  };

  scrollToLeft = (smooth: boolean = false, duration: number = 800) => {
    this.scrollToY(0, smooth, duration);
  };

  scrollToRight = (smooth: boolean = false, duration: number = 800) => {
    const finalY = this.getScrollWidth();
    this.scrollToY(finalY, smooth, duration);
  };

  scrollToTop = (smooth: boolean = false, duration: number = 800) => {
    this.scrollToX(0, smooth, duration);
  };

  scrollToBottom = (smooth: boolean = false, duration: number = 800) => {
    const bottomX = this.getScrollHeight();
    this.scrollToX(bottomX, smooth, duration);
  };

  getClientHeight = () => {
    return this.scrollbars.current ? this.scrollbars.current.getClientHeight() : 0;
  };

  getScrollHeight = () => {
    return this.scrollbars.current ? this.scrollbars.current.getScrollHeight() : 0;
  };
  getClientWidth = () => {
    return this.scrollbars.current ? this.scrollbars.current.getClientWidth() : 0;
  };

  getScrollWidth = () => {
    return this.scrollbars.current ? this.scrollbars.current.getScrollWidth() : 0;
  };
}

export type ScrollbarsType = Scrollbars;
export default withStyles(styles)(Scrollbars);
