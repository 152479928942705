import {Theme} from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '0px 15px',
      boxSizing: 'border-box',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit,
    },
    dense: {
      padding: 0,
    },
  });

export type Styles = WithStyles<typeof styles>;
