import React, {FC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import NumberFormatCustom from '../../../NumberFormatCustom';
import {PopperContentSection} from '../../../Popper';
import {TextField, TextFieldSize} from '../../../TextField';
import {Styles, styles} from './SettingsPopper.style';

interface Props extends Styles {
  autoFocus?: boolean;
  maxValue?: number;
  onChange: (budget: number) => void;
}

const Presentational: FC<Props> = (props) => {
  const {autoFocus, maxValue, onChange} = props;

  return (
    <PopperContentSection>
      <TextField
        autoFocus={autoFocus}
        label="Budget"
        required
        value={maxValue === null || isNaN(maxValue) ? '' : maxValue}
        onChange={(value) => {
          const num = Number(value) || null;
          onChange(num);
        }}
        size={TextFieldSize.small}
        fullWidth
        inputProps={{thousandSeparator: true}}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
    </PopperContentSection>
  );
};
export default withStyles(styles)(Presentational);
