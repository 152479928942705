import React from 'react';

import Typography from '@material-ui/core/Typography';
import _capitalize from 'lodash/capitalize';

const SingleValue = (props) => {
  const {selectProps, innerProps} = props;
  const {'data-testid': dataTestId, classes, size, SingleValueProps} = selectProps;
  return (
    <Typography
      className={classes[`singleValue${_capitalize(size)}`]}
      noWrap
      {...SingleValueProps}
      {...innerProps}
      data-testid={dataTestId ? `${dataTestId}-value` : dataTestId}
    >
      {props.children}
    </Typography>
  );
};

SingleValue.muiName = 'SingleValue';

export default SingleValue;
