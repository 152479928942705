import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 15px',
      height: 48,
      boxSizing: 'border-box',
    },
  });

export type Styles = WithStyles<typeof styles>;
