import React from 'react';
import {LegacyRef} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {AutoSizer, List, ListProps, WindowScroller, WindowScrollerProps} from 'react-virtualized';

import {ScrollbarsContext} from '../Scrollbars';
import {Scrollbars} from '../Scrollbars';
import {Styles, styles} from './VirtualizedList.style';

export type VirtualizedListProps = Styles & {
  rowHeight: ListProps['rowHeight'];
  // https://github.com/bvaughn/react-virtualized#pass-thru-props
  // Shallow Compare https://reactjs.org/docs/shallow-compare.html
  items: any[];
  deferredMeasurementCache?: ListProps['deferredMeasurementCache'];
  children: ListProps['rowRenderer'];
  scrollAutoHide?: boolean;
  scrollAutoHeightMax?: string | number;
  scrollElement?: WindowScrollerProps['scrollElement'];
  listRef?: LegacyRef<List>;
};

class VirtualizedList extends React.Component<VirtualizedListProps> {
  renderChildrenWithInternalScroll = () => {
    const {scrollAutoHide, scrollAutoHeightMax} = this.props;
    return (
      <Scrollbars autoHide={scrollAutoHide} autoHeight autoHeightMax={scrollAutoHeightMax}>
        <ScrollbarsContext.Consumer>
          {({view}) => {
            return this.renderChildren(view);
          }}
        </ScrollbarsContext.Consumer>
      </Scrollbars>
    );
  };

  renderChildren = (scrollElement: WindowScrollerProps['scrollElement']) => {
    const {classes, rowHeight, children, items, deferredMeasurementCache, listRef} = this.props;
    return (
      <div>
        <WindowScroller scrollElement={scrollElement}>
          {({
            height,
            isScrolling,
            // registerChild,
            scrollTop,
            onChildScroll,
          }) => (
            <AutoSizer disableHeight>
              {({width}) => (
                // <div ref={registerChild}>
                <List
                  className={classes.root}
                  autoHeight
                  height={height || 0}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  rowCount={(items || []).length}
                  rowHeight={rowHeight}
                  rowRenderer={children}
                  scrollTop={scrollTop}
                  width={width}
                  ref={listRef}
                  // The list has no items prop. It is been passed to prevent the shallow comparison
                  // https://github.com/bvaughn/react-virtualized#pass-thru-props
                  // Shallow Compare https://reactjs.org/docs/shallow-compare.html
                  items={items}
                  deferredMeasurementCache={deferredMeasurementCache}
                />
                // </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </div>
    );
  };

  render() {
    const {scrollElement} = this.props;

    return !!scrollElement
      ? this.renderChildren(scrollElement)
      : this.renderChildrenWithInternalScroll();
  }
}

export default withStyles(styles)(VirtualizedList);
