import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    card: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      border: '1px solid rgba(0, 0, 0, 0.125)',
      borderRadius: '0.25rem',
      transition: 'all 0.3s',
      padding: 16,
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
    cardBlock: {},
    placeholder: {
      '&:after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '0.8rem',
        backgroundColor: '#f3f3f3',
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
