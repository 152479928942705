import React, {FC, ReactNode, useState} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import Header from '../Header';
import MenuPopper from '../MenuPopper';
import {Styles, styles} from './Widget.style';

const initialState = {anchorEl: null as HTMLElement, openMenu: false, openSettings: false};
type State = typeof initialState;
type RenderProps = State & {
  resetState?: () => void;
  onUpdateState?: (state: Partial<State>) => void;
};

export interface Props extends Styles {
  title: string;
  hideOptions?: boolean;
  renderBeforeSettingsMenuItem?: (props: RenderProps) => ReactNode;
  renderAfterSettingsMenuItem?: (props: RenderProps) => ReactNode;
  renderBeforeDeleteMenuItem?: (props: RenderProps) => ReactNode;
  renderAfterDeleteMenuItem?: (props: RenderProps) => ReactNode;
  settingsPopper: (props: RenderProps) => ReactNode;
  onDelete: () => void;
}

const Presentational: FC<Props> = (props) => {
  const {classes, title, hideOptions, children, settingsPopper, onDelete} = props;
  const {
    renderBeforeSettingsMenuItem,
    renderAfterSettingsMenuItem,
    renderBeforeDeleteMenuItem,
    renderAfterDeleteMenuItem,
  } = props;
  const [state, setState] = useState(initialState);
  const {anchorEl, openMenu, openSettings} = state;

  const resetState = () => setState(initialState);
  const renderProps = {
    ...state,
    resetState: resetState,
    onUpdateState: (newState) => (newState ? setState({...state, ...newState}) : null),
  };

  return (
    <div className={classes.root}>
      <Header
        title={title}
        hideOptions={hideOptions}
        onMoreClick={(ev) => setState({...state, openMenu: true, anchorEl: ev.currentTarget})}
      />
      {children}
      {!!openMenu && (
        <MenuPopper
          open={true}
          anchorEl={anchorEl}
          beforeSettingsItem={
            renderBeforeSettingsMenuItem && renderBeforeSettingsMenuItem({...renderProps})
          }
          afterSettingsItem={
            renderAfterSettingsMenuItem && renderAfterSettingsMenuItem({...renderProps})
          }
          beforeDeleteItem={
            renderBeforeDeleteMenuItem && renderBeforeDeleteMenuItem({...renderProps})
          }
          afterDeleteItem={renderAfterDeleteMenuItem && renderAfterDeleteMenuItem({...renderProps})}
          onClickAway={() => !openSettings && resetState()}
          onDeleteClick={() => {
            resetState();
            onDelete();
          }}
          onSettingsClick={() => setState({...state, openSettings: true})}
        />
      )}
      {settingsPopper({...renderProps})}
    </div>
  );
};

Presentational.defaultProps = {
  renderBeforeSettingsMenuItem: () => null,
  renderAfterSettingsMenuItem: () => null,
  renderBeforeDeleteMenuItem: () => null,
  renderAfterDeleteMenuItem: () => null,
};

export default withStyles(styles)(Presentational);
