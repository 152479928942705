import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    paper: {
      marginLeft: 10,
    },
    emptyArea: {
      flex: 1,
    },
  });

export type Styles = WithStyles<typeof styles>;
