import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      outline: 'none',
    },
    content: {
      display: 'flex',
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
