import React from 'react';

import _equal from 'fast-deep-equal';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {SearchPopperListOption} from '../SearchPopper/List';
import {FilterContext} from './FilterContext';

export type BaseProps<T, P = {}> = {
  /**
   * Set filter as a main filter on add filter listed first
   */
  isMain?: boolean;
  defaultValue?: T;
  filterAddOption: SearchPopperListOption<T>;
  onChange?: (value: T, ev?: React.SyntheticEvent) => void;
  popperClasses?: {[index in keyof P]: string};
  chipClasses?: Partial<{noChanges: string; hasChanges: string; chip: string}>;
};

export abstract class BaseFilter<P extends BaseProps<T>, T, S = {}> extends React.Component<P, S> {
  static contextType = FilterContext;
  context!: React.ContextType<typeof FilterContext>;

  static defaultProps = {
    chipClasses: {noChanges: '', hasChanges: '', chip: ''},
    popperClasses: {},
    isMain: false,
    defaultValue: undefined,
    onChange: (value: any) => {},
  } as Partial<BaseProps<any>>;

  get isOpen() {
    return this.context.openFilter === this.props.filterAddOption.id;
  }

  get value() {
    const {
      filterAddOption: {id},
      defaultValue,
    } = this.props;
    return (isNil(this.context.shownFilters[id])
      ? defaultValue
      : this.context.shownFilters[id]) as T;
  }

  get tempValue() {
    const {
      filterAddOption: {id},
    } = this.props;
    return this.context.tempFilters[id];
  }

  get initialValue() {
    const {
      filterAddOption: {id},
      defaultValue,
    } = this.props;

    const {initialAppliedFilters, appliedFilters} = this.context;
    const result = this.hasInitialFilters ? initialAppliedFilters[id] : appliedFilters[id];

    return (!isNil(result) ? result : defaultValue) as T;
  }

  get hasInitialFilters() {
    return !isEmpty(this.context.initialAppliedFilters);
  }

  openPopper = (e: React.MouseEvent<HTMLElement>) => {
    const {handleChipFilterClick} = this.context;
    const {
      filterAddOption: {id},
    } = this.props;

    return handleChipFilterClick(id)(e.currentTarget);
  };

  closePopper = (temp?: any) => {
    if (temp) {
      const {
        filterAddOption: {id},
      } = this.props;
      const {value} = this;
      if (!_equal(value, temp)) {
        this.context.handleSetTempValue(id, temp);
      } else {
        this.context.handleSetTempValue(id, null);
      }
    }

    this.context.handlePopperClose();
  };

  resetTempValue = () => {
    const {
      filterAddOption: {id},
    } = this.props;
    this.context.handleSetTempValue(id, null);
  };

  onChange = (value: T, ev: React.SyntheticEvent) => {
    const {handleChangeFilter} = this.context;
    const {
      filterAddOption: {id},
      onChange: externalOnChange,
    } = this.props;

    handleChangeFilter(id, value);

    if (externalOnChange) {
      externalOnChange(value, ev);
    }
  };

  onReset = () => {
    const {handleRemoveFilter} = this.context;
    const {
      filterAddOption: {id},
    } = this.props;

    handleRemoveFilter(id);
  };
}
