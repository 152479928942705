import React, {SFC} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import _capitalize from 'lodash/capitalize';

import TextField, {TextFieldProps} from '../TextField';
import {Styles, styles} from './TextArea.style';

export type TextAreaProps = Styles & TextFieldProps;

const TextArea: SFC<TextAreaProps> = ({classes, size, InputProps, ...otherProps}) => {
  const {classes: InputPropsClasses, inputProps} =
    InputProps || ({} as TextAreaProps['InputProps']);
  const {root: InputPropsClassesRoot, ...otherInputPropsClasses} =
    InputPropsClasses || ({} as TextAreaProps['InputProps']['classes']);

  const {className: inputPropsClassName, ...inputPropsOtherProps} =
    inputProps || ({} as TextAreaProps['inputProps']);

  return (
    <TextField
      size={size}
      InputProps={{
        classes: {
          root: classNames(classes.textAreaPadding, InputPropsClassesRoot),
          ...otherInputPropsClasses,
        },
        inputProps: {
          className: classNames(
            classes[`size${_capitalize(size)}TextAreaFont`],
            inputPropsClassName,
          ),

          ...inputPropsOtherProps,
        },
      }}
      {...otherProps}
    />
  );
};

TextArea.defaultProps = {
  multiline: true,
  rows: 4,
  rowsMax: 8,
};

export default withStyles(styles)(TextArea);
