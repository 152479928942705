import React, {FunctionComponent, HTMLAttributes} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import {Styles, styles} from './Breadcrumbs.style';

type BreadcrumbsProps = Styles & HTMLAttributes<HTMLDivElement>;

const Presentational: FunctionComponent<BreadcrumbsProps> = ({
  classes,
  className,
  children,
  ...otherProps
}) => (
  <div className={classNames(classes.root, className)} {...otherProps}>
    {children}
  </div>
);

Presentational.defaultProps = {};

export default withStyles(styles)(Presentational);
