import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    track: {
      height: 6,
      borderRadius: 100,
    },
    trackAfter: {
      opacity: 1,
      backgroundColor: '#8338B9',
    },
    trackBefore: {
      opacity: 1,
      backgroundColor: '#CDA7F3',
    },

    container: {
      padding: '22px 10px',
    },
    thumbIconWrapper: {
      height: 15,
      width: 15,
      '&:focus': {
        boxShadow: '0px 0px 0px 9px rgba(128, 7, 212, 0.16)',
      },
    },
    thumb: {
      border: `1.5px solid #8338B9`,
      boxSizing: 'border-box',
      height: 15,
      width: 15,
      backgroundColor: '#fff',
      borderRadius: '50%',
      display: 'inline-block',
    },
  });

export type Styles = WithStyles<typeof styles>;
