import React from 'react';

import {IconButtonProps} from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import {SvgIconProps} from '@material-ui/core/SvgIcon';

import {AdornmentIcon} from '../AdornmentIcon';
import {Styles, styles} from './EndAdornment.style';

export interface EndAdornmentProps extends Styles {
  onClick?: IconButtonProps['onClick'];
  Icon: React.ComponentType<SvgIconProps>;
}
const EndAdornment = (props: EndAdornmentProps) => {
  const {classes, Icon, onClick} = props;
  return (
    <InputAdornment position="end" classes={{positionEnd: classes.positionEnd}}>
      <AdornmentIcon Icon={Icon} onClick={onClick} />
    </InputAdornment>
  );
};

export default withStyles(styles)(EndAdornment);
