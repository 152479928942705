import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';
import AsyncSelect, {Props as AsyncProps} from 'react-select/lib/Async';
import {SelectComponentsConfig} from 'react-select/lib/components';
import {createFilter} from 'react-select/lib/filters';
import {Option as SelectOption} from 'react-select/lib/filters';

import {ScrollbarsContext} from '../../Scrollbars';
import {AutocompleteSize, defaultAutoCompleteStyles} from '../Autocomplete';
import {styles, Styles} from '../Autocomplete.style';
import {asyncComponents} from '../utils';

type AsyncDefaultProps = AsyncProps<SelectOption>;

export type AsyncAutocompleteProps = AsyncDefaultProps &
  Styles & {
    virtualized?: boolean;
    size?: AutocompleteSize;
    SingleValueProps?: TypographyProps;
    components?: SelectComponentsConfig<SelectOption>;
  };

const AsyncAutocomplete: FunctionComponent<AsyncAutocompleteProps> = (props) => {
  const {
    className,
    classes,
    virtualized,
    filterOption,
    components = {},
    styles = {},
    ...otherProps
  } = props;
  const {root, ...otherClasses} = classes;

  const scrollbars = React.useContext(ScrollbarsContext);

  return (
    <AsyncSelect
      className={classNames(root, className)}
      classes={otherClasses}
      components={{...asyncComponents, ...components}}
      virtualized={virtualized}
      filterOption={filterOption || (virtualized && createFilter({ignoreAccents: false}))} // this makes all the difference!
      styles={{...defaultAutoCompleteStyles, ...styles}}
      onMenuOpen={(...args) => {
        otherProps.onMenuOpen?.(...args);
        scrollbars.setDisableVerticalScrolling(true);
      }}
      onMenuClose={(...args) => {
        otherProps.onMenuClose?.(...args);
        scrollbars.setDisableVerticalScrolling(false);
      }}
      {...otherProps}
    />
  );
};

AsyncAutocomplete.defaultProps = {
  menuPortalTarget: process.browser ? document.body : null,
  menuPlacement: 'auto',
  isMulti: false,
  label: '',
  size: AutocompleteSize.large,
  menuShouldScrollIntoView: true,
  instanceId: 'auto-complete',
};

export default withStyles(styles)(AsyncAutocomplete);
