import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import classNames from 'classnames';

import {DatagridSortingType} from '../../../../Datagrid/Datagrid.model';

import {Styles, styles} from './Sorting.style';

interface Props extends Styles {
  sorting: DatagridSortingType;
}

const DatagridTableViewSorting: FunctionComponent<Props> = (props) => {
  const {classes, sorting} = props;
  return (
    <ArrowUpwardIcon
      className={classNames(classes.root, {
        [classes.rotate]: sorting === DatagridSortingType.desc,
      })}
    />
  );
};

export default withStyles(styles)(DatagridTableViewSorting);
