import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import RefreshIcon from '@material-ui/icons/Refresh';
import classNames from 'classnames';

import {styles, Styles} from './Spinner.style';

interface Props extends Styles {}

const Spinner: FunctionComponent<Props> = (props) => {
  const {classes} = props;
  return <RefreshIcon className={classNames(classes.buttonIcon, classes.buttonSpin)} />;
};

export default withStyles(styles)(Spinner);
