import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme) =>
  createStyles({
    root: {
      border: '1px solid #DDD',
      borderRadius: 4,
      padding: 10,
    },
  });

export type Styles = WithStyles<typeof styles>;
