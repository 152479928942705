import React from 'react';

import {Omit} from '@material-ui/core';

import {FilterChip as UIKitFilterChip, FilterChipProps} from '../../../FilterChip';
import {classNames} from './classNames';
import {Styles} from './FilterChip.style';

export type FilterColorChipProps<T> = Styles & {
  enableColor: boolean;
  hasChanges: boolean;
  value: T;
} & Omit<FilterChipProps, 'label'>;

abstract class FilterColorChip<P extends FilterColorChipProps<T>, T> extends React.Component<P> {
  abstract getLabel(value: T): string;

  static defaultProps: Partial<FilterColorChipProps<any>> = {
    isLoaded: true,
  };

  render() {
    const {value, classes, enableColor, hasChanges, ...otherProps} = this.props;
    const {noChanges: noChangesClasses, hasChanges: hasChangesClasses, ...otherClasses} = classes;
    const label = this.getLabel(value);

    return (
      <UIKitFilterChip
        {...otherProps}
        classes={{
          ...otherClasses,
          chip: classNames({
            [classes.chip]: true,
            [noChangesClasses]: enableColor && !hasChanges,
            [hasChangesClasses]: enableColor && hasChanges,
          }),
        }}
        label={label}
      />
    );
  }
}

export default FilterColorChip;
