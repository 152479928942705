import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      backgroundColor: '#eee',
      borderRadius: 4,
      textAlign: 'center',
      padding: '20px 0',
      width: '100%',
    },
    title: {
      color: '#888888',
      fontWeight: 500,
      marginBottom: '0.3rem',
      fontSize: 12,
    },
    content: {
      color: '#666',
      fontSize: 18,
      fontWeight: 800,
    },
  });

export type Styles = WithStyles<typeof styles>;
