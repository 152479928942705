import React, {SFC} from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {Badge, BadgeProps} from './Badge';
import {Styles, styles} from './Item.style';

export interface SearchPopperListOption<T = any> {
  id: string;
  title: string;
  description?: string;
  disabled?: boolean;
  badge?: string;
  badgeType?: BadgeProps['type'];
  isDeprecated?: boolean;
  initialValue?: T;
  dynamicId?: string;
}

export type SearchPopperListItemProps = Styles & {
  selected?: boolean;
  option: SearchPopperListOption;
  onClick: (option: SearchPopperListOption, evt: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseEnter: (
    option: SearchPopperListOption,
    evt: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
};

const handleEvent = (
  opt: SearchPopperListOption,
  callback: SearchPopperListItemProps['onClick' | 'onMouseEnter'],
) => (evt: React.MouseEvent<HTMLElement>) => {
  callback(opt, evt);
};

const NewListItemText: SFC<Pick<SearchPopperListItemProps, 'classes' | 'option'>> = ({
  classes,
  option,
  children,
}) =>
  option.badge ? (
    <div className={classes.newItemTextPrimaryRoot}>
      <Typography className={classes.itemTextPrimary}>{option.title}</Typography>
      <Badge type={option.badgeType}>{option.badge}</Badge>
    </div>
  ) : (
    <>{option.title}</>
  );

const SearchPopperListItem: SFC<SearchPopperListItemProps> = (props) => {
  const {classes, option, selected, onClick, onMouseEnter} = props;

  return (
    <ListItem
      dense
      button
      selected={selected}
      disabled={option.disabled}
      classes={{
        root: classes.itemRoot,
        selected: classes.selected,
      }}
      onClick={handleEvent(option, onClick)}
      onMouseEnter={handleEvent(option, onMouseEnter)}
    >
      <ListItemText
        primary={<NewListItemText classes={classes} option={option} />}
        secondary={option.description}
        classes={{
          root: classes.itemTextRoot,
          primary: classes.itemTextPrimary,
          secondary: classes.itemTextSecondary,
          textDense: classes.textDense,
        }}
      />
    </ListItem>
  );
};

export default withStyles(styles)(SearchPopperListItem);
