import React from 'react';

export enum DatagridViewMode {
  table = 'table',
  map = 'map',
  cards = 'cards',
}

export interface DatagridProperty {
  label: string | React.ReactNode;
  value?: string;
  style?: React.CSSProperties
}
export interface ColumnHeaderProperty extends DatagridProperty {
  columnRootClass?: string;
  columnHeadRootClass?: string;
  columnHeadTextClass?: string;
  columnHeadExtra?: React.ReactNode;
  columnHeadExtraContainerClass?: string;
}

export enum DatagridSortingType {
  asc = 'asc',
  desc = 'desc',
}

export interface DatagridSorting {
  key: string;
  type: DatagridSortingType;
}
