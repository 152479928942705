import React from 'react';

import isEqual from 'lodash/isEqual';

import {BaseFilter, BaseProps} from '../../BaseFilter';
import Chip from './Chip';
import SearchFilterPopper from './Popper';

type PopperClasses = typeof SearchFilterPopper['defaultProps']['classes'];
type Props = {
  hideAdvancedButton?: boolean;
} & BaseProps<string, PopperClasses>;
const FILTER_ID = 'searchTerm' as const;

export class SearchTermFilter extends BaseFilter<Props, string> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Search Query',
    description: 'Search surfaces by any terms or keywords.',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    defaultValue: '',
    filterAddOption: SearchTermFilter.defaultFilterAddOption,
  } as Partial<Props>;

  render() {
    const {
      value,
      initialValue,
      isOpen: open,
      tempValue,
      resetTempValue,
      closePopper,
      onChange,
    } = this;
    const {disabled, anchorEl} = this.context;
    const {chipClasses, popperClasses, hideAdvancedButton} = this.props;

    return (
      <div>
        {!!value && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
            classes={chipClasses}
          />
        )}
        {open && (
          <SearchFilterPopper
            hideAdvancedButton={hideAdvancedButton}
            value={value}
            tempValue={tempValue}
            open={open}
            anchorEl={anchorEl}
            onPopperClose={closePopper}
            onChange={onChange}
            resetTempValue={resetTempValue}
            hasChanges={!!tempValue}
            classes={popperClasses}
          />
        )}
      </div>
    );
  }
}
