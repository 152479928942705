import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      borderRadius: 4,
      border: '1px solid #DDDDDD',
      padding: 15,
      display: 'flex',
      margin: '10px 0',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    boxTitle: {
      flex: 1,
    },
    caption: {
      marginTop: 10,
      color: '#666666',
      letterSpacing: 0,
    },
    switch: {
      marginTop: -13,
    },
  });

export type Styles = WithStyles<typeof styles>;
