import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography, {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';

import {styles, Styles} from './HeaderText.style';

export type PopperHeaderTextProps = Styles & TypographyProps;

const HeaderText: FunctionComponent<PopperHeaderTextProps> = (props) => {
  const {classes, className, ...otherProps} = props;

  return <Typography className={classNames(classes.root, className)} {...otherProps} />;
};

HeaderText.defaultProps = {
  component: 'div',
};

export default withStyles(styles)(HeaderText);
