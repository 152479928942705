import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      position: 'relative',
    },
    outerContainer: {
      height: 'inherit',
      overflow: 'hidden',
      flex: 1,
    },
    innerContainer: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      transition: 'all 0.3s',
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '@media (min-width: 576px)': {
        paddingRight: 0,
      },
    },
  });

export type Styles = WithStyles<typeof styles>;
