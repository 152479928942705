import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '2rem 1rem',
      borderTop: '1px solid rgba(255,255,255,0.1)',
      paddingTop: '2rem',
    },
    message: {
      color: 'rgba(255,255,255,0.6)',
      fontSize: '14px',
    },
    linksContent: {
      marginTop: '10px',
    },
  });

export type Styles = WithStyles<typeof styles>;
