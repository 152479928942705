import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    '@keyframes dots': {
      '0%, 20%': {
        color: 'rgba(0,0,0,0)',
        textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
      },
      '40%': {
        textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
      },
      '60%': {
        textShadow: '.25em 0 0, .5em 0 0 rgba(0,0,0,0)',
      },
      '80%, 100%': {
        textShadow: '.25em 0 0, .5em 0 0',
      },
    },
    root: {
      display: 'block',
      '&:after': {
        content: '" ."',
        animation: 'dots 1s steps(5, end) infinite',
      },
    },
  });

export type Style = WithStyles<typeof styles>;
