import React, {Component, MouseEvent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './UserMenu.style';
import {UserMenuImage, UserMenuImageProps} from './UserMenuImage';
import {UserMenuPopper, UserMenuPopperProps} from './UserMenuPopper';

export type UserMenuProps = Styles &
  Pick<UserMenuImageProps, 'loading' | 'imageUrl'> &
  Pick<
    UserMenuPopperProps,
    | 'userName'
    | 'linkToAccount'
    | 'userEmail'
    | 'onAccountClick'
    | 'onLogoutClick'
    | 'version'
    | 'build'
  >;

const initialState = {
  anchorEl: null as (HTMLElement | null),
};

export type UserMenuState = typeof initialState;

class UserMenu extends Component<UserMenuProps, UserMenuState> {
  static defaultProps = {
    linkToAccount: '',
    onAccountClick: undefined,
    onLogoutClick: undefined,
  };

  constructor(props: UserMenuProps) {
    super(props);
    this.state = initialState;
  }

  handleClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {
      userName,
      userEmail,
      imageUrl,
      linkToAccount,
      onLogoutClick,
      onAccountClick,
      version,
      build,
      loading,
    } = this.props;
    const {anchorEl} = this.state;

    return (
      <>
        <UserMenuImage
          userName={userName}
          imageUrl={imageUrl}
          loading={loading}
          onClick={this.handleClick}
        />
        <UserMenuPopper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClickAway={this.handleClose}
          userName={userName}
          userEmail={userEmail}
          linkToAccount={linkToAccount}
          onLogoutClick={onLogoutClick}
          onAccountClick={onAccountClick}
          version={version}
          build={build}
        />
      </>
    );
  }
}

export default withStyles(styles)(UserMenu);
