import React from 'react';

import isEqual from 'lodash/isEqual';

import {BaseFilter, BaseProps} from '../../BaseFilter';
import {MultipleCheckFilterPopper} from '../MultipleCheckFilter';
import Chip from './Chip';

export enum CurationStatusEnum {
  draft = 'draft',
  review = 'review',
  notFound = 'not-found',
  completed = 'completed',
  published = 'published',
  inactive = 'inactive',
  unavailable = 'unavailable',
}

type Props = {
  requiredValue?: boolean;
  options?: CurationStatusEnum[];
} & BaseProps<CurationStatusEnum[], {}>;

const FILTER_ID = 'curationStatus' as const;

export class CurationStatusFilter extends BaseFilter<Props, CurationStatusEnum[]> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Curation Status',
    description: 'Draft, review, completed and published',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: CurationStatusFilter.defaultFilterAddOption,
    options: ['draft', 'completed', 'review', 'published'],
  } as Partial<Props>;

  render() {
    const {value = [], initialValue = [], context, isOpen: open, tempValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;
    const {chipClasses, requiredValue, options} = this.props;

    const isLoaded = !disabled;

    return (
      <div>
        {!!value.length && (
          <Chip
            hideClose={requiredValue}
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={isLoaded}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
            classes={chipClasses}
          />
        )}
        {open && (
          <MultipleCheckFilterPopper
            headerTitle={this.props.filterAddOption.title}
            options={options}
            open={open}
            anchorEl={anchorEl}
            value={value}
            tempValue={tempValue}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  }
}
