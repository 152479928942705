import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {nope} from '../../../utils/function';
import {ColoredIndicator} from '../../ColoredIndicator';
import {CssTooltipProps} from '../../CssTooltip';
import {RemovableListProps} from '../RemovableList';
import {Styles, styles} from './IndicatorItem.style';

export type RemovableListIndicatorItemProps = Styles & {
  item: RemovableListProps['items'][number];
  onIndicatorClick?: (value: string) => void;
  tooltipProps?: Partial<CssTooltipProps>;
};

const handleIndicatorClick = (
  callback: RemovableListIndicatorItemProps['onIndicatorClick'],
  value: RemovableListIndicatorItemProps['item']['value'],
) => () => {
  callback(value);
};

const Presentational: FunctionComponent<RemovableListIndicatorItemProps> = ({
  classes,
  item,
  onIndicatorClick,
  tooltipProps,
}) => {
  return (
    <div className={classes.root}>
      {
        <ColoredIndicator
          classes={{root: classes.indicator}}
          onIndicatorClick={handleIndicatorClick(onIndicatorClick, item.value)}
          tooltipProps={tooltipProps}
        />
      }
      {item.label}
    </div>
  );
};

Presentational.defaultProps = {
  onIndicatorClick: nope,
};

export default withStyles(styles)(Presentational);
