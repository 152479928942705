import React, {useCallback, useState} from 'react';

export const usePopper = () => {
  const [popperRef, setPopperRef] = useState<HTMLElement>(null);

  const onOpenFromRef = useCallback(
    (el: HTMLElement) => {
      setPopperRef(el);
    },
    [setPopperRef],
  );

  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      onOpenFromRef(e.currentTarget as HTMLElement);
    },
    [onOpenFromRef],
  );

  const onClose = useCallback(() => {
    setPopperRef(null);
  }, [setPopperRef]);

  return {popperRef, onOpen, onOpenFromRef, onClose};
};
