import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      height: 'auto',
      minHeight: '53px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  });

export type Styles = WithStyles<typeof styles>;
