import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    rows: {
      position: 'relative',
      backgroundColor: '#fff',
    },
  });

export type Styles = WithStyles<typeof styles>;
