import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {Styles, styles} from './ItemWithSubtitle.style';

export type RemovableListItemWithSubtitleProps = Styles & {
  item: {label: string; subtitle?: React.ReactNode};
};

const Presentational = ({classes, item}: RemovableListItemWithSubtitleProps) => {
  return (
    <div className={classes.root}>
      <div className={classes.label}>{item.label}</div>
      {!!item.subtitle && <div className={classes.subtitle}>{item.subtitle}</div>}
    </div>
  );
};

export default withStyles(styles)(Presentational);
